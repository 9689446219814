/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

// import TermsOfUseModal from "../../components/Modal/TermsOfUseModal";

export class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };
  }
  toggleModal = (e) => {
    this.setState(() => ({ modal: !this.state.modal }));
  }
  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
      >
        {/* <TermsOfUseModal isOpen={this.state.modal} toggle={this.toggleModal}/> */}
        <Container fluid={this.props.fluid ? true : false}>
          <Row>
            <div className="credits ml-auto">
              <div className="copyright">
                &copy; {1900 + new Date().getYear()}, Hiwave.io 
                {/*<a className="termsofuse" onClick={() => this.toggleModal()}>
                  Terms of Use
                </a>*/}
              </div>
            </div>
          </Row>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
