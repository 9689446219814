import React from "react";
import { connect } from 'react-redux';

import {
    Row,
    Col,
    Button
} from 'reactstrap';
import axios from "axios";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import LoadingPage from '../../components/Loading/LoadingPage';
import Header from '../../components/Subscription/Header/Header';
import SubscriptionCard from '../../components/Subscription/SubscriptionCard';

export class SubscriptionView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            selectedPlan: 'P-0AC48566360702629MI5XRDY'
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);

        this.toggleLoading(false);
    }
    toggleLoading = (status) => {
        this.setState({
            loading: status
        })
    }
    createSubscription = (data, actions) => {
        return actions.subscription.create({
            'plan_id': this.state.selectedPlan
        }).then((subscriptionID) => {
            return subscriptionID
        });
    };
    onApprove = (data, actions) => {
        axios.post(`${process.env.SERVER_URL}/auth/addSubscription`, 
            {
                userId: this.props.auth['id'],
                planPaypalId: this.state.selectedPlan,
                subscriptionId: data['subscriptionID'],
                orderId: data['orderID'],
                facilitatorAccessToken: data['facilitatorAccessToken']
            },
            {
                headers: {
                    'Authorization': "Bearer " + JSON.parse(localStorage.getItem('user'))['token']
                }
            }
        ).then((response) =>  {
            window.location.reload(false);
        }).catch((error) => {
            console.log(error);
        });
    };
    onPlanClick = (plan) => {
        this.setState({
            selectedPlan: plan
        })
    }
    render() {
        if (this.state.loading === true) {
            return <LoadingPage colorTheme={this.props.colorTheme}/>;
        }

        const initialOptions = {
            "client-id": "AYxSBIeqYcwPCoRvFfBclZcbEuhhGK0A-79y1DD62r5tlxmzK_8WeoCBlpTEiAvwyruMz5I7J18lH2a5",
            "intent": "subscription",
            "vault": true
        };

        return (
            <div className="content">
                <Header />
                <div className="content-row">
                    <Row>
                        <Col xs={12} md={12}>
                            <Row>
                                <Col xs={12} md={6}>
                                    <SubscriptionCard 
                                        selected={this.state.selectedPlan == 'P-0AC48566360702629MI5XRDY'}
                                        name='Light - 5d Trial'
                                        description1='Works well for teams who wish to track lightly and get notified of an emotional event related to a specific entity from last months 12 months.'
                                        description2='Get daily LIVE alerts in your mailbox with Emerging Emotions.'
                                        price={150}
                                        features={[
                                            'Sources: Twitter and Reddit',
                                            '5x Tracked Brands/Hashtags/Terms'
                                        ]}
                                        planPaypalId='P-0AC48566360702629MI5XRDY'
                                        onClick={this.onPlanClick}
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <SubscriptionCard 
                                        selected={this.state.selectedPlan == 'P-7341561758650882VMI5XTUA'}
                                        name='Medium - 5d Trial'
                                        description1='Works well for teams looking to apply brand sentiments within their marketing, product or communications strategy.'
                                        description2='Everything from Alerts, plus you get to import your own NPS reviews and get more search terms across specified sources.'
                                        price={250}
                                        features={[
                                            'Sources: Twitter and Reddit',
                                            '10K NPS Reviews/Month *',
                                            '13x Tracked Brand/Hashtags/Terms',
                                            'Dashboard and CSV Data Access'
                                        ]}
                                        planPaypalId='P-7341561758650882VMI5XTUA'
                                        onClick={this.onPlanClick}
                                    />
                                </Col>
                            </Row>
                            <Row>   
                                <Col xs={12}>
                                    <div className='subscription__footer'>
                                        <PayPalScriptProvider options={initialOptions}>
                                            <PayPalButtons 
                                                className='subscription__footer-paypal-button'
                                                createSubscription={this.createSubscription}
                                                onApprove={this.onApprove}
                                            />
                                        </PayPalScriptProvider>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        {/*<Col xs={12} md={4}>
                            <Row>   
                                <Col xs={12}>
                                    <SubscriptionCard 
                                        name='Premium'
                                        description1='Scale customer feedback analysis and application through sales automation, higher volumes of chatter and fast sampling of emotions and sentiment.'
                                        description2=''
                                        price={'800-3500'}
                                        features={[
                                            'Sources: Twitter/Reddit/Social Reviews',
                                            'Sales Automation',
                                            'Up to 100K NPS Reviews/Month',
                                            '21x Tracked Brands/Hashtags/Terms',
                                            'Dashboard, API and CSV Data Access'
                                        ]}
                                        planPaypalId='PREMIUM'
                                    />
                                </Col>
                            </Row>
                            <Row>   
                                <Col xs={12}>
                                    <div className='subscription__footer'>
                                        <div className="onboarding__submit">
                                            <Button 
                                                className="onboarding__submit-button"
                                                type="submit"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>*/}
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        colorTheme: state['admin']['colorTheme'],
        auth: state['auth']
    }
}

export default connect(mapStateToProps)(SubscriptionView);