import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Select from "react-select";

const AlertFilter = ({ selected, currentAlert, theme, asset, alertsList, compareList, onSelect }) => {
    const [dropdown, setDropdown ] = useState([])

    useEffect(() => {
        if (asset) {
            setDropdown(alertsList[theme][asset].sort((a, b) => a['createdAtEpochTime'] - b['createdAtEpochTime']).map(widget => ({
                value: widget['shareCode'],
                label: moment.unix(widget['createdAtEpochTime']).format("MMM DD YYYY hh:mm:ss a"),
                disabled: compareList.some(c => c['shareCode'] === widget['shareCode'])
            })))
        }else {
            setDropdown([])
        }
    }, [theme, asset])

    return (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
            <div className="vertical-center">
                <span style={{ fontWeight: 'bold' }}>
                    Alert:
                </span>
            </div>
            <Select
                className="tait-alert__select-full"
                classNamePrefix="select"
                placeholder="Select Alert"
                value={
                    selected &&
                        dropdown.find(widget => 
                            widget['value'] == selected
                        )
                }
                onChange={onSelect}
                options={dropdown}
                isOptionDisabled={(option) => option.disabled}
            />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        alertsList: state['alerts']['alertsList']
    };
};

export default connect(mapStateToProps)(AlertFilter);