import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    Input,
    Button
} from 'reactstrap';

import { setSearchFilter, setSentimentFilter, setDateFilter, setSourceFilter } from '../../../../../actions/alerts/data-point-search';

const SearchFilter = ({ setSearchFilter, searchTerm, setSentimentFilter, setDateFilter, setSourceFilter }) => {
    const [inputValue, setInputValue] = useState(searchTerm)

    useEffect(() => {
        setInputValue(searchTerm)
    }, [searchTerm])

    const onChange = (e) => {
        setInputValue(e)
    }

    const onClick = (e) => {
        setSentimentFilter(null)
        setDateFilter(null)
        setSourceFilter(null)
        setSearchFilter(inputValue)
    }

    return (
        <div className="dps-filters__search">
            <Input 
                className="dps-filters__search-input"
                value={inputValue}
                placeholder="Search for any word"
                onChange={(e) => onChange(e.target.value)}
            />
            <Button
                className="dps-filters__button"
                onClick={onClick}
                onMouseDown={e => e.preventDefault()}
            >
                Search
            </Button>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        searchTerm: state['dataPointSearch']['searchFilter']
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSearchFilter: (searchFilter) => dispatch(setSearchFilter(searchFilter)),
        setDateFilter: (startDate, endDate) => dispatch(setDateFilter(startDate, endDate)),
        setSentimentFilter: (sentimentFilter) => dispatch(setSentimentFilter(sentimentFilter)),
        setSourceFilter: (sourceFilter) => dispatch(setSourceFilter(sourceFilter))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
