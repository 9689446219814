import React, { Component } from 'react'
import Chart from "chart.js";

Chart.defaults.customLine = Chart.defaults.line;
Chart.defaults.customLine.defaultFontFamily = "'ProximaNovaSoftW03-Regular', 'Montserrat', 'Helvetica', Arial, sans-serif";

export default class LineChart extends Component {
    chartRef = React.createRef();
    
    componentDidMount() {
        const myChartRef = this.chartRef.current.getContext("2d");

        const { datasets, labels, options } = this.props;

        var customLine = Chart.controllers.line.extend({
            draw: function(ease) {
                Chart.controllers.line.prototype.draw.call(this, ease);
            }
        });
        
        Chart.controllers.customLine = customLine;
        
        new Chart(myChartRef, {
            type: 'customLine',
            data: {
                labels: labels,
                datasets: datasets
            },
            options: options
        });
    }
    render() {
        return (
            <canvas
                id="customLine"
                ref={this.chartRef}
            />
        )
    }
}
