import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Select from "react-select";

const WidgetFilter = ({ history, widgetFilter, themeFilter, assetFilter, alertsList }) => {
    const [dropdown, setDropdown ] = useState([])

    const onSelect = (e) => {
        if (e.value != widgetFilter) {
            history.push({
                pathname: '/alerts',
                search: `?shareCode=${e.value}`
            })
        }
    }

    useEffect(() => {
        if (assetFilter) {
            setDropdown(alertsList[themeFilter][assetFilter].sort((a, b) => a['createdAtEpochTime'] - b['createdAtEpochTime']).map(widget => ({
                value: widget['shareCode'],
                label: moment.unix(widget['createdAtEpochTime']).format("MMM DD YYYY hh:mm:ss a")
            })))
        }
    }, [])

    return (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
            <div className="vertical-center">
                <span style={{ fontWeight: 'bold' }}>
                    Alert:
                </span>
            </div>
            <Select
                className="tait-alert__select-full"
                classNamePrefix="select"
                placeholder="Select Alert"
                value={
                    dropdown.find(widget => 
                        widget['value'] == widgetFilter
                    )
                }
                onChange={onSelect}
                options={dropdown}
            />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        alertsList: state['alerts']['alertsList'],
        themeFilter: state['alertsFilters']['theme'],
        assetFilter: state['alertsFilters']['asset'],
        widgetFilter: state['alertsFilters']['widget'],
    };
};

export default connect(mapStateToProps)(WidgetFilter);