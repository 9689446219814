import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from "react-select";

const AssetFilter = ({ theme, selected, alertsList, onSelect}) => {
    const [dropdown, setDropdown] = useState([])

    useEffect(() => {
        if (theme) {
            setDropdown(Object.keys(alertsList[theme]).sort().map(asset => ({
                value: asset,
                label: asset
            })))
        }
    }, [theme])

    return ( 
        <div style={{ display: 'flex', gap: '0.5rem' }}>
            <div className="vertical-center">
                <span style={{ fontWeight: 'bold' }}>
                    Asset:
                </span>
            </div>
            <Select
                className="tait-alert__select-full"
                classNamePrefix="select"
                placeholder="Select Asset"
                value={
                    selected &&
                        dropdown.find(asset => 
                            asset['value'] == selected
                        )
                }
                onChange={onSelect}
                options={dropdown}
            />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        alertsList: state['alerts']['alertsList'],
    };
};
  
export default connect(mapStateToProps)(AssetFilter);