import React from 'react';
import {
    Row,
    Col,
    Tooltip
} from 'reactstrap'

export class CardHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false
        }
    }
    toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }
    render() {
        return (
            <div className='alert-card-header'>
                <div className='alert-card-header__title'>
                    <span>
                        {this.props.title}
                    </span>
                </div>
                {
                    this.props.img &&
                        <div 
                            className='alert-card-header__title-image'
                        >
                            <img
                                src={this.props.img}
                            />
                        </div>
                }
                {
                    this.props.tooltipId && 
                        <div  
                            id={this.props.tooltipId}
                            className='alert-card-header__tooltip-image'
                        >
                            <img
                                src='https://ucarecdn.com/6900d1db-f5b1-4be3-a504-4bbd3161439a/information.png'
                            />
                        </div>
                }
                {
                    this.props.tooltipId &&
                        <Tooltip 
                            placement="right" 
                            isOpen={this.state.tooltipOpen} 
                            target={this.props.tooltipId}
                            toggle={this.toggle}
                        >
                            {this.props.tooltipText}
                        </Tooltip>
                }
            </div>
        );
    };
}

export default CardHeader;