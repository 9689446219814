import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Numeral from 'react-numeral';
import axios from "axios";
import Select from "react-select";
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap'
import LoadingPage from '../../../Loading/LoadingPage';

import DataPoint from './DataPoint';
import MediaGallery from './MediaGallery';

const positiveThreshold = 0.30
const negativeThreshold = -0.30


const bizmojiMapping = {
    'heart': 'Happy',
    'smile': 'Pleased',
    'thumbsup': 'Trusting',
    'wink': 'Stimulated',
    'sweat': 'Stress',
    'disappointed': 'Disappointment',
    'neutral_face': 'Neglected',
    'grin': 'Pleased',
    'angry': 'Frustrated',
    'sob': 'Disappointment',
    'mask': null
}

export class DataPointModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            sentimentFilter: null,
            sortFilter: 'newest',
            sortDropdown: [
                {
                    value: 'oldest',
                    label: `Date (Oldest)`
                },
                {
                    value: 'newest',
                    label: `Date (Newest)`
                },
                {
                    value: 'highestIntensity',
                    label: 'Intensity (Highest)'
                },
                {
                    value: 'lowestIntensity',
                    label: 'Intensity (Lowest)'
                },
                {
                    value: 'highestEngagement',
                    label: 'Engagement (Highest)'
                },
                {
                    value: 'lowestEngagement',
                    label: 'Engagement (Lowest)'
                }
            ],
            emotionFilter: null,
            emotionDropdown: [],
            dataPoints: [],
            filteredDataPoints: [],
            mediaDataPoints: [],
            filteredMediaDataPoints: [],
            activeTab: '1',
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isOpen !== prevProps.isOpen && this.props.isOpen == true) {
            this.setState({
                loading: true
            })

            axios.get(`${process.env.SERVER_URL}/alerts/getDataPoints`, 
                {
                    params: {
                        dataPointIds: this.props.dataPointIds
                    },
                    headers: {
                        'Authorization': "Bearer " + JSON.parse(localStorage.getItem('user'))['token']
                    }
                }
            // axios.get(`${process.env.SERVER_URL}/data-point/getDataPoints`, 
            //     {
            //         params: {
            //             shareCode: this.props.widgetFilter,
            //             searchType: 'Context',
            //             searchTerm: this.props.header,
            //             userName: null,
            //             userSource: null
            //         },
            //         headers: {
            //             'Authorization': "Bearer " + JSON.parse(localStorage.getItem('user'))['token']
            //         }
            //     }
            ).then(response =>  {
                if (response['status'] == 200) {
                    let filteredDataPoints = response['data'];
                    const mediaDataPoints = []
                    let filteredMediaDataPoints = []
                    const uniqueEmotions = []
                    const emotionDropdown = []

                    filteredDataPoints.forEach(dp => {
                        if (dp['metadata'] != null && 'media' in dp['metadata'] && dp['metadata']['media'].length > 0) {
                            dp['metadata']['media'].forEach(dpm => {
                                const mediaDataPoint = {
                                    engagement: dp['engagement'],
                                    epoch_time: dp['epoch_time'],
                                    sentiment: dp['sentiment'],
                                    source: dp['source'],
                                    text: dp['text'],
                                    user_name: dp['user_name'],
                                    metadata: {
                                        user: dp['metadata']['user'],
                                        media: {
                                            type: dpm['type'],
                                            url: dpm['url']
                                        }
                                    }
                                }
                                mediaDataPoints.push(mediaDataPoint)
                                filteredMediaDataPoints.push(mediaDataPoint)
                            })
                        } 

                        if (dp['emojis'] !== null) {
                            dp['emojis'] = dp['emojis'].map(emoji => {
                                if (bizmojiMapping[emoji] !== null) {
                                    if (!uniqueEmotions.includes(bizmojiMapping[emoji])) {
                                        uniqueEmotions.push(bizmojiMapping[emoji])

                                        emotionDropdown.push({
                                            value: bizmojiMapping[emoji],
                                            label: bizmojiMapping[emoji]
                                        })
                                    }

                                    return bizmojiMapping[emoji]
                                } else {
                                    return null
                                }
                            })
                        }
                    })  

                    emotionDropdown.sort((a, b) => a['value'].localeCompare(b['value']) )

                    filteredDataPoints.sort((a, b) => b['epoch_time'] - a['epoch_time'])
                    filteredMediaDataPoints.sort((a, b) => b['epoch_time'] - a['epoch_time'])

                    if (this.props.sentFilter == 'pos') {
                        filteredDataPoints = filteredDataPoints.filter(dp => dp['sentiment'] >= positiveThreshold);
                        filteredMediaDataPoints = filteredMediaDataPoints.filter(dp => dp['sentiment'] >= positiveThreshold);
                    } else if (this.props.sentFilter == 'neg') {
                        filteredDataPoints = filteredDataPoints.filter(dp => dp['sentiment'] <= negativeThreshold);
                        filteredMediaDataPoints = filteredMediaDataPoints.filter(dp => dp['sentiment'] <= negativeThreshold);
                    } else if (this.props.sentFilter == 'neut') {
                        filteredDataPoints = filteredDataPoints.filter(dp => dp['sentiment'] < positiveThreshold && dp['sentiment'] > negativeThreshold);
                        filteredMediaDataPoints = filteredMediaDataPoints.filter(dp => dp['sentiment'] < positiveThreshold && dp['sentiment'] > negativeThreshold);
                    }

                    this.setState({
                        loading: false,
                        sentimentFilter: this.props.sentFilter,
                        sortFilter: 'newest',
                        dataPoints: response['data'],
                        emotionDropdown: emotionDropdown,
                        mediaDataPoints: mediaDataPoints,
                        filteredDataPoints: filteredDataPoints,
                        filteredMediaDataPoints: filteredMediaDataPoints
                    })
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }
    onFiltersSelect = () => {
        let filteredDataPoints = this.state.dataPoints;
        let filteredMediaDataPoints = this.state.mediaDataPoints;

        if (this.state.sentimentFilter) {
            if (this.state.sentimentFilter == 'pos') {
                filteredDataPoints = filteredDataPoints.filter(dp => dp['sentiment'] >= positiveThreshold);
                filteredMediaDataPoints = filteredMediaDataPoints.filter(dp => dp['sentiment'] >= positiveThreshold);
            } else if (this.state.sentimentFilter == 'neg') {
                filteredDataPoints = filteredDataPoints.filter(dp => dp['sentiment'] <= negativeThreshold);
                filteredMediaDataPoints = filteredMediaDataPoints.filter(dp => dp['sentiment'] <= negativeThreshold);
            } else if (this.state.sentimentFilter == 'neut') {
                filteredDataPoints = filteredDataPoints.filter(dp => dp['sentiment'] < positiveThreshold && dp['sentiment'] > negativeThreshold);
                filteredMediaDataPoints = filteredMediaDataPoints.filter(dp => dp['sentiment'] < positiveThreshold && dp['sentiment'] > negativeThreshold);
            }
        }

        if (this.state.emotionFilter && this.state.emotionFilter.length > 0) {
            filteredDataPoints = filteredDataPoints.filter(dp => {
                let overallFilter = true;

                this.state.emotionFilter.forEach(e => {
                    let filterPass = false;

                    if (dp['emojis']) {
                        dp['emojis'].forEach(dpe => {
                            if (dpe == e) filterPass = true
                        })
                    }

                    if (!filterPass) {
                        overallFilter = false
                    }
                })

                if (overallFilter) return true
                else return false
            });

            filteredMediaDataPoints = filteredMediaDataPoints.filter(dp => {
                let overallFilter = true;

                this.state.emotionFilter.forEach(e => {
                    let filterPass = false;

                    if (dp['emojis']) {
                        dp['emojis'].forEach(dpe => {
                            if (dpe == e) filterPass = true
                        })
                    }

                    if (!filterPass) {
                        overallFilter = false
                    }
                })

                if (overallFilter) return true
                else return false
            });
        }

        if (this.state.sortFilter) {
            if (this.state.sortFilter == 'newest') {
                filteredDataPoints.sort((a, b) => b['epoch_time'] - a['epoch_time'])
                filteredMediaDataPoints.sort((a, b) => b['epoch_time'] - a['epoch_time'])
            } else if (this.state.sortFilter == 'oldest') {
                filteredDataPoints.sort((a, b) => a['epoch_time'] - b['epoch_time'])
                filteredMediaDataPoints.sort((a, b) => a['epoch_time'] - b['epoch_time'])
            } else if (this.state.sortFilter == 'highestIntensity') {
                filteredDataPoints.sort((a, b) => Math.abs(b['sentiment']) - Math.abs(a['sentiment']))
                filteredMediaDataPoints.sort((a, b) => Math.abs(b['sentiment']) - Math.abs(a['sentiment']))
            } else if (this.state.sortFilter == 'lowestIntensity') {
                filteredDataPoints.sort((a, b) => Math.abs(a['sentiment']) - Math.abs(b['sentiment']))
                filteredMediaDataPoints.sort((a, b) => Math.abs(a['sentiment']) - Math.abs(b['sentiment']))
            } else if (this.state.sortFilter == 'highestEngagement') {
                filteredDataPoints.sort((a, b) => {
                    const aEngagement = Object.values(a['engagement']).reduce((accumulator, val) => {
                        return accumulator + val
                    })
                    const bEngagement = Object.values(b['engagement']).reduce((accumulator, val) => {
                        return accumulator + val
                    })
                    return bEngagement - aEngagement
                })

                filteredMediaDataPoints.sort((a, b) => {
                    const aEngagement = Object.values(a['engagement']).reduce((accumulator, val) => {
                        return accumulator + val
                    })
                    const bEngagement = Object.values(b['engagement']).reduce((accumulator, val) => {
                        return accumulator + val
                    })
                    return bEngagement - aEngagement
                })
            } else if (this.state.sortFilter == 'lowestEngagement') {
                filteredDataPoints.sort((a, b) => {
                    const aEngagement = Object.values(a['engagement']).reduce((accumulator, val) => {
                        return accumulator + val
                    })
                    const bEngagement = Object.values(b['engagement']).reduce((accumulator, val) => {
                        return accumulator + val
                    })
                    return aEngagement - bEngagement
                })

                filteredMediaDataPoints.sort((a, b) => {
                    const aEngagement = Object.values(a['engagement']).reduce((accumulator, val) => {
                        return accumulator + val
                    })
                    const bEngagement = Object.values(b['engagement']).reduce((accumulator, val) => {
                        return accumulator + val
                    })
                    return aEngagement - bEngagement
                })
            }
        }

        const uniqueEmotions = []
        const emotionDropdown = []

        filteredDataPoints.forEach(dp => {
            if (dp['emojis'] !== null) {
                dp['emojis'].forEach(emoji => {
                    if (!uniqueEmotions.includes(emoji)) {
                        uniqueEmotions.push(emoji)

                        emotionDropdown.push({
                            value: emoji,
                            label: emoji
                        })
                    }
                })
            }
        })

        this.setState({
            filteredDataPoints: filteredDataPoints,
            filteredMediaDataPoints: filteredMediaDataPoints,
            emotionDropdown: emotionDropdown,
        })
    }
    onSortSelect = (e) => {
        this.setState({
            sortFilter: e['value']
        }, () => {
            this.onFiltersSelect()
        })
    }
    onEmotionSelect = (emotion) => {
        this.setState({
            emotionFilter: emotion.map(e => e['value'])
        }, () => {
            this.onFiltersSelect()
        })
    }
    onSentimentSelect = (sent) => {
        this.setState({
            sentimentFilter: sent === this.state.sentimentFilter ? null : sent
        }, () => {
            this.onFiltersSelect()
        })
    }
    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    render() { 
        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={() => this.props.toggle(null)}
                className="data-point-modal"
            >
                <ModalHeader toggle={() => this.props.toggle(null)}>
                    {this.props.header} ({this.state.filteredDataPoints.length > 0 ? <Numeral value={this.state.filteredDataPoints.length} format={'0,0'}/> : 0} data points)
                </ModalHeader>
                <ModalBody>
                    {
                        this.props.userMetadata && this.props.userMetadata.user &&
                            <div className="social-engagement__modal-user-card">
                                <div className="social-engagement__modal-user-metadata-div">
                                    <div className="social-engagement__modal-user-info">
                                        <div style={{display: 'flex', gap: '0.5rem'}}>
                                            <img
                                                className="social-engagement__modal-user-profile-image"
                                                src={this.props.userMetadata.user['profile_image_url'] ? this.props.userMetadata.user['profile_image_url'] : 'https://ucarecdn.com/cea023f8-a13d-446f-9c4c-fc68a379372f/'}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src="https://ucarecdn.com/cea023f8-a13d-446f-9c4c-fc68a379372f/";
                                                }}
                                            />
                                            <div className="social-engagement__modal-user-names">
                                                <div style={{display: 'flex', gap: '1rem'}}>
                                                    <div style={{display: 'flex', flexDirection: 'column', gap: '0.2rem'}}>
                                                        <span className="social-engagement__modal-user-info-name">
                                                            {
                                                                this.props.userMetadata.user['name'] ?
                                                                    this.props.userMetadata.user['name']
                                                                    :
                                                                    this.props.userMetadata.user['screen_name']
                                                            }
                                                        </span>
                                                        {
                                                            this.props.userMetadata.user['name'] && this.props.userMetadata.user['screen_name'] &&
                                                                <span className="social-engagement__modal-user-info-screen-name">
                                                                    @{this.props.userMetadata.user['screen_name']}
                                                                </span>
                                                        }
                                                    </div>
                                                    <div className="vertical-center">
                                                        {
                                                            this.props.source == 'Twitter' &&
                                                                <img
                                                                    style={{width: '25px', height: '25px'}} 
                                                                    src="https://ucarecdn.com/8aedb345-4dd2-4a2e-8a46-084760200904/"
                                                                />
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    this.props.userMetadata.user['created_at'] &&
                                                        <span>
                                                            Created At: {
                                                                Number.isInteger(this.props.userMetadata.user['created_at']) ? 
                                                                    moment.unix(this.props.userMetadata.user['created_at']).format("MMM DD, YYYY") 
                                                                    : 
                                                                    moment(this.props.userMetadata.user['created_at']).format("MMM DD, YYYY")
                                                            }
                                                        </span>
                                                }
                                            </div>
                                        </div>
                                        <div className="social-engagement__modal-user-metadata">
                                            {
                                                this.props.userMetadata['user']['followers_count'] &&   
                                                    <div className="social-engagement__modal-user-metadata-categories">
                                                        {
                                                            this.props.userMetadata.user['followers_count'] == 0 ?
                                                                <span className="social-engagement__modal-user-metadata-span">
                                                                    <span className="social-engagement__modal-user-metadata-number" >
                                                                        0
                                                                    </span> Followers
                                                                </span>
                                                                :
                                                                <span className="social-engagement__modal-user-metadata-span">
                                                                    <Numeral 
                                                                        className="social-engagement__modal-user-metadata-number" 
                                                                        value={this.props.userMetadata.user['followers_count']} 
                                                                        format={'0,0'}
                                                                    /> Followers
                                                                </span>
                                                        }
                                                    </div>
                                            }
                                            {
                                                this.props.userMetadata['user']['friends_count'] &&
                                                    <div className="social-engagement__modal-user-metadata-categories">
                                                        {
                                                            this.props.userMetadata.user['friends_count'] == 0 ?
                                                                <span className="social-engagement__modal-user-metadata-span">
                                                                    <span className="social-engagement__modal-user-metadata-number" >
                                                                        0
                                                                    </span> Following
                                                                </span>
                                                                :
                                                                <span className="social-engagement__modal-user-metadata-span">
                                                                    <Numeral 
                                                                        className="social-engagement__modal-user-metadata-number" 
                                                                        value={this.props.userMetadata.user['friends_count']} 
                                                                        format={'0,0'}
                                                                    /> Following
                                                                </span>
                                                        }
                                                    </div>
                                            }
                                            {
                                                this.props.userMetadata['user']['statuses_count'] &&
                                                    <div className="social-engagement__modal-user-metadata-categories">
                                                        {
                                                            this.props.userMetadata.user['statuses_count'] == 0 ?
                                                                <span className="social-engagement__modal-user-metadata-span">
                                                                    <span className="social-engagement__modal-user-metadata-number" >
                                                                        0
                                                                    </span> Tweets
                                                                </span>
                                                                :
                                                                <span className="social-engagement__modal-user-metadata-span">
                                                                    <Numeral 
                                                                        className="social-engagement__modal-user-metadata-number" 
                                                                        value={this.props.userMetadata.user['statuses_count']} 
                                                                        format={'0,0'}
                                                                    /> Tweets
                                                                </span>
                                                        }
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    <hr/>
                                    <div>
                                        <span>
                                            {this.props.userMetadata.user['description']}
                                        </span> 
                                    </div>
                                </div>
                            </div>
                    }
                    <div className="tait-alert__dpm-filters">
                        <div className="tait-alert__dpm-sent-filters">
                            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                                <span>
                                    Filter By Sentiment:
                                </span>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', gap: '0.5rem'}}>
                                <div className="tait-alert__dpm-sent-filter-icons">
                                    <Button
                                        className={`tait-alert__dpm-sent-filter ${this.state.sentimentFilter == 'pos' ? 'active' : ''}`}
                                        onClick={() => this.onSentimentSelect('pos')}
                                        onMouseDown={e => e.preventDefault()}
                                    >
                                        <img src="https://ucarecdn.com/d87f0afc-a1cb-4234-a6c1-4464cbfa0087/"/>
                                    </Button>
                                    <span>
                                        Positive
                                    </span>
                                </div>
                                <div className="tait-alert__dpm-sent-filter-icons">
                                    <Button
                                        className={`tait-alert__dpm-sent-filter ${this.state.sentimentFilter == 'neut' ? 'active' : ''}`}
                                        onClick={() => this.onSentimentSelect('neut')}
                                        onMouseDown={e => e.preventDefault()}
                                    >
                                        <img src="https://ucarecdn.com/1471243f-44b7-46d8-ab32-c4521400e59a/"/>
                                    </Button>
                                    <span>
                                        Neutral
                                    </span>
                                </div>
                                <div className="tait-alert__dpm-sent-filter-icons">
                                    <Button
                                        className={`tait-alert__dpm-sent-filter ${this.state.sentimentFilter == 'neg' ? 'active' : ''}`}
                                        onClick={() => this.onSentimentSelect('neg')}
                                        onMouseDown={e => e.preventDefault()}
                                    >
                                        <img src="https://ucarecdn.com/821a0a48-8bae-41ae-b8de-f6c013b464bc/"/>
                                    </Button>
                                    <span>
                                        Negative
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="tait-alert__dpm-emotion-filters">
                            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                                <span>
                                    Filter By Emotion:
                                </span>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', gap: '0.5rem'}}>
                                <Select
                                    isMulti
                                    className="tait-alert__select dpm__emotion-select"
                                    classNamePrefix="select"
                                    placeholder="Select Emotion"
                                    onChange={this.onEmotionSelect}
                                    options={this.state.emotionDropdown}
                                />
                            </div>
                        </div>
                        <div className="tait-alert__dpm-sort-filter">
                            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                                <span>
                                    Sort By:
                                </span>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                                <Select
                                    className="tait-alert__select"
                                    classNamePrefix="select"
                                    placeholder="Select Entity"
                                    value={
                                        this.state.sortDropdown.find(e => 
                                            e['value'] == this.state.sortFilter
                                        )
                                    }
                                    onChange={this.onSortSelect}
                                    options={this.state.sortDropdown}
                                />
                            </div>
                        </div>
                    </div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={this.state.activeTab == '1' ? 'active' : ''}
                                onClick={() => this.toggleTab('1')}
                            >
                                Text
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={this.state.activeTab == '2' ? 'active' : ''}
                                onClick={() => this.toggleTab('2')}
                            >
                                Media
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <div className="tait-alert__dpm-dps">
                                {
                                    this.state.loading ?
                                        <LoadingPage colorTheme="light" mini={true}/>
                                    :
                                    this.state.filteredDataPoints.length > 0 ?
                                        this.state.filteredDataPoints.map(dp => (
                                            <DataPoint
                                                key={dp['id']}
                                                id={dp['id']}
                                                engagement={dp['engagement']}
                                                epochTime={dp['epoch_time']}
                                                metadata={dp['metadata']}
                                                source={dp['source']}
                                                sentiment={dp['sentiment']}
                                                text={dp['text']}
                                                userLocation={dp['user_location']}
                                                userName={dp['user_name']}
                                            />
                                        ))
                                        :
                                        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '1rem'}}>
                                            <span style={{fontSize: '20px'}}>
                                                No text found.
                                            </span>
                                        </div>
                                }
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            {
                                this.state.filteredMediaDataPoints.length > 0 ?
                                    <MediaGallery 
                                        dataPoints={this.state.filteredMediaDataPoints}
                                    />
                                    :
                                    <div style={{display: 'flex', justifyContent: 'center', paddingTop: '1rem'}}>
                                        <span style={{fontSize: '20px'}}>
                                            No media found.
                                        </span>
                                    </div>
                            }
                        </TabPane>
                    </TabContent>
                </ModalBody>
                <ModalFooter />
            </Modal>
        );
    };
}

const mapStateToProps = (state, ownProps) => {
    return {
        widgetFilter: state['alertsFilters']['widget']
    }
}

export default connect(mapStateToProps)(DataPointModal);