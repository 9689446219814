import React, { useEffect, useState, useReducer } from 'react';
import moment from 'moment';

const positiveThreshold = 0.30;
const negativeThreshold = -0.30;

const DataPoint = ({ engagement, epochTime, metadata, source, sentiment, text, userLocation, userName }) => {
    const [state, setState] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            sentimentType: null,
            sourceUrl: 'https://ucarecdn.com/1b7a2fed-0ed2-439e-bc56-c704dfb1034a/',
            profileImageUrl: 'https://ucarecdn.com/cea023f8-a13d-446f-9c4c-fc68a379372f/',
            transformedUserName: null
        }
    )

    useEffect(() => {
        let tempSentimentType = null;
        let tempSourceUrl = 'https://ucarecdn.com/1b7a2fed-0ed2-439e-bc56-c704dfb1034a/';
        let tempProfileImageUrl = 'https://ucarecdn.com/cea023f8-a13d-446f-9c4c-fc68a379372f/';
        let tempTransformedUserName = null;

        if (sentiment >= positiveThreshold) tempSentimentType = 'positive';
        else if (sentiment <= negativeThreshold) tempSentimentType = 'negative';
        else tempSentimentType = 'neutral';

        if (source == 'Twitter') tempSourceUrl = 'https://ucarecdn.com/8aedb345-4dd2-4a2e-8a46-084760200904/';
        else if (source == 'Reddit') tempSourceUrl = 'https://ucarecdn.com/2a036a68-274f-4805-90b9-ef8efb23b6fd/';
        else if (source == 'Facebook') tempSourceUrl = 'https://ucarecdn.com/6d44a863-0e9f-4349-88aa-91d149d5bc4d/';
        else if (source == 'Instagram') tempSourceUrl = 'https://ucarecdn.com/f67a7d0c-21db-4faa-9ef1-86e4e0f111ee/';
        else if (source == 'YouTube') tempSourceUrl = 'https://ucarecdn.com/9d0e7160-2e6a-4d3f-8111-b2257e706e47/youtube.png';
        else if (source == 'TikTok') tempSourceUrl = 'https://ucarecdn.com/2572cc0f-9d55-40ef-92c7-dc7fd0df7713/tiktok.png';
        else if (source == 'Truth Social') tempSourceUrl = 'https://ucarecdn.com/29610c89-c385-456c-9e84-522d69fcdc5b/';

        if (metadata && metadata['user']) {
            if (metadata['user']['profile_image_url']) tempProfileImageUrl = metadata['user']['profile_image_url']

            if ('screen_name' in metadata['user']) {
                tempTransformedUserName = metadata['user']['screen_name']
            } else if ('name' in metadata['user']) {
                tempTransformedUserName = metadata['user']['name']
            } else {
                tempTransformedUserName = userName
            }
        } else {
            if (source == 'Reddit') {
                tempTransformedUserName = userName.split('_').slice(0, -1).join('_')
            } else {
                tempTransformedUserName = userName
            }
        }

        setState({
            sentimentType: tempSentimentType,
            sourceUrl: tempSourceUrl,
            profileImageUrl: tempProfileImageUrl,
            transformedUserName: tempTransformedUserName
        })
    }, [])

    const goToSource = (source) => {
        if (source !== undefined && source !== '' && source !== null) {
            window.open(source, '_blank').focus();
        }
    }

    return (
        <div 
            className={`data-point ${state['sentimentType']} ${(metadata && metadata['url']) ? 'active' : ''}`}
            onClick={() => goToSource(metadata['url'])}
        >
         {/*   <div className="hide-for-mobile">
                <img
                    className="data-point__user-profile-image"
                    src={state['profileImageUrl']}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src="https://ucarecdn.com/cea023f8-a13d-446f-9c4c-fc68a379372f/";
                    }}
                />
            </div>*/}
            <div className={`data-point__content`}>
                <div className="data-point__header">   
                    <div style={{display: 'flex', gap: '0.5rem'}}>
                        <img
                            className="data-point__user-profile-image"
                            src={state['profileImageUrl']}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="https://ucarecdn.com/cea023f8-a13d-446f-9c4c-fc68a379372f/";
                            }}
                        />
                        <div className="data-point__header-user">
                            <div className="data-point__header-user-group">
                                <span className="data-point__header-name">
                                    {state['transformedUserName']}
                                </span>
                               {/* {
                                    state['transformedScreenName'] && 
                                        <span className="data-point__header-screen-name">
                                            {state['transformedScreenName']}
                                        </span>
                                }*/}
                            </div>
                            <span>
                                {moment.unix(epochTime).format("MMM DD, YYYY hh:mm:ss a")}
                            </span>
                        </div>
                    </div>
                    <div>
                       {
                            state['sourceUrl'] && 
                                <img 
                                    className="data-point__header-source-img"
                                    src={state['sourceUrl']}
                                />
                        }
                    </div>
                </div>
                <hr />
                <div className='data-point__body'>
                    <span>
                        {text}
                    </span>
                </div>
                <hr />
                <div className="data-point__footer">
                    {
                        engagement &&
                            <div className="data-point__footer-engagement">
                                {
                                    Object.keys(engagement).map((eng) => (
                                        <div key={eng}>
                                            {engagement[eng]} {eng}
                                        </div>
                                    ))
                                }
                            </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default DataPoint;