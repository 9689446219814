export const setAlertsThemeFilter = (theme) => ({
    type: 'SET_ALERTS_THEME_FILTER',
    theme
});

export const setAlertsAssetFilter = (asset) => ({
    type: 'SET_ALERTS_ASSET_FILTER',
    asset
});

export const setAlertsWidgetFilter = (widget) => ({
    type: 'SET_ALERTS_WIDGET_FILTER',
    widget
});