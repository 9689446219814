import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Row,
    Col,
    Button,
    Label,
    Tooltip
} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
    
import LoadingPage from '../../Loading/LoadingPage';
import CampaignSelect from './CampaignSelect';
import AlertSelect from './AlertSelect';
import EntitySelect from './EntitySelect';

import { saveMailchimpCampaignTrigger } from '../../../actions/integrations';

const MappingRow = ({ id, auth, mailchimp, campaigns, alerts, saveMailchimpCampaignTrigger, campaignTriggerSave }) => {
    const [successStatus, setSuccessStatus] = useState("")
    const [errorStatus, setErrorStatus] = useState("")
    const [saveLoading, setSaveLoading] = useState(null)
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const formikRef = React.createRef()

    const onSave = (values) => {
        setSaveLoading(true)

        values['status'] = 'Pending'

        saveMailchimpCampaignTrigger(auth['id'], values).then(res => {
            if (res['status'] == 200) {
                campaignTriggerSave(values)
                setSuccessStatus('Campaign trigger successfully saved.')
                setErrorStatus('')
                setSaveLoading(false)
            } else {
                setSuccessStatus('')
                setErrorStatus('There was an error processing your request.')
                setSaveLoading(false)
            }
        })
    }

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen)
    }

    return (
        <Row>
            <Col xs={12}>
                {
                    saveLoading ?
                        <LoadingPage colorTheme="light" mini/> 
                        :
                        <div className="mailchimp-mapping-row">
                            <Formik
                                innerRef={formikRef}
                                initialValues={
                                    { 
                                        campaign: null, 
                                        alert: null,
                                        triggers: null,
                                        matchAnyWord: false
                                    }
                                }
                                validate={values => {
                                    const errors = {};
                                    if (!values.campaign || values.campaign == "") {
                                        errors.campaign = 'Campaign cannot be empty.';
                                    } 
                                    if (values.alert === null) {
                                        errors.alert = 'Alert cannot be empty.';
                                    } 
                                    if (!values.triggers || values.triggers == "") {
                                        errors.triggers = 'Triggers cannot be empty.';
                                    } 
                                    {/*console.log(errors)*/}
                                    
                                    return errors;
                                }}
                                onSubmit={onSave}
                            >
                                {({ isSubmitting }) => (
                                    <Form className="mailchimp__form">
                                        <Row className="mailchimp__row">
                                            <Col xs={12} md={3} className="mailchimp__col">
                                                <div className="mailchimp__input">
                                                    <CampaignSelect
                                                        name='campaign'
                                                        options={campaigns.filter(c => c.status != 'sent').map(c => (
                                                                {
                                                                    value: c['id'],
                                                                    label: c['title'],              
                                                                }
                                                            )).sort((a, b) => a['label'].localeCompare(b['label']))
                                                        }
                                                        type="campaign"
                                                    />
                                                    <ErrorMessage 
                                                        name="campaign" 
                                                        component="div" 
                                                        className="mailchimp__error-text"
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3} className="mailchimp__col">
                                                <div className="mailchimp__input">
                                                    <AlertSelect
                                                        name='alert'
                                                        options={alerts.map((a, idx) => ({
                                                            value: {
                                                                theme: a['theme'],
                                                                asset: a['asset'],
                                                                searchTerm: a['searchTerm']
                                                            },
                                                            label: `Alert ${idx+1} - Theme: ${a['theme']}, Asset: ${a['asset']}, Searchterm: ${a['searchTerm']}`
                                                        }))}
                                                        type="alert"
                                                    />
                                                    <ErrorMessage 
                                                        name="alert" 
                                                        component="div" 
                                                        className="mailchimp__error-text" 
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3} className="mailchimp__col">
                                                <div className="mailchimp__input">
                                                    <EntitySelect
                                                        name='triggers'
                                                        type="triggers"
                                                    />
                                                    <ErrorMessage 
                                                        name="triggers" 
                                                        component="div" 
                                                        className="mailchimp__error-text" 
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3} className="mailchimp__col" style={{justifyContent: 'space-between'}}>
                                                <Label check className="mailchimp__checkbox-label">
                                                    <div style={{display: 'flex', gap: '0.2rem'}}>
                                                        <Field type="checkbox" name="matchAnyWord" />
                                                        {' '}
                                                        Match Any Word
                                                        <div  
                                                            id={`mailchimp__tooltip-${id}`}
                                                            className='alert-card-header__tooltip-image'
                                                        >
                                                            <img
                                                                src='https://ucarecdn.com/6900d1db-f5b1-4be3-a504-4bbd3161439a/information.png'
                                                            />
                                                        </div>
                                                    </div>
                                                </Label>
                                                <Button 
                                                    className="mailchimp__save-button"
                                                    type="submit"
                                                    onMouseDown={e => e.preventDefault()}
                                                >
                                                    Save
                                                </Button>
                                            </Col>
                                        </Row>
                                        <div>
                                            {
                                                successStatus && successStatus != "" && 
                                                    <div className="mailchimp__success-status">
                                                        <span>
                                                            {successStatus}
                                                        </span>
                                                    </div>
                                            }
                                            {
                                                errorStatus && errorStatus != "" && 
                                                    <div className="mailchimp__error-status">
                                                        <span>
                                                            {errorStatus}
                                                        </span>
                                                    </div>
                                            }
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                }
            </Col>
            <Tooltip 
                placement="right" 
                isOpen={tooltipOpen} 
                target={`mailchimp__tooltip-${id}`}
                toggle={toggleTooltip}
            >
                This allows you to match any words in your search term. It will widen your engagement. Exact match will make engagement focused on exactly the set term.
            </Tooltip>
        </Row>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state['auth']
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveMailchimpCampaignTrigger: (userId, trigger) => dispatch(saveMailchimpCampaignTrigger(userId, trigger))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(MappingRow)