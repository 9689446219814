import React from "react";
import { useField } from "formik";

import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';

function Dropdown({ name, options, type }) {
    const [field, meta, { setValue }] = useField({ name });

    const handleChange = (
        newValue: OnChangeValue<Option, false>,
        actionMeta: ActionMeta<Option>
    ) => {
        if (newValue) setValue(newValue['value']);
        else setValue("");
    };

    return (
        <CreatableSelect
            name={name}
            isClearable
            placeholder={`Select or create ${type}...`}
            className="onboarding__dropdown"
            onChange={handleChange}
            options={options}
            value={field['value'] != '' ? {value: field['value'], label: field['value']} : null}
        />
    );
}

export default React.memo(Dropdown);
