import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import Numeral from 'numeral';
import {
    Row,
    Col,
    Tooltip
} from 'reactstrap';

const Description = ({ count, startDate, endDate, totalUsers, totalEngagement, assetName, searchFilter, sourceFilter }) => {
    const [name, setName] = useState(assetName);
    const [tooltipOpen, setTooltipOpen] = useState(false)

    useEffect(() => {
        let tempName = assetName;

        if (searchFilter && searchFilter !== '') {
            tempName += ` (${searchFilter})`
        }

        if (sourceFilter && sourceFilter !== '') {
            tempName += ` on ${sourceFilter}`
        }

        setName(tempName)
    }, [assetName, searchFilter, sourceFilter]);

    const onTooltipToggle = () => {
        setTooltipOpen(!tooltipOpen)
    }

    const onCommunityClick = () => {
        const socialEngagementDiv = document.getElementById("social-engagement");

        if (socialEngagementDiv) {
            socialEngagementDiv.scrollIntoView()
        }
    }

    return (
        <div className={'tait-alert__card'}>
            <div className={'tait-alert__card-body'}>
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <div>
                            {
                                count > 0 ?
                                    <div style={{display: 'flex', gap: '0.5rem'}}>
                                        <span>
                                            talkAItive searched for <b>{name}</b> from <b>{startDate}</b> to <b>{endDate}</b>   
                                            <div  
                                                id={'description-tooltip'}
                                                style={{ 
                                                    display: 'inline', marginLeft: '0.5rem',
                                                    maxWidth: '200px'
                                                }}
                                            >
                                                <img
                                                    style={{ width: '20px', height: '20px' }}
                                                    src='https://ucarecdn.com/6900d1db-f5b1-4be3-a504-4bbd3161439a/information.png'
                                                />
                                            </div>
                                        </span>
                                    </div>
                                    :
                                    <span>
                                        talkAItive searched for <b>{name}</b>
                                    </span>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={4} lg={4}>
                        <div className="description__group">
                            <img
                                className="description__icon"
                                src='/images/description/volume.webp'
                            />
                            <div className="description__subgroup" style={{ color: '#EEC200' }}>
                                <span className="description__subgroup-count">
                                    { Numeral(count).format("0,0") }
                                </span>
                                <span className="description__subgroup-desc">
                                    Data Points
                                </span>
                            </div> 
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4}>
                        <div 
                            className="description__group active"
                            onClick={() => onCommunityClick()}
                        >
                            <img
                                className="description__icon"
                                src='/images/description/people.webp'
                            />
                            <div className="description__subgroup" style={{ color: '#009B3E' }}>
                                <span className="description__subgroup-count">
                                    { Numeral(totalUsers).format("0,0") }
                                </span>
                                <span className="description__subgroup-desc">
                                    Community Size
                                </span>
                            </div> 
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4}>
                        <div className="description__group">
                            <img
                                className="description__icon"
                                src='/images/description/engagement.webp'
                            />
                            <div className="description__subgroup" style={{ color: '#0093C3' }}>
                                <span className="description__subgroup-count">
                                    { Numeral(totalEngagement).format("0,0") }
                                </span>
                                <span className="description__subgroup-desc">
                                    Total Engagement
                                    <p>
                                        (includes likes, comments)
                                    </p>
                                </span>
                            </div> 
                        </div>
                    </Col>
                </Row>
            </div>
            {
                count > 0 &&
                    <Tooltip 
                        placement="right" 
                        isOpen={tooltipOpen} 
                        target={'description-tooltip'}
                        toggle={onTooltipToggle}
                    >   
                        <p>This section provides volume aggregates for your chatter.</p>
                        <p>Volume - Shows chatter volume by channel.</p>
                        <p>Community Size - This is the number of unique users who are currently sentimentally active relative to your search term. They are the ideal users to engage with nuanced content.</p>
                        <p>Engagement - This showcases engagement across covered channels relative to your search term. It includes adding up metrics like Likes, Retweets, Comments, Reactions, UpVotes, DownVotes depending on the social channel.</p>
                    </Tooltip>
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        assetName: state['alerts']['widgetAnalysis']['name'],
        searchFilter: state['dataPointSearch']['searchFilter'],
        sourceFilter: state['dataPointSearch']['sourceFilter'],
    };
};

export default connect(mapStateToProps)(Description);