import React from 'react';
import uuid from 'react-native-uuid';
import {
    Row,
    Col,
    Button,
    Tooltip
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from 'formik';

import LoadingPage from '../Loading/LoadingPage';
import CardHeader from '../Alerts/Widget/Header/CardHeader';
import MultiSelect from "./OnboardingTable/MultiSelect";
import Dropdown from "./OnboardingTable/Dropdown";
import Input from "./OnboardingTable/Input";
import InputThreshold from "./OnboardingTable/InputThreshold";

export class SearchtermSetup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            saveLoading: false,
            successStatus: "",
            errorStatus: "",
            emailNotificationTooltipOpen: false
        }

        this.formikRef = React.createRef();
    }
    emailNotificationTooltipToggle = () => {
        this.setState({
            emailNotificationTooltipOpen: !this.state.emailNotificationTooltipOpen
        })
    }
    toggleSaveLoading = (value) => {
        if (value) {
            this.setState({
                saveLoading: value
            })  
        } else {
            this.setState({
                saveLoading: !this.state.saveLoading
            })
        }
    }
    validationCheck = (tasks) => {
        let taskMapping = {};

        for (const task of nonEmptyTasks) {
            if ([task['theme'], task['asset'], task['searchTerm']] in taskMapping) {
                this.setState({ status: "Duplicate Theme + Asset + Search Term" });
                validated = false;
                break;
            } else {
                taskMapping[[task['theme'], task['asset'], task['searchTerm']]] = true;
            }
        }

        return validated;
    }
    onSaveConfig = (values) => {
        this.toggleSaveLoading(true);

        this.props.saveConfig(values).then(r => {
            if (r['status'] == 200) {
                let tempConfig = this.props['config']
                tempConfig['saved'] = true

                this.setState({
                    successStatus: "Your alert has been saved.",
                    errorStatus: "",
                    config: tempConfig
                })

                this.toggleSaveLoading(false);
            } else {
                this.setState({
                    successError: "",
                    errorStatus: "There was an error processing your request."
                })

                this.toggleSaveLoading(false);
            }
        }) 
    }
    onDeleteConfig = () => {
        this.props.deleteConfig(this.formikRef.current.values) 
    }
    render() {
        const themeList = [];
        const assetList = [];

        for (const [theme, assets] of Object.entries(this.props.themeAssets)) {
            themeList.push({ value: theme, label: theme });

            for (var asset of assets) {
                assetList.push({ value: asset, label: asset });
            }
        }

        return (
            <Row>
                <Col xs={12}>
                    <div className={'tait-alert__card'}>
                        <CardHeader
                            title={`Alert ${this.props.num+1}`}
                        />
                        <div className={'tait-alert__card-body'}>
                            <Formik
                                innerRef={this.formikRef}
                                initialValues={
                                    { 
                                        theme: this.props.config['theme'], 
                                        asset: this.props.config['asset'],
                                        searchTerm: this.props.config['searchTerm'],
                                        sources: this.props.config['sources'],
                                        uuid: this.props.config['uuid'],
                                        nevThreshold: this.props.config['nevThreshold'],
                                        posThreshold: this.props.config['posThreshold'],
                                        negThreshold: this.props.config['negThreshold'],
                                        overallThreshold: this.props.config['overallThreshold'],
                                        emailNotifications: this.props.config['emailNotifications']
                                    }
                                }
                                validate={values => {
                                    const errors = {};
                                    if (!values.theme || values.theme == "") {
                                        errors.theme = 'Theme cannot be empty.';
                                    } 
                                    if (!values.asset || values.asset == "") {
                                        errors.asset = 'Asset cannot be empty.';
                                    } 
                                    if (!values.searchTerm || values.searchTerm == "") {
                                        errors.searchTerm = 'Search term cannot be empty.';
                                    } 
                                    if (!values.sources || values.sources.length == 0) {
                                        errors.sources = 'You must select at least one source.';
                                    }
                                    if (values.searchTerm.length > 500) {
                                        errors.searchTerm = 'Search term cannot be more than 500 characters.';
                                    }
                                    if (values.emailNotifications.includes('overall') && (!values.overallThreshold || values.overallThreshold == "")) {
                                        errors.overallThreshold = "Threshold cannot be empty";
                                    }
                                    if (values.emailNotifications.includes('nev') && (!values.nevThreshold || values.nevThreshold == "")) {
                                        errors.nevThreshold = "Threshold cannot be empty";
                                    }
                                    if (values.emailNotifications.includes('pos') && (!values.posThreshold || values.posThreshold == "")) {
                                        errors.posThreshold = "Threshold cannot be empty";
                                    }
                                    if (values.emailNotifications.includes('neg') && (!values.negThreshold || values.negThreshold == "")) {
                                        errors.negThreshold = "Threshold cannot be empty";
                                    }
                                    
                                    return errors;
                                }}
                                onSubmit={this.onSaveConfig}
                            >
                                {({ isSubmitting }) => (
                                    <Form className="onboarding__config-form">
                                        <Row className="onboarding__config-row">
                                            <Col xs={12} md={7} className="onboarding__config-col">
                                                <div className="onboarding__config-labels-div">
                                                    <div className="onboarding__config-label">
                                                        <label htmlFor="theme" >Theme:</label>
                                                    </div>
                                                    <div className="onboarding__config-label">
                                                        <label htmlFor="asset">Asset:</label>
                                                    </div>
                                                    <div className="onboarding__config-label">
                                                        <label htmlFor="sources">Sources:</label>
                                                    </div>
                                                    <div className="onboarding__config-label">
                                                        <label htmlFor="searchTerm">Search Term:</label>
                                                    </div>
                                                </div>
                                                <div className="onboarding__config-inputs-div">
                                                    <div className="onboarding__config-input">
                                                        <Dropdown 
                                                            name='theme'
                                                            options={themeList}
                                                            type="theme"
                                                        />
                                                        <ErrorMessage 
                                                            name="theme" 
                                                            component="div" 
                                                            className="onboarding__config-error-text"
                                                        />
                                                    </div>
                                                    <div className="onboarding__config-input">
                                                        <Dropdown 
                                                            name='asset'
                                                            options={assetList}
                                                            type="asset"
                                                        />
                                                        <ErrorMessage 
                                                            name="asset" 
                                                            component="div" 
                                                            className="onboarding__config-error-text" 
                                                        />
                                                    </div>
                                                    <div className="onboarding__config-input">
                                                        <MultiSelect 
                                                            name="sources"
                                                        />
                                                        <ErrorMessage 
                                                            name="sources" 
                                                            component="div" 
                                                            className="onboarding__config-error-text"
                                                        />
                                                    </div>
                                                    <div className="onboarding__config-input">
                                                        <Input name="searchTerm" />
                                                        <ErrorMessage 
                                                            name="searchTerm" 
                                                            component="div" 
                                                            className="onboarding__config-error-text"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={5}>
                                                <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                                                    <label htmlFor="emailNotif" style={{display: 'flex', gap: '0.2rem'}}>
                                                        <span>Email Notifications</span>
                                                        <div  
                                                            id={`tooltip-id-${this.props.id}`}
                                                            className='alert-card-header__tooltip-image'
                                                        >
                                                            <img
                                                                src='https://ucarecdn.com/6900d1db-f5b1-4be3-a504-4bbd3161439a/information.png'
                                                            />
                                                        </div>
                                                        <span>:</span>
                                                    </label>
                                                    <div role="group" aria-labelledby="emailNotif" className="onboarding__config-email-notif-group">
                                                        <label className="onboarding__config-checkbox-group">
                                                            <Field type="radio" name="emailNotifications" value="all" />
                                                            All alert refreshes
                                                        </label>
                                                        <label className="onboarding__config-checkbox-group">
                                                            <Field type="checkbox" name="emailNotifications" value="nev" />
                                                            <div className="vertical-center">
                                                                When NEV changes by 
                                                            </div>
                                                            <InputThreshold name="nevThreshold"/>
                                                            <div className="vertical-center">
                                                                %
                                                            </div>
                                                            <ErrorMessage 
                                                                name="nevThreshold" 
                                                                component="div" 
                                                                className="vertical-center onboarding__config-error-text"
                                                            />
                                                        </label>
                                                        <label className="onboarding__config-checkbox-group">
                                                            <Field type="checkbox" name="emailNotifications" value="overall" />
                                                            <div className="vertical-center">
                                                                When overall volume changes by 
                                                            </div>
                                                            <InputThreshold name="overallThreshold" />
                                                            <div className="vertical-center">
                                                                %
                                                            </div>
                                                            <ErrorMessage 
                                                                name="overallThreshold" 
                                                                component="div" 
                                                                className="vertical-center onboarding__config-error-text"
                                                            />
                                                        </label>
                                                        <label className="onboarding__config-checkbox-group">
                                                            <Field type="checkbox" name="emailNotifications" value="pos" />
                                                            <div className="vertical-center">
                                                                When positive volume changes by 
                                                            </div>
                                                            <InputThreshold name="posThreshold" />
                                                            <div className="vertical-center">
                                                                %
                                                            </div>
                                                            <ErrorMessage 
                                                                name="posThreshold" 
                                                                component="div" 
                                                                className="vertical-center onboarding__config-error-text"
                                                            />
                                                        </label>
                                                        <label className="onboarding__config-checkbox-group">
                                                            <Field type="checkbox" name="emailNotifications" value="neg" />
                                                            <div className="vertical-center">
                                                                When negative volume changes by 
                                                            </div>
                                                            <InputThreshold name="negThreshold" />
                                                            <div className="vertical-center">
                                                                %
                                                            </div>
                                                            <ErrorMessage 
                                                                name="negThreshold" 
                                                                component="div" 
                                                                className="vertical-center onboarding__config-error-text"
                                                            />
                                                        </label>
                                                        <label className="onboarding__config-checkbox-group">
                                                            <Field type="radio" name="emailNotifications" value="none" />
                                                            None
                                                        </label>
                                                        {/*<div>Picked: {values.picked}</div>*/}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div>
                                            {
                                                this.state.successStatus && this.state.successStatus != "" && 
                                                    <div className="onboarding__success-status">
                                                        <span>
                                                            {this.state.successStatus}
                                                        </span>
                                                    </div>
                                            }
                                            {
                                                this.state.errorStatus && this.state.errorStatus != "" && 
                                                    <div className="onboarding__error-status">
                                                        <span>
                                                            {this.state.errorStatus}
                                                        </span>
                                                    </div>
                                            }
                                            {
                                                this.state.saveLoading ?
                                                    <LoadingPage colorTheme="light" mini/>
                                                    :
                                                    <div className="onboarding__submit">
                                                        <Button 
                                                            className="onboarding__save-button"
                                                            type="submit"
                                                        >
                                                            Save
                                                        </Button>
                                                        <Button 
                                                            className="onboarding__delete-button"
                                                            onClick={this.onDeleteConfig}
                                                            disabled={!this.props.config.saved}
                                                            onMouseDown={e => e.preventDefault()}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </div>
                                            }
                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </Col>
                <Tooltip 
                    placement="right" 
                    isOpen={this.state.emailNotificationTooltipOpen} 
                    target={`tooltip-id-${this.props.id}`}
                    toggle={this.emailNotificationTooltipToggle}
                >
                    You can set your own thresholds as “% change in sentiment volume” for your search term. talkAItive will track sentiment weighted by volume and will notify you if any of the set thresholds are breached.
                </Tooltip>
            </Row>
        )
    }
}
  
export default SearchtermSetup;