import React from "react";
import { useField } from "formik";

function InputThreshold({ name }) {
  	const [field] = useField({ name, type: "text" });
  
  	return (
  		<input 
  			id={name} 
  			className='onboarding__text-input-threshold'
  			name={name} 
  			type="text" 
  			onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
  			{...field} 
  		/>
  	);
}

export default React.memo(InputThreshold);
