import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';

import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";

import IntegrationsView from "../../views/Integrations/Integrations";
var ps;

class Integrations extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidUpdate(e) {
        if (e.history.action === "PUSH") {
            this.refs.mainPanel.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        }
    }
    getRoutes() {
        const Integrations = (props) => {
            return (
                <IntegrationsView
                    {...props}
                />
            );
        }

        let routeList = [];
        routeList.push(
            <Route
                key='integrations'
                path={`/integrations`}
                component={Integrations}
            />
        );
        return routeList;
    };
    render() {
        return (
            <div className="wrapper">
                <Sidebar
                    {...this.props}
                />
                <div data-color={this.props.colorTheme} className="main-panel" ref="mainPanel">
                    <Header
                        {...this.props}
                    />
                    <Switch>{this.getRoutes()}</Switch>
                    <Footer fluid />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        colorTheme: state['admin']['colorTheme']
    }
};

export default connect(mapStateToProps)(Integrations);