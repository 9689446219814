import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from "react-redux";

const DateFilter = ({ dateFilter }) => {
	return ( 
        <Fragment>
            {
                dateFilter && dateFilter['startDate'] && dateFilter['endDate'] &&
                    <div style={
                        { 
                            display: 'flex', justifyContent: 'start',
                            gap: '0.5rem', fontSize: '1rem'
                        }
                    }>
                        <span>{moment.unix(dateFilter['startDate']).format("MMM DD YYYY hh:mm:ss a")}</span>
                        <span> - </span>
                        <span>{moment.unix(dateFilter['endDate']).format("MMM DD YYYY hh:mm:ss a")}</span>
                    </div>
            }
        </Fragment>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        dateFilter: state['dataPointSearch']['dateFilter']
    };
};

export default connect(mapStateToProps)(DateFilter);