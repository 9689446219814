import axios from 'axios';

export const startSetIntegrations = (id) => {
    return (dispatch) => {
        return axios.get(`${process.env.SERVER_URL}/integrations/getIntegrations`, 
            {
                params: {
                    userId: id
                },
                headers: {
                    'Authorization': "Bearer " + JSON.parse(localStorage.getItem('user'))['token']
                }
            }
        ).then((response) =>  {
            if (response['status'] === 200) {
                return response['data']
            } else {
                return null
            }
        }).catch((error) => {
            console.log(error);
        });
    };
};

export const saveMailchimpIntegration = (userId, mailchimp) => {
    return (dispatch) => {
        return axios.post(`${process.env.SERVER_URL}/integrations/saveMailchimpIntegration`, 
            {
                userId: userId,
                mailchimp: mailchimp
            },
            {
                headers: {
                    'Authorization': "Bearer " + JSON.parse(localStorage.getItem('user'))['token']
                }
            }
        ).then((response) =>  {
            return response;
        }).catch((error) => {
            return error;
        });
    };
}

export const getMailchimpCampaigns = (apiKey, server) => {
    return (dispatch) => {
        return axios.get(`${process.env.SERVER_URL}/integrations/getMailchimpCampaigns`, 
            {
                params: {
                    apiKey: apiKey,
                    server: server
                },
                headers: {
                    'Authorization': "Bearer " + JSON.parse(localStorage.getItem('user'))['token']
                }
            }
        ).then((response) =>  {
            if (response['status'] == 200) {
                return response['data']['data']
            } else {
                return []
            }
        }).catch((error) => {
            return error;
        });
    };
}

export const getMailchimpHistory = (userId) => {
    return (dispatch) => {
        return axios.get(`${process.env.SERVER_URL}/integrations/getMailchimpHistory`, 
            {
                params: {
                    userId: userId
                },
                headers: {
                    'Authorization': "Bearer " + JSON.parse(localStorage.getItem('user'))['token']
                }
            }
        ).then((response) =>  {
            if (response['status'] == 200) {
                return response['data']
            } else {
                return []
            }
        }).catch((error) => {
            return error;
        });
    };
}

export const saveMailchimpCampaignTrigger = (userId, trigger) => {
    return (dispatch) => {
        return axios.post(`${process.env.SERVER_URL}/integrations/saveMailchimpCampaignTrigger`, 
            {
                userId: userId,
                trigger: trigger
            },
            {
                headers: {
                    'Authorization': "Bearer " + JSON.parse(localStorage.getItem('user'))['token']
                }
            }
        ).then((response) =>  {
            return response
        }).catch((error) => {
            return error;
        });
    };
}
