import React from "react";
import axios from "axios";
import { connect } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import {
    Button
} from 'reactstrap'

import LoadingPage from '../../components/Loading/LoadingPage';

import { login, sendVerificationEmail, validateCode } from '../../actions/auth';

export class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            email: '',
            password: '',
            status: ''
        }
    }
    componentDidMount() {
        if (this.props.verificationState && this.props.verificationState['verification'] && this.props.verificationState['code']) {
            this.props.validateCode(this.props.verificationState['code']).then((response) => {
                if (response == 200) {
                    this.setState({
                        status: 'Email has been verified. Please log in.'
                    });
                }
                
                this.toggleLoading(false);
            })
        } else {
            this.toggleLoading(false);
        }
    }
    login = (r, portal, email) => {
        const token = r.headers.get('x-auth-token');
        r.json().then(user => {
            if (token) {
                if (user == -1) {
                    this.setState({
                        status: 'Incorrect email or password'
                    });

                    this.toggleLoading(false);
                } else if (user == -2) {
                    this.setState({
                        status: 'Not verified'
                    });

                    this.props.sendVerificationEmail(email).then(() => {
                        this.toggleLoading(false);
                    });
                } else {
                    axios.get(`${process.env.SERVER_URL}/auth/getUser`, 
                        {
                            params: {
                                portal: portal,
                                id: user
                            },
                            headers: {
                                'Authorization': "Bearer " + token
                            }
                        }
                    ).then((response) =>  {
                        if (response['data']) {
                            const loggedInUser = {
                                isAuthenticated: true,
                                portal: portal,
                                id: user,
                                token: token,
                                email: response['data']['email'],
                                givenName: response['data']['given_name'],
                                familyName: response['data']['family_name'],
                                apiKey: response['data']['api_key']
                            }

                            localStorage.setItem('user', JSON.stringify(loggedInUser));
            
                            this.props.login(
                                portal, user, token, response['data']['email'], response['data']['given_name'], 
                                response['data']['family_name'], response['data']['api_key']
                            );

                            this.toggleLoading(false);
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                }
            }
        })
    }
    googleSuccess = (response) => {
        this.toggleLoading(true);

        const accessToken = response['tokenObj']['access_token'];

        const tokenBlob = new Blob([JSON.stringify({access_token: accessToken} ,
            null, 2)], {type: 'application/json'});

        let options = {
            method: 'POST',
            body: tokenBlob,
            mode: 'cors',   
            cache: 'default'
        };
        
        fetch(`${process.env.SERVER_URL}/auth/google/login`, options).then(r => {
            const accessToken = null;
            const portal = 'google';

            if (r['status'] == 401) {
                this.setState({
                    status: 'Account does not exist. Please sign up.'
                });

                this.toggleLoading(false);
            } 
            else if (r['status'] == 200) {
                this.login(r, portal, null);
            }
        });
    }
    googleFailed = (response) => {
    }
    onSubmit = ({ email, password }, { setSubmitting }) => {
        setSubmitting(false);
        this.toggleLoading(true);

        let options = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                username: email,
                password: password
            })
        };
        
        fetch(`${process.env.SERVER_URL}/auth/local/login`, options).then(r => {
            const accessToken = null;
            const portal = 'local';

            if (r['status'] == 401) {
                this.setState({
                    status: 'Incorrect email or password'
                });

                this.toggleLoading(false);
            } 
            else if (r['status'] == 200) {
                this.login(r, portal, email);
            }
        });
    }
    resendEmailVerification = (email) => {
        this.props.sendVerificationEmail(email);
    }
    toggleLoading = (state) => {
        this.setState({
            loading: state
        })
    }
    render() {
        const validationSchema = Yup.object().shape({
            email: Yup.string()
                .email('Email is invalid')
                .required('Email is required'),
            password: Yup.string().required('Password is required')
        });

        return (
            <div className="login-content">
                <div className="login-div">
                    <div className="login-div__header">
                        <img
                            src="https://ucarecdn.com/2b29fdae-d550-44c3-950e-82bbbcd04313/logoblackpng.webp"
                        />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                        <span style={{textAlign: 'center'}}>
                            Welcome to talkAItive’s new Emotional NPS Alerts page. 
                        </span>
                        <span style={{textAlign: 'center'}}>
                            You can sign up for a new account or login to see your personal alerts.
                        </span>
                        <span style={{textAlign: 'center'}}>
                            For more information on Emotional NPS Alerts please visit <a href="https://talkaitive.com/our-products/emotional-nps.html">talkaitive.com/our-products/emotional-nps.html</a>
                        </span>
                    </div>
                    {
                        this.state.loading === true ?
                            <LoadingPage colorTheme={this.props.colorTheme} mini={true}/>
                            :
                            <div> 
                                <div>
                                    <Formik initialValues={this.state} validationSchema={validationSchema} onSubmit={this.onSubmit}>
                                        {({ errors, touched, isSubmitting }) => (
                                            <Form>
                                                <div className="form-group">
                                                    <Field placeholder="Email" name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <Field placeholder="Password" name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    {
                                                        this.state.status == 'Not verified' ?
                                                            <span className="login-error">Email has not been verified. Click <a style={{cursor: 'pointer'}} onClick={() => this.resendEmailVerification(this.props.email)}><b style={{textDecoration: 'underline'}}>here</b></a> to resend verification email.</span> 
                                                            :
                                                            <span className="login-error">{this.state.status}</span>  
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <Button type="submit" disabled={isSubmitting} className="login-button">
                                                        Log In
                                                    </Button>
                                                </div>
                                                <div className="form-group">
                                                    <GoogleLogin
                                                        clientId={process.env.GOOGLE_CLIENT_ID}
                                                        render={renderProps => (
                                                            <Button
                                                                className='dark-auth-button'
                                                                disabled={renderProps.disabled}
                                                                onClick={renderProps.onClick}
                                                            >
                                                                <img
                                                                    src='/images/signup/google-sign-in.png'
                                                                />
                                                            </Button>
                                                        )}
                                                        onSuccess={(e) => this.googleSuccess(e)}
                                                        onFailure={(e) => this.googleFailed(e)}
                                                        cookiePolicy={'single_host_origin'}
                                                    />
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                                    <span>
                                        Don't have a talkAItive account? Create one <a href="/signup">here</a>
                                    </span> 
                                </div>
                            </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        colorTheme: state['admin']['colorTheme'],
        auth: state['auth'],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (portal, user, token, email, givenName, familyName, apiKey) => 
            dispatch(login(portal, user, token, email, givenName, familyName, apiKey)),
        sendVerificationEmail: (email) => dispatch(sendVerificationEmail(email)),
        validateCode: (code) => dispatch(validateCode(code))
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Login);