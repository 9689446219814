import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from "react-select";

const ThemeFilter = ({ history, alertsList, themeFilter }) => {
    const [dropdown, setDropdown] = useState([])

    const onSelect = (e) => {
        if (e.value != themeFilter) {
            const asset = Object.keys(alertsList[e.value]).sort()[0];
            const widget = alertsList[e.value][asset][0]['shareCode'];

            history.push({
                pathname: '/alerts',
                search: `?shareCode=${widget}`
            })
        }
    }

    useEffect(() => {
        if (themeFilter) {
            setDropdown(Object.keys(alertsList).sort().map(theme => ({
                value: theme,
                label: theme
            })))
        }
    }, [])

    return (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
            <div className="vertical-center">
                <span style={{ fontWeight: 'bold' }}>
                    Theme:
                </span>
            </div>
            <Select
                className="tait-alert__select-full"
                classNamePrefix="select"
                placeholder="Select Theme"
                value={
                    dropdown.find(theme => 
                        theme['value'] == themeFilter)
                }
                onChange={onSelect}
                options={dropdown}
            />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        alertsList: state['alerts']['alertsList'],
        themeFilter: state['alertsFilters']['theme'],
    };
};

export default connect(mapStateToProps)(ThemeFilter);