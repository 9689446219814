import axios from 'axios';

export const setSentimentFilter = (sentimentFilter) => ({
    type: 'SET_SENTIMENT_FILTER',
    sentimentFilter
})

export const setSortFilter = (sortFilter) => ({
    type: 'SET_SORT_FILTER',
    sortFilter
})

export const setSearchFilter = (searchFilter) => ({
    type: 'SET_SEARCH_FILTER',
    searchFilter
})

export const setDateFilter = (startDate, endDate) => ({
    type: 'SET_DATE_FILTER',
    dateFilter: {
        startDate,
        endDate
    }
})

export const setSourceFilter = (sourceFilter) => ({
    type: 'SET_SOURCE_FILTER',
    sourceFilter
})

export const setUserFilter = (userFilter) => ({
    type: 'SET_USER_FILTER',
    userFilter
})

export const setDataPoints = (dataPoints) => ({
    type: 'SET_DATA_POINTS',
    dataPoints
})

export const resetFilters = () => ({
    type: 'RESET_FILTERS'
})

export const getDataPointsBySearch = (shareCode, searchFilter) => {
    return (dispatch) => {
        return axios.get(`${process.env.SERVER_URL}/data-point/getDataPointsBySearch`, 
            {
                params: {
                    shareCode,
                    searchTerm: searchFilter
                },
                headers: {
                    'Authorization': "Bearer " + JSON.parse(localStorage.getItem('user'))['token']
                }
            }
        ).then(response =>  {
            dispatch(setDataPoints(response['data']))
        }).catch((error) => {
        });
    };
};

export const getDataPointsByUser = (shareCode, userFilter) => {
    return (dispatch) => {
        return axios.get(`${process.env.SERVER_URL}/data-point/getDataPointsByUser`, 
            {
                params: {
                    shareCode,
                    userName: userFilter['userName'],
                    source: userFilter['source']
                },
                headers: {
                    'Authorization': "Bearer " + JSON.parse(localStorage.getItem('user'))['token']
                }
            }
        ).then(response =>  {
            dispatch(setDataPoints(response['data']))
        }).catch((error) => {
        });
    };
};