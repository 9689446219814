import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';

import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";

import AlertsView from "../../views/Alerts/Alerts";
var ps;

class Alerts extends React.Component {
    constructor(props) {
        super(props);
    }
    // componentDidMount() {
    //     if (navigator.platform.indexOf("Win") > -1) {
    //         ps = new PerfectScrollbar(this.refs.mainPanel);
    //         document.body.classList.toggle("perfect-scrollbar-on");
    //     }
    // }
    // componentWillUnmount() {
    //     if (navigator.platform.indexOf("Win") > -1) {
    //         ps.destroy();
    //         document.body.classList.toggle("perfect-scrollbar-on");
    //     }
    // }
    componentDidUpdate(e) {
        if (e.history.action === "PUSH") {
            this.refs.mainPanel.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        }
    }
    getRoutes() {
        let routeList = [];
        routeList.push(
            <Route
                key='/alerts'
                path={`/alerts`}
                component={AlertsView}
            />
        );
        return routeList;
    };
    render() {
        return (
            <div className="wrapper">
                <Sidebar
                    {...this.props}
                />
                <div data-color={this.props.colorTheme} className="main-panel" ref="mainPanel">
                    <Header
                        {...this.props}
                    />
                    <Switch>{this.getRoutes()}</Switch>
                    <Footer fluid />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        colorTheme: state['admin']['colorTheme']
    }
};

export default connect(mapStateToProps)(Alerts);