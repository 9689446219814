import React from 'react';
import {
    Row,
    Col,
    Button
} from "reactstrap";

import LoadingPage from '../Loading/LoadingPage';
import CardHeader from '../Alerts/Widget/Header/CardHeader';

export class Twitter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }
    login = () => {
        console.log('login')
        window.open("http://localhost:5008/auth/twitter", "_self");
    }
    render() {
        return (
            <Row>
                <Col xs={12}>
                    <div className='content-row'>
                        <div className='tait-alert__card'>
                            <CardHeader
                                title='Twitter'
                                img='https://ucarecdn.com/31d199e4-f723-4188-9a40-71bbaa405c6f/'
                            />
                            <div className='tait-alert__card-body'>
                                <Button 
                                    onClick={this.login}
                                >
                                    Login
                                </Button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
}
  
export default Twitter;