import React, { useState, useEffect, memo } from 'react';
import moment from 'moment';
import LineChart from '../Graphs/LineChart';

const defaultPointRadius = 3;
const selectedPointRadius = 10;

const SentimentTimelineGraph = ({ timeline, nodeClick }) => {
    let [chartOptions, setChartOptions] = useState({})
    let [chartLabels, setChartLabels] = useState([])
    let [chartDatasets, setChartDatasets] = useState([])

    useEffect(() => {
        const chartOrder = {
            0: 'pos',
            1: 'neg',
            2: 'neut'
        }

        const labels = []
        const posDataset = []
        const negDataset = []
        const neutDataset = []

        let minDate = Number.POSITIVE_INFINITY;
        let maxDate = 0;

        const dateLabelsUnix = []
        const dateEndLabelsUnix = []

        timeline.forEach((x) => {
            if (x['date'] > maxDate) maxDate = x['date'];
            if (x['date'] < minDate) minDate = x['date'];

            dateLabelsUnix.push(x['date'])
            labels.push(moment.unix(x['date']).format("MMM DD YYYY hh:mm:ss a"))
            posDataset.push(x['count']['pos'])
            negDataset.push(x['count']['neg'])
            neutDataset.push(x['count']['neut'])
        })

        let dateIncrement = 0
        for (var i=0;i < dateLabelsUnix.length;i++) {
            if (i != dateLabelsUnix.length - 1) 
                dateIncrement = dateLabelsUnix[i+1] - dateLabelsUnix[i]

            dateEndLabelsUnix.push(dateLabelsUnix[i+1])
        }
        dateEndLabelsUnix[dateEndLabelsUnix.length - 1] = dateEndLabelsUnix[dateEndLabelsUnix.length - 2] + dateIncrement

        let dateFormat = '';

        let dateDiffSeconds = (maxDate - minDate)
        let dateDiffHours = dateDiffSeconds / 60 / 60
        let dateDiffDays = dateDiffSeconds / 60 / 60 / 24
        let dateDiffMonths = dateDiffSeconds / 60 / 60 / 24 / 30

        if (dateDiffMonths >= 12) dateFormat = 'MMM YYYY';
        else if (dateDiffDays >= 1) dateFormat = 'MMM DD';
        else dateFormat = 'HH:mm:ss';

        setChartDatasets([
            {
                label: 'Positive',
                data: posDataset,
                fill: false,
                id: 'Overall',
                yAxisID: 'ySentiment',
                borderColor: '#3ABAE2',
                backgroundColor: '#3ABAE2',
                pointHoverRadius: Array(posDataset.length).fill(selectedPointRadius),
                pointRadius: Array(posDataset.length).fill(defaultPointRadius),
                tension: 0.2
            },
            {
                label: 'Negative',
                data: negDataset,
                fill: false,
                id: 'Overall',
                yAxisID: 'ySentiment',
                borderColor: '#E94F76',
                backgroundColor: '#E94F76',
                pointHoverRadius: Array(negDataset.length).fill(selectedPointRadius),
                pointRadius: Array(negDataset.length).fill(defaultPointRadius),
                tension: 0.2
            },
            {
                label: 'Neutral',
                data: neutDataset,
                fill: false,
                id: 'Overall',
                yAxisID: 'ySentiment',
                borderColor: '#7C65D5',
                backgroundColor: '#7C65D5',
                pointHoverRadius: Array(neutDataset.length).fill(selectedPointRadius),
                pointRadius: Array(neutDataset.length).fill(defaultPointRadius),
                tension: 0.2
            },
        ])

        setChartLabels(labels)
        setChartOptions({
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            title: {
                display: false,
            },
            hover: {
                mode: 'point'
            },
            scales: {
                yAxes: [
                    {
                        id: 'ySentiment',
                        position: 'left',
                        ticks: {
                            fontColor: '#150637',
                            fontSize: 16,
                            beginAtZero: true
                        },
                        gridLines: {
                            color: '#e7e4e4'
                        },
                        scaleLabel: {
                            display: true,
                            fontColor: '#150637',
                            fontSize: 16,
                            labelString: '# of Data Points'
                        }
                    }
                ],
                xAxes: [
                    {
                        display: true, 
                        type: 'time',
                        distribution: 'series',
                        time: {
                            displayFormats: {
                                'millisecond': dateFormat,
                                'second': dateFormat,
                                'minute': dateFormat,
                                'hour': dateFormat,
                                'day': dateFormat,
                                'week': dateFormat,
                                'month': dateFormat,
                                'quarter': dateFormat,
                                'year': dateFormat,

                            },
                        },
                        ticks: {
                            fontSize: 16,
                            fontColor: '#150637'
                        },
                        grid: {
                            color: 'rgb(202, 195, 195)'
                        }
                    }
                ]
            },
            interaction: {
                mode: 'nearest'
            },
            onHover: function(evt, chartElement) {
                evt.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
            },
            onClick: (evt, item) => {
                if (item.length) {
                    const datasetIndex = item[0]._datasetIndex;

                    const data = item[0]._chart.config.data.datasets[datasetIndex].data[item[0]._index];

                    const sourceId = item[0]._chart.config.data.datasets[datasetIndex]['id'];

                    const sentKws = chartOrder[datasetIndex];

                    if (item[0]._chart.config.data.datasets[datasetIndex].pointRadius[item[0]._index] == selectedPointRadius) {
                        nodeClick(null, null, null, null);

                        item[0]._chart.config.data.datasets.forEach((d) => {
                            d.pointRadius = Array(posDataset.length).fill(defaultPointRadius)
                        })

                        item[0]._chart.update();
                    } else {
                        nodeClick(
                            chartOrder[datasetIndex], 
                            dateLabelsUnix[item[0]._index], 
                            dateEndLabelsUnix[item[0]._index],
                            item[0]._index
                        );

                        item[0]._chart.config.data.datasets.forEach((d) => {
                            d.pointRadius = Array(posDataset.length).fill(defaultPointRadius)
                        })

                        item[0]._chart.config.data.datasets[datasetIndex].pointRadius[item[0]._index] = selectedPointRadius
                    
                        item[0]._chart.update();
                    }  
                }
            },
            tooltips: {
                callbacks: {
                    title: function(tooltipItem, data) {
                        const title = [
                            `Start Date: ${data['labels'][tooltipItem[0]['index']]}`, 
                            `End Date: ${moment.unix(dateEndLabelsUnix[tooltipItem[0]['index']]).format("MMM DD YYYY hh:mm:ss a")}`
                        ]
                        return title;
                    },
                },
            }
        })

    }, [timeline])

    return (
        <div className='tait-alert__st-graph-div'>
            <div className={'tait-alert__st'}>
                <LineChart
                    key={Math.random()}
                    datasets={chartDatasets}
                    labels={chartLabels}
                    options={chartOptions}
                />
            </div>
            <div className={'tait-alert__st-footer'}>
                <div className="tait-alert__st-footer-div">
                    <div className="tait-alert__st-footer-bar tait-alert__st-footer-bar-pos"></div>
                    <div className="sentiment-timeline-footer-text">Positive</div>
                </div>
                <div className="tait-alert__st-footer-div">
                    <div className="tait-alert__st-footer-bar tait-alert__st-footer-bar-neut"></div>
                    <div className="sentiment-timeline-footer-text">Neutral</div>
                </div>
                <div className="tait-alert__st-footer-div">
                    <div className="tait-alert__st-footer-bar tait-alert__st-footer-bar-neg"></div>
                    <div className="sentiment-timeline-footer-text">Negative</div>
                </div>
            </div>
        </div>
    );
}

const areEqual = (prevProps, nextProps) => {
    if (prevProps.timeline === nextProps.timeline) {
        return true                                    // donot re-render
    }
    return false                                     // will re-render
}

export default memo(SentimentTimelineGraph, areEqual);