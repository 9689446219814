import React from 'react';
import moment from 'moment';

const positiveThreshold = 0.30;
const negativeThreshold = -0.30;

const MediaDataPoint = ({ onClick, data }) => {
    let sentiment;
    let sourceUrl;
    let profileImageUrl;
    let name;
    let screenName;

    if (data['sentiment'] >= positiveThreshold) sentiment = 'positive';
    else if (data['sentiment'] <= negativeThreshold) sentiment = 'negative';
    else sentiment = 'neutral';

    if (data['source'] == 'Twitter') sourceUrl = 'https://ucarecdn.com/8aedb345-4dd2-4a2e-8a46-084760200904/';
    else if (data['source'] == 'Reddit') sourceUrl = 'https://ucarecdn.com/2a036a68-274f-4805-90b9-ef8efb23b6fd/';
    else if (data['source'] == 'Facebook') sourceUrl = 'https://ucarecdn.com/6d44a863-0e9f-4349-88aa-91d149d5bc4d/';
    else if (data['source'] == 'Instagram') sourceUrl = 'https://ucarecdn.com/f67a7d0c-21db-4faa-9ef1-86e4e0f111ee/';
    else if (data['source'] == 'YouTube') sourceUrl = 'https://ucarecdn.com/9d0e7160-2e6a-4d3f-8111-b2257e706e47/youtube.png';
    else if (data['source'] == 'TikTok') sourceUrl = 'https://ucarecdn.com/2572cc0f-9d55-40ef-92c7-dc7fd0df7713/tiktok.png';
    else if (data['source'] == 'Truth Social') sourceUrl = 'https://ucarecdn.com/29610c89-c385-456c-9e84-522d69fcdc5b/';
    else sourceUrl = 'https://ucarecdn.com/1b7a2fed-0ed2-439e-bc56-c704dfb1034a/';

    if (data['metadata'] && data['metadata']['user']) {
        profileImageUrl = data['metadata']['user']['profile_image_url']
        name = data['metadata']['user']['name']
        screenName = '@' + data['metadata']['user']['screen_name']
    } else {
        profileImageUrl = 'https://ucarecdn.com/cea023f8-a13d-446f-9c4c-fc68a379372f/'

        if (data['source'] == 'Reddit') {
            name = data['user_name'].split('_').slice(0, -1).join('_')
        } else {
            name = data['user_name']
        }
        
        screenName = null
    }


    return (
        <div
            className={`media-data-point ${sentiment}`}
        >
            <img 
                src={data['metadata']['media']['url']} 
                onClick={onClick}
            />
            <div className="media-data-point__user-div">
                <div className="media-data-point__user-header">
                    <div style={{display: 'flex', gap: '0.3rem'}}>
                        <div className="media-data-point__user-profile-div">   
                            <img
                                className="data-point__user-profile-image"
                                src={profileImageUrl}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src="https://ucarecdn.com/cea023f8-a13d-446f-9c4c-fc68a379372f/";
                                }}
                            />
                        </div>
                        <div className="media-data-point__user">
                            <div className="media-data-point__user-group">
                                <span className="media-data-point__user-name">
                                    {name}
                                </span>
                                <span className="media-data-point__user-screen-name">
                                    {screenName}
                                </span>
                            </div>
                            <span>
                                {moment.unix(data['epoch_time']).format("MMM DD, YYYY hh:mm:ss a")}
                            </span>
                        </div>
                    </div>
                    <div>
                       {
                            sourceUrl && 
                                <img 
                                    className="data-point__header-source-img"
                                    src={sourceUrl}
                                />
                        }
                    </div>
                </div>
                <hr/>
                <div className="media-data-point__user-body">
                    <span>
                        {data['text']}
                    </span>
                </div>
                <hr/>
                <div>
                    {
                        data['engagement'] &&
                            <div className="media-data-point__user-footer-engagement">
                                {
                                    Object.keys(data['engagement']).map((eng) => (
                                        <div key={eng}>
                                            {data['engagement'][eng]} {eng}
                                        </div>
                                    ))
                                }
                            </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default MediaDataPoint;