import React from 'react';
import PieChart from '../Graphs/PieChart';
import {
    Row,
    Col
} from 'reactstrap'

import CardHeader from '../Header/CardHeader';

export class Sentiment extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const sentimentLabels = [];
        const sentimentColors = [];
        const sentimentData = [];
        let maxSentimentValue = 0;
        let maxSentimentLabel = 'Positive';
        let maxSentimentColor = '#3ABAE2';
        let sentimentList = [];

        Object.entries(this.props.analysis).forEach(([key, value]) => {
            let sentimentLabel = null;
            let sentimentColor = null;

            if (key == 'POS') {
                sentimentColor = '#3ABAE2';
                sentimentLabel = 'Positive';
            } else if (key == 'NEG') {
                sentimentColor = '#E94F76';
                sentimentLabel = 'Negative';
            } else if (key == 'NEUT') {
                sentimentColor = '#7C65D5';
                sentimentLabel = 'Neutral';
            }

            sentimentList.push({
                label: sentimentLabel,
                value: Math.round(value * 100),
                color: sentimentColor
            });
        })

        sentimentList = sentimentList.sort((a, b) => b['value'] - a['value']);

        sentimentList.forEach((sent) => {
            sentimentColors.push(sent['color']);
            sentimentLabels.push(sent['label']);
            sentimentData.push(sent['value']);

            if (sent['value'] > maxSentimentValue) {
                maxSentimentValue = sent['value'];
                maxSentimentLabel = sent['label'];
                maxSentimentColor = sent['color'];
            }
        })

        let sentimentChart = {
            data: {
                labels: sentimentLabels,
                datasets: [{
                    data: sentimentData,
                    backgroundColor: sentimentColors,
                    hoverBackgroundColor: sentimentColors,
                    borderColor: 'white',
                    borderWidth: 2
                }],
                text1: `${maxSentimentValue}%`,
                text1Color: maxSentimentColor,
                text2: maxSentimentLabel 
            },
            options: {
                events: [],
                animation: {
                    duration: 0
                },
                responsive: true,
                cutoutPercentage: 90,
                legend: {
                    display: false
                }
            }
        };

        return (
            <div className={'tait-alert__card'}>
                <CardHeader
                    title='Sentiment'
                    tooltipId={`tait-alert__sentiment-tooltip-${this.props.device}`}
                    tooltipText='Sentiment Overview'
                />
                <div className={'tait-alert__card-body'}>
                    <Row>
                        <Col xs={12}>
                            <Row style={{justifyContent: 'center'}}>
                                <div className={'tait-alert__volume-div'}>
                                    <PieChart
                                        key={Math.random()}
                                        data={sentimentChart.data}
                                        options={sentimentChart.options}
                                    />
                                    <div className={'tait-alert__volume-legend'}>
                                        {
                                            sentimentList.map((sent, index) => {
                                                if (index != 0) {
                                                    return (
                                                        <div key={sent['label']}>
                                                            <span className='perc' style={{color: sentimentColors[index]}}>
                                                                {sent['value']}%
                                                            </span>
                                                            <span className='description'>
                                                                {sent['label']}
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    };
}

export default Sentiment;