import React from "react";
import { useField } from "formik";

import { default as ReactSelect, components } from "react-select";
import { ActionMeta, OnChangeValue } from 'react-select';

function MultiSelect({ name }) {
	const [field, meta, { setValue }] = useField({ name });

	const Option = (props) => {
		return (
			<div>
				<components.Option {...props}>
					<input
					  type="checkbox"
					  checked={props.isSelected}
					  onChange={() => null}
					/>{" "}
					<label>{props.label}</label>
				</components.Option>
			</div>
		);
	};

	const options = [
		{ value: "Twitter", label: "Twitter" },
		{ value: "Reddit", label: "Reddit" }
	];

  	const handleChange = (
	    newValue: OnChangeValue<Option, false>,
	    actionMeta: ActionMeta<Option>
	) => {
		if (newValue.length > 0) setValue(newValue.map((item) => item.value))
		else setValue([])
	};

  	return (
  		<ReactSelect
  			className="onboarding__multiselect"
			options={options}
			placeholder={'Select sources...'}
			isMulti
			closeMenuOnSelect={false}
			hideSelectedOptions={false}
			components={{
				Option
			}}
			onChange={handleChange}
			allowSelectAll={true}
			value={field['value'].length > 0 ? field['value'].map((source) => ({value: source, label: source})) : null}
		/>
  	);
}

export default React.memo(MultiSelect);
