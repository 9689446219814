import React from 'react';
import moment from 'moment';
import Numeral from 'react-numeral';
import Select from "react-select";
import {
    Row,
    Col,
    Button,
    Tooltip,
    Collapse
} from 'reactstrap'

import CardHeader from '../Header/CardHeader';
import UserCard from './UserCard/UserCard';
import DataPointModal from '../DataPoint/DataPointModal';

export class SocialEngagement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contributorsCollapse: false,
            promotersCollapse: false,
            detractorsCollapse: false,
            contributorsTooltipOpen: {
                totalFollowers: false,
                accountAge: false,
                avgCount: false
            },
            detractorsTooltipOpen: {
                totalFollowers: false,
                accountAge: false,
                avgCount: false
            },
            promotersTooltipOpen: {
                totalFollowers: false,
                accountAge: false,
                avgCount: false
            },
            dataPointModal: {
                open: false,
                header: null,
                dataPointCount: 0,
                dataPointIds: [],
                sentFilter: null,
                userMetadata: null,
                source: null
            }
        }
    }
    openUserLink = (user_link) => {
        window.open(user_link);
    }
    contributorsCollapseToggle = () => {
        this.setState({
            contributorsCollapse: !this.state.contributorsCollapse
        })
    }
    promotersCollapseToggle = () => {
        this.setState({
            promotersCollapse: !this.state.promotersCollapse
        })
    }
    detractorsCollapseToggle = () => {
        this.setState({
            detractorsCollapse: !this.state.detractorsCollapse
        })
    }
    contributorsTooltipToggle = (type) => {
        let temp = this.state.contributorsTooltipOpen;
        temp[type] = !this.state.contributorsTooltipOpen[type];

        this.setState({
            contributorsTFTooltipOpen: temp
        });
    }
    promotersTooltipToggle = (type) => {
        let temp = this.state.promotersTooltipOpen;
        temp[type] = !this.state.promotersTooltipOpen[type];

        this.setState({
            promotersTFTooltipOpen: temp
        });
    }
    detractorsTooltipToggle = (type) => {
        let temp = this.state.detractorsTooltipOpen;
        temp[type] = !this.state.detractorsTooltipOpen[type];

        this.setState({
            detractorsTFTooltipOpen: temp
        });
    }
    dataPointModalToggle = () => {
        let dataPointModal = this.state.dataPointModal
        dataPointModal['open'] = !dataPointModal['open']

        this.setState({
            dataPointModal
        })
    }
    onUserCardClick = (user) => {
        if (user) {
            let dataPointModal = this.state.dataPointModal
            dataPointModal['open'] = true
            dataPointModal['header'] = user['userName']
            dataPointModal['dataPointCount'] = user['dataPointIds'].length
            dataPointModal['dataPointIds'] = user['dataPointIds']
            dataPointModal['sentFilter'] = null
            dataPointModal['userMetadata'] = user['metadata']
            dataPointModal['source'] = user['source']

            this.setState({
                dataPointModal
            })
        } else {
            let dataPointModal = this.state.dataPointModal
            dataPointModal['open'] = !dataPointModal['open']

            this.setState({
                dataPointModal
            })
        }
    }
    render() {
        return (
            <div className={'tait-alert__card'} id="social-engagement">
                <CardHeader
                    title='Social Community'
                    tooltipId='tait-alert__se-tooltip'
                    tooltipText='Social Community card showcases the engaged users relative to your search term and time of analysis, broken down by promoters and detractors. Select different users to discover who makes up your emotionally engaged community.'
                />
                <div className={'tait-alert__card-body'}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div className='social-engagement__section'>
                                <div className="social-engagement__header">
                                    <div className="social-engagement__header-title" onClick={this.contributorsCollapseToggle}>
                                        <span>Top Contributors</span>
                                        <div 
                                            className="vertical-center"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {
                                                this.state.contributorsCollapse ?
                                                    <img src='https://ucarecdn.com/33a51f69-3088-41fd-93dd-8938e1ed332b/'/>
                                                    :
                                                    <img src='https://ucarecdn.com/9e36c3c7-215d-4e27-b7aa-6a3a4287d1df/'/>
                                            }
                                        </div>
                                    </div>
                                    <Collapse isOpen={this.state.contributorsCollapse}>
                                        <div className="social-engagement__header-subtitle">
                                            {
                                                this.props.analysis['contributors']['metadata']['totalFollowers'] > 0 &&
                                                    <div className="social-engagement__header-subtitle-group">
                                                        Total Followers 
                                                        <div  
                                                            id="contributors-total-followers-tooltip"
                                                            className='social-engagement__tooltip-image'
                                                        >
                                                            <img
                                                                src='https://ucarecdn.com/6900d1db-f5b1-4be3-a504-4bbd3161439a/information.png'
                                                            />
                                                        </div>: <Numeral value={this.props.analysis['contributors']['metadata']['totalFollowers']} format={'0,0'}/>
                                                    </div>
                                            }
                                           {/* {
                                                this.props.analysis['contributors']['metadata']['avgAccountAge'] &&
                                                    <div className="social-engagement__header-subtitle-group">
                                                        Avg Account Age
                                                        <div  
                                                            id="contributors-account-age-tooltip"
                                                            className='social-engagement__tooltip-image'
                                                        >
                                                            <img
                                                                src='https://ucarecdn.com/6900d1db-f5b1-4be3-a504-4bbd3161439a/information.png'
                                                            />
                                                        </div>: {Math.round(this.props.analysis['contributors']['metadata']['avgAccountAge'])} months
                                                    </div>
                                            }*/}
                                            {
                                                this.props.analysis['contributors']['metadata']['avgCount'] > 0 &&
                                                    <div className="social-engagement__header-subtitle-group">
                                                        Avg Count
                                                        <div  
                                                            id="contributors-avg-count-tooltip"
                                                            className='social-engagement__tooltip-image'
                                                        >
                                                            <img
                                                                src='https://ucarecdn.com/6900d1db-f5b1-4be3-a504-4bbd3161439a/information.png'
                                                            />
                                                        </div>: {this.props.analysis['contributors']['metadata']['avgCount'].toFixed(2) }
                                                    </div>
                                            }
                                        </div>
                                    </Collapse>
                                </div>
                                <hr/>
                                <div className='tait-alert__sc-user-div'>
                                    {
                                        this.props.analysis['contributors']['userList'].slice(0, 100).map((u, idx) => {
                                            let userName;
                                            let metadata;

                                            if (u['metadata'] && u['metadata']['user']) {
                                                userName = u['metadata']['user']['screen_name']
                                                metadata = u['metadata']['user']
                                            } else {
                                                userName = u['userName']
                                                metadata = null
                                            }

                                            return (
                                                <UserCard 
                                                    key={`contributor-${idx}-${userName}`}
                                                    metadata={metadata}
                                                    userLink={u['userSource']}
                                                    averageSentiment={u['averageSentiment']}
                                                    modalToggle={() => this.onUserCardClick(u)}
                                                    userName={userName}
                                                    source={u['source']}
                                                    vws={u['vws']}  
                                                    count={u['dataPointIds'].length}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div className='social-engagement__section'>
                                <div className="social-engagement__header">
                                    <div className="social-engagement__header-title" onClick={this.promotersCollapseToggle}>
                                        <span>Top Promoters</span>
                                        <div
                                            className="vertical-center"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {
                                                this.state.promotersCollapse ?
                                                    <img src='https://ucarecdn.com/33a51f69-3088-41fd-93dd-8938e1ed332b/'/>
                                                    :
                                                    <img src='https://ucarecdn.com/9e36c3c7-215d-4e27-b7aa-6a3a4287d1df/'/>
                                            }
                                        </div>
                                    </div>
                                    <Collapse isOpen={this.state.promotersCollapse}>
                                        <div className="social-engagement__header-subtitle">
                                            {
                                                this.props.analysis['promoters']['metadata']['totalFollowers'] > 0 &&
                                                    <div className="social-engagement__header-subtitle-group">
                                                        Total Followers 
                                                        <div  
                                                            id="promoters-total-followers-tooltip"
                                                            className='social-engagement__tooltip-image'
                                                        >
                                                            <img
                                                                src='https://ucarecdn.com/6900d1db-f5b1-4be3-a504-4bbd3161439a/information.png'
                                                            />
                                                        </div>: <Numeral value={this.props.analysis['promoters']['metadata']['totalFollowers']} format={'0,0'}/>
                                                    </div>
                                            }
                                            {/*{
                                                this.props.analysis['promoters']['metadata']['avgAccountAge'] &&
                                                    <div className="social-engagement__header-subtitle-group">
                                                        Avg Account Age
                                                        <div  
                                                            id="promoters-account-age-tooltip"
                                                            className='social-engagement__tooltip-image'
                                                        >
                                                            <img
                                                                src='https://ucarecdn.com/6900d1db-f5b1-4be3-a504-4bbd3161439a/information.png'
                                                            />
                                                        </div>: {Math.round(this.props.analysis['promoters']['metadata']['avgAccountAge'])} months
                                                    </div>
                                            }*/}
                                            {
                                                this.props.analysis['promoters']['metadata']['avgCount'] > 0 &&
                                                    <div className="social-engagement__header-subtitle-group">
                                                        Avg Count
                                                        <div  
                                                            id="promoters-avg-count-tooltip"
                                                            className='social-engagement__tooltip-image'
                                                        >
                                                            <img
                                                                src='https://ucarecdn.com/6900d1db-f5b1-4be3-a504-4bbd3161439a/information.png'
                                                            />
                                                        </div>: {this.props.analysis['promoters']['metadata']['avgCount'].toFixed(2) }
                                                    </div>
                                            }
                                        </div>
                                    </Collapse>
                                </div>
                                <hr/>
                                <div className='tait-alert__sc-user-div'>
                                    {
                                        this.props.analysis['promoters']['userList'].slice(0, 100).map((u, idx) => {
                                            let userName;
                                            let metadata;

                                            if (u['metadata'] && u['metadata']['user']) {
                                                userName = u['metadata']['user']['screen_name']
                                                metadata = u['metadata']['user']
                                            } else {
                                                userName = u['userName']
                                                metadata = null
                                            }

                                            return (
                                                <UserCard 
                                                    key={`promoter-${idx}-${userName}`}
                                                    metadata={metadata}
                                                    userLink={u['userSource']}
                                                    averageSentiment={u['averageSentiment']}
                                                    modalToggle={() => this.onUserCardClick(u)}
                                                    userName={userName}
                                                    source={u['source']}
                                                    vws={u['vws']}
                                                    count={u['dataPointIds'].length}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div className='social-engagement__section'>
                                <div className="social-engagement__header">
                                    <div className="social-engagement__header-title" onClick={this.detractorsCollapseToggle}>
                                        <span>Top Detractors</span>
                                        <div
                                            className="vertical-center"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {
                                                this.state.detractorsCollapse ?
                                                    <img src='https://ucarecdn.com/33a51f69-3088-41fd-93dd-8938e1ed332b/'/>
                                                    :
                                                    <img src='https://ucarecdn.com/9e36c3c7-215d-4e27-b7aa-6a3a4287d1df/'/>
                                            }
                                        </div>
                                    </div>
                                    <Collapse isOpen={this.state.detractorsCollapse}>
                                        <div className="social-engagement__header-subtitle">
                                            {
                                                this.props.analysis['detractors']['metadata']['totalFollowers'] > 0 &&
                                                    <div className="social-engagement__header-subtitle-group">
                                                        Total Followers 
                                                        <div  
                                                            id="detractors-total-followers-tooltip"
                                                            className='social-engagement__tooltip-image'
                                                        >
                                                            <img
                                                                src='https://ucarecdn.com/6900d1db-f5b1-4be3-a504-4bbd3161439a/information.png'
                                                            />
                                                        </div>: <Numeral value={this.props.analysis['detractors']['metadata']['totalFollowers']} format={'0,0'}/>
                                                    </div>
                                            }
                                           {/* {
                                                this.props.analysis['detractors']['metadata']['avgAccountAge'] &&
                                                    <div className="social-engagement__header-subtitle-group">
                                                        Avg Account Age
                                                        <div  
                                                            id="detractors-account-age-tooltip"
                                                            className='social-engagement__tooltip-image'
                                                        >
                                                            <img
                                                                src='https://ucarecdn.com/6900d1db-f5b1-4be3-a504-4bbd3161439a/information.png'
                                                            />
                                                        </div>: {Math.round(this.props.analysis['detractors']['metadata']['avgAccountAge'])} months
                                                    </div>
                                            }*/}
                                            {
                                                this.props.analysis['detractors']['metadata']['avgCount'] > 0 &&
                                                    <div className="social-engagement__header-subtitle-group">
                                                        Avg Count
                                                        <div  
                                                            id="detractors-avg-count-tooltip"
                                                            className='social-engagement__tooltip-image'
                                                        >
                                                            <img
                                                                src='https://ucarecdn.com/6900d1db-f5b1-4be3-a504-4bbd3161439a/information.png'
                                                            />
                                                        </div>: {this.props.analysis['detractors']['metadata']['avgCount'].toFixed(2) }
                                                    </div>
                                            }
                                        </div>
                                    </Collapse>
                                </div>
                                <hr/>
                                <div className='tait-alert__sc-user-div'>
                                    {
                                        this.props.analysis['detractors']['userList'].slice(0, 100).map((u, idx) => {
                                            let userName;
                                            let metadata;

                                            if (u['metadata'] && u['metadata']['user']) {
                                                userName = u['metadata']['user']['screen_name']
                                                metadata = u['metadata']['user']
                                            } else {
                                                userName = u['userName']
                                                metadata = null
                                            }

                                            return (
                                                <UserCard 
                                                    key={`detractor-${idx}-${userName}`}
                                                    metadata={metadata}
                                                    userLink={u['userSource']}
                                                    averageSentiment={u['averageSentiment']}
                                                    modalToggle={() => this.onUserCardClick(u)}
                                                    userName={userName}
                                                    source={u['source']}
                                                    vws={u['vws']}
                                                    count={u['dataPointIds'].length}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <DataPointModal
                    isOpen={this.state.dataPointModal['open']}
                    toggle={() => this.dataPointModalToggle()}
                    header={this.state.dataPointModal['header']}
                    dataPointCount={this.state.dataPointModal['dataPointCount']}
                    dataPointIds={this.state.dataPointModal['dataPointIds']}
                    sentFilter={this.state.dataPointModal['sentFilter']}
                    userMetadata={this.state.dataPointModal['userMetadata']}
                    source={this.state.dataPointModal['source']}
                />
                {
                    this.props.analysis['contributors']['metadata']['totalFollowers'] > 0 &&
                        <Tooltip 
                            placement="right" 
                            isOpen={this.state.contributorsTooltipOpen['totalFollowers']} 
                            target="contributors-total-followers-tooltip"
                            toggle={() => this.contributorsTooltipToggle('totalFollowers')}
                        >
                            This is the number of users plus their followers. This is the total size of community you can reach right now.
                        </Tooltip>
                }
                {
                    this.props.analysis['promoters']['metadata']['totalFollowers'] > 0 &&
                        <Tooltip 
                            placement="right" 
                            isOpen={this.state.promotersTooltipOpen['totalFollowers']} 
                            target="promoters-total-followers-tooltip"
                            toggle={() => this.promotersTooltipToggle('totalFollowers')}
                        >
                            This is the number of users plus their followers. This is the total size of community you can reach right now.
                        </Tooltip>
                }
                {
                    this.props.analysis['detractors']['metadata']['totalFollowers'] > 0 &&
                        <Tooltip 
                            placement="right" 
                            isOpen={this.state.detractorsTooltipOpen['totalFollowers']} 
                            target="detractors-total-followers-tooltip"
                            toggle={() => this.detractorsTooltipToggle('totalFollowers')}
                        >
                            This is the number of users plus their followers. This is the total size of community you can reach right now.
                        </Tooltip>
                }
                {
                    this.props.analysis['contributors']['metadata']['avgCount'] > 0 &&
                        <Tooltip 
                            placement="right" 
                            isOpen={this.state.contributorsTooltipOpen['avgCount']} 
                            target="contributors-avg-count-tooltip"
                            toggle={() => this.contributorsTooltipToggle('avgCount')}
                        >
                            This is the average number of data points each user in your community is posting related to your search term. This is an indicator of engagement.
                        </Tooltip>
                }
                {
                    this.props.analysis['promoters']['metadata']['avgCount'] > 0 &&
                        <Tooltip 
                            placement="right" 
                            isOpen={this.state.promotersTooltipOpen['avgCount']} 
                            target="promoters-avg-count-tooltip"
                            toggle={() => this.promotersTooltipToggle('avgCount')}
                        >
                            This is the average number of data points each user in your community is posting related to your search term. This is an indicator of engagement.
                        </Tooltip>
                }
                {
                    this.props.analysis['detractors']['metadata']['avgCount'] > 0 &&
                        <Tooltip 
                            placement="right" 
                            isOpen={this.state.detractorsTooltipOpen['avgCount']} 
                            target="detractors-avg-count-tooltip"
                            toggle={() => this.detractorsTooltipToggle('avgCount')}
                        >
                            This is the average number of data points each user in your community is posting related to your search term. This is an indicator of engagement.
                        </Tooltip>
                }
            </div>
        );
    };
}

export default SocialEngagement;



