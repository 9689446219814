import axios from 'axios';

export const setAlertsList = (alertsList) => ({
    type: 'SET_ALERTS_LIST',
    alertsList
});

export const startSetAlertsList = (userId) => {
    return (dispatch) => {
        return axios.get(`${process.env.SERVER_URL}/alerts/getAlerts`, 
            {
                params: {
                    userId
                },
                headers: {
                    'Authorization': "Bearer " + JSON.parse(localStorage.getItem('user'))['token']
                }
            }
        ).then((response) =>  {
            dispatch(setAlertsList(response['data']));
            return response['data'];
        }).catch((error) => {
        });
    };
};

export const setWidgetAnalysis = (widgetAnalysis) => ({
    type: 'SET_WIDGET_ANALYSIS',
    widgetAnalysis
});

export const getWidgetAnalysis = (userId, shareCode) => {
    return (dispatch) => {
        return axios.get(`${process.env.SERVER_URL}/alerts/getWidgetAnalysis`, 
            {
                params: {
                    userId,
                    shareCode
                },
                headers: {
                    'Authorization': "Bearer " + JSON.parse(localStorage.getItem('user'))['token']
                }
            }
        ).then((response) =>  {
            return response['data'];
        }).catch((error) => {
        });
    };
};

export const startSetWidgetAnalysis = (userId, shareCode) => {
    return (dispatch) => {
        return axios.get(`${process.env.SERVER_URL}/alerts/getWidgetAnalysis`, 
            {
                params: {
                    userId,
                    shareCode
                },
                headers: {
                    'Authorization': "Bearer " + JSON.parse(localStorage.getItem('user'))['token']
                }
            }
        ).then((response) =>  {
            dispatch(setWidgetAnalysis(response['data']));
            return response['data'];
        }).catch((error) => {
        });
    };
};