import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
} from 'reactstrap';

const EmptyCompareCard = ({ onClick, loading }) => {
    const onCardClick = () => {
        if (!loading) {
            onClick()
        }
    }

    return (
        <div className={`compare__empty-card ${loading && 'disabled'}`} onClick={onCardClick}>
            {
                loading ?
                    <img 
                        className="loader__image" 
                        src="/images/loader-light.gif"
                    />
                    :
                    <img src="/images/compare/add.webp" />
            }
        </div>
    )
}


export default EmptyCompareCard;