import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useField } from "formik";

import { ActionMeta, OnChangeValue } from 'react-select';

const EntitySelect = ({ name, type }) => {
	const [field, meta, { setValue }] = useField({ name });

	const handleChange = (
        newValue: OnChangeValue<Option, false>,
        actionMeta: ActionMeta<Option>
    ) => {
        if (newValue.length > 0) setValue(newValue.map((item) => item.value))
		else setValue([])
    };

    return (
        <CreatableSelect
            isClearable
            isMulti
            placeholder="Assign Triggers"
            className="onboarding__dropdown"
            onChange={handleChange}
        />
    );
}

export default EntitySelect