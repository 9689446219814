import React from 'react';
import HorizontalBarChart from '../Graphs/HorizontalBarChart';
import {
    Row,
    Col
} from 'reactstrap'

import CardHeader from '../Header/CardHeader';

export class NEV extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false
        };
    }
    toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }
    render() {
        const total = this.props.socialNPS.promoters + this.props.socialNPS.detractors + this.props.socialNPS.passives;
        const promotersPerc = Math.round((this.props.socialNPS.promoters / total) * 100);
        const detractorsPerc = Math.round((this.props.socialNPS.detractors / total) * 100);

        const socialNPSScore = promotersPerc - detractorsPerc;

        const labels = ['Promoters', 'Detractors'];
        const data = [this.props.socialNPS.promoters, this.props.socialNPS.detractors];
        const backgroundColors = ['#3ABAE2', '#E94F76'];

        let horizontalBarChart = {
            datasets: [{
                data: data,
                backgroundColor: backgroundColors,
                hoverBackgroundColor: backgroundColors,
                barThickness: 30,
                borderWidth: 0,
            }], 
            labels: labels,
            options: {
                events: [],
                responsive: true,
                maintainAspectRatio: false,
                animation: false,
                legend: {
                    display: false
                },
                title: {
                    display: false,
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            fontSize: 15,
                            fontColor: '#150637'
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            beginAtZero: true,
                            fontSize: 15,
                            fontColor: '#150637'
                        }
                    }]
                }
            }
        };
        
        return (
            <div className={'tait-alert__card'} style={{flex: 1}}>
                <CardHeader
                    title='Net Sentiment Score'
                    tooltipId={`tait-alert__nev-tooltip-${this.props.device}`}
                    tooltipText='NSS is a representation of your overall audience sentiment health. NSS mirrors net promoter score to showcase your promoters vs detractors. talkAItive calculates NSS across all your data for a given alert.'
                />
                <div className={'tait-alert__card-body'}>
                    <Row>
                        <Col xs={12}>
                            <div className={'tait-alert__nev'}>
                                <div className='tait-alert__nev-group'>
                                    <div className='tait-alert__nev-div'>
                                        <div className='tait-alert__nev-div-img'>
                                            <img
                                                className={'tait-alert__nev-img'} 
                                                src="https://ucarecdn.com/46923d09-e9a9-47f1-bca1-08a89f3d6d86/happiness.png"
                                            />
                                        </div>
                                        <span>Promoters</span>
                                    </div>
                                    <span className={'tait-alert__nev-score'}>{promotersPerc}%</span>
                                </div>
                                <div className='tait-alert__nev-score'>
                                    <span>-</span>
                                </div>
                                <div className='tait-alert__nev-group'>
                                    <div className='tait-alert__nev-div'>
                                        <div className='tait-alert__nev-div-img'>
                                            <img
                                                className={'tait-alert__nev-img'} 
                                                src="https://ucarecdn.com/ddd1a94a-a2f3-4a54-b7ae-7f24f1398cf0/sad.png"
                                            />
                                        </div>
                                        <span>Detractors</span>
                                    </div>
                                    <span className={'tait-alert__nev-score'}>{detractorsPerc}%</span>
                                </div>
                                <div className='tait-alert__nev-score'>
                                    <span>=</span>
                                </div>
                                <div className='tait-alert__nev-group'>
                                    {
                                        socialNPSScore > 0 ? 
                                            <div className='tait-alert__nev-div'>
                                                <div className='tait-alert__nev-div-img'>
                                                    <img
                                                        className={'tait-alert__nev-img'} 
                                                        src="https://ucarecdn.com/46923d09-e9a9-47f1-bca1-08a89f3d6d86/happiness.png"
                                                    />
                                                </div>
                                                <span>NSS</span>
                                            </div>
                                            :
                                            <div className='tait-alert__nev-div'>
                                                <div className='tait-alert__nev-div-img'>
                                                    <img
                                                        className={'tait-alert__nev-img'} 
                                                        src="https://ucarecdn.com/ddd1a94a-a2f3-4a54-b7ae-7f24f1398cf0/sad.png"
                                                    />
                                                </div>
                                                <span>NSS</span>
                                            </div>
                                    }
                                    <span className={'tait-alert__nev-score'}>{socialNPSScore}%</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{height: '200px'}}>
                                <HorizontalBarChart 
                                    key={Math.random()}
                                    datasets={horizontalBarChart.datasets}
                                    labels={horizontalBarChart.labels}
                                    options={horizontalBarChart.options}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    };
}

export default NEV;