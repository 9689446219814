import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Numeral from 'numeral';
import {
    Row,
    Col,
} from 'reactstrap';
import { Sparklines, SparklinesLine } from 'react-sparklines';

const validSources = [
    'Twitter', 'Reddit', 'Instagram', 'Facebook', 'TikTok', 'YouTube', 'Truth Social'
]

const SourceCard = ({ name, count, sentiment, timeline, onClick, sourceFilter }) => {
    const [imgUrl, setImgUrl] = useState(null)
    const [parsedSentiment, setParsedSentiment] = useState(null)
    const [sentimentTimeline, setSentimentTimeline] = useState([])
    const [countTimeline, setCountTimeline] = useState([])
    const [active, setActive] = useState(false)

    useEffect(() => {
        let tempSentimentTimeline = []
        let tempCountTimeline = []

        if (validSources.includes(name)) {
            setImgUrl(`/images/source-breakdown/${name.toLowerCase()}.webp`)
        } else {
            setImgUrl(`/images/source-breakdown/source.webp`)
        }

        if (Math.abs(sentiment) >= 1) {
            setParsedSentiment(sentiment.toFixed())
        } else {
            setParsedSentiment(sentiment.toFixed(2))
        }

        timeline.forEach(tl => {
            tempSentimentTimeline.push(tl['sentiment'])
            tempCountTimeline.push(tl['count'])
        })

        setSentimentTimeline(tempSentimentTimeline)
        setCountTimeline(tempCountTimeline)
    }, [])

    useEffect(() => {
        if (sourceFilter === name) {
            setActive(true)
        } else {
            setActive(false)
        }

    }, [sourceFilter])

    return (
        <div className={`source-card ${active ? 'active' : ''}`} onClick={() => onClick(name)}>
            <div className="source-card__title">
                <img
                    className="source-card__title-logo" 
                    src={imgUrl} 
                />
                <div className="vertical-center">
                    <span>
                        {name}
                    </span>
                </div>
            </div>

            <div className="source-card__info-section">
                <div className="vertical-center">
                    <span><b>Volume:</b> {Numeral(count).format("0,0")}</span>
                </div>
                <div className="sparklines">
                    <Sparklines data={countTimeline}>
                        <SparklinesLine color="blue" />
                    </Sparklines>
                </div>
            </div>
            <div className="source-card__info-section">
                <div className="vertical-center">
                    <span><b>Senti Trend:</b></span>
                </div>
                <div className="sparklines">
                    <Sparklines data={sentimentTimeline}>
                        <SparklinesLine color="blue" />
                    </Sparklines>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        sourceFilter: state['dataPointSearch']['sourceFilter']
    };
};

export default connect(mapStateToProps)(SourceCard);