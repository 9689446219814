
import React from "react";
import { connect } from 'react-redux';
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { GoogleLogin } from 'react-google-login';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';

import LoadingPage from '../../components/Loading/LoadingPage';
import { startSignUp, login, sendVerificationEmail } from '../../actions/auth';
import TermsOfServiceModal from './TermsOfServiceModal';

export class Signup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            localTOS: false,
            googleTOS: false,
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            status,
            tosModalOpen: false
        }
    }
    localTOSChange = (e) => {
        this.setState({localTOS: e.target.checked})
    }
    googleTOSChange = (e) => {
        this.setState({googleTOS: e.target.checked})
    }
    googleSuccess = (response) => {
        // this.props.signup('google', response, null);
        this.toggleLoading(true);

        const tokenBlob = new Blob([
            JSON.stringify({
                access_token: response['tokenObj']['access_token']
            } ,
            null, 
            2)
        ], {type: 'application/json'});

        let options = {
            method: 'POST',
            body: tokenBlob,
            mode: 'cors',   
            cache: 'default'
        };
        
        fetch(`${process.env.SERVER_URL}/auth/google/login`, options).then(r => {
            const accessToken = response['tokenObj']['access_token'];
            const id = response['googleId'];
            const email = response['profileObj']['email'];
            const givenName = response['profileObj']['givenName'];
            const familyName = response['profileObj']['familyName'];
            const password = null;
            const portal = 'google';

            if (r['status'] == 401) {
                this.props.startSignUp(
                    portal, id, email, accessToken, givenName, familyName, password
                ).then((resp) => {
                    if (resp == 200){
                        fetch(`${process.env.SERVER_URL}/auth/${portal}/login`, options).then(r => {
                            if (r['status'] == 401) {
                            } else if (r['status'] == 200) {
                                this.login(r, portal, email);
                            }
                        });
                    }
                })
            } 
            else if (r['status'] == 200) {
                this.setState({
                    status: 'User already exists. Please log in.'
                });

                this.toggleLoading(false);
            }
        });

    }
    googleFailed = (response) => {
    }
    toggleLoading = (state) => {
        this.setState({
            loading: state,
            localTOS: false,
            googleTOS: false
        })
    }
    login = (r, portal, email) => {
        const token = r.headers.get('x-auth-token');
        r.json().then(user => {
            if (token) {
                if (user == -1) {
                    this.setState({
                        status: 'User already exists. Please log in.'
                    });

                    this.toggleLoading(false);
                } else if (user == -2) {
                    this.setState({
                        status: 'Not verified'
                    });

                    this.props.sendVerificationEmail(email).then(() => {
                        this.toggleLoading(false);
                    });
                } else {
                    axios.get(`${process.env.SERVER_URL}/auth/getUser`, 
                        {
                            params: {
                                portal: portal,
                                id: user
                            },
                            headers: {
                                'Authorization': "Bearer " + token
                            }
                        }
                    ).then((response) =>  {
                        if (response['data']) {
                            const loggedInUser = {
                                isAuthenticated: true,
                                portal: portal,
                                id: user,
                                token: token,
                                email: response['data']['email'],
                                givenName: response['data']['given_name'],
                                familyName: response['data']['family_name'],
                                apiKey: response['data']['api_key']
                            }

                            localStorage.setItem('user', JSON.stringify(loggedInUser));
            
                            this.props.login(
                                portal, user, token, response['data']['email'], response['data']['given_name'], 
                                response['data']['family_name'], response['data']['api_key']
                            );

                            this.toggleLoading(false);
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                }
            }
        })
    }
    onSubmit = ({ firstName, lastName, email, password }, { setSubmitting }) => {
        setSubmitting(false);
        this.toggleLoading(true);

        let options = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                username: email,
                password: password
            })
        };
        
        fetch(`${process.env.SERVER_URL}/auth/local/login`, options).then(r => {
            const accessToken = null;
            const id = uuidv4();
            const portal = 'local';

            if (r['status'] == 401) {
                this.props.startSignUp(
                    portal, id, email, accessToken, firstName, lastName, password
                ).then((resp) => {
                    if (resp == 200){
                        fetch(`${process.env.SERVER_URL}/auth/${portal}/login`, options).then(r => {
                            if (r['status'] == 401) {
                            } else if (r['status'] == 200) {
                                this.login(r, portal, email);
                            }
                        });
                    }
                })
            } 
            else if (r['status'] == 200) {
                this.setState({
                    status: 'User already exists. Please log in.'
                });

                this.toggleLoading(false);
            }
        });
    }
    resendEmailVerification = (email) => {
        this.props.sendVerificationEmail(email);
    }
    toggleTOSModal = (e) => {
        this.setState({
            tosModalOpen: !this.state.tosModalOpen
        })
    }
    render() {
        const validationSchema = Yup.object().shape({
            email: Yup.string()
                .email('Email is invalid')
                .required('Email is required'),
            password: Yup.string().required('Password is required'),
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('Last name is required')
        });

        return (
            <div className="tait-alert__signup">
                <div className="tait-alert__signup-div">
                    <div className="tait-alert__signup-header">
                        <img
                            src="https://ucarecdn.com/2b29fdae-d550-44c3-950e-82bbbcd04313/logoblackpng.webp"
                        />
                    </div>
                    {
                        this.state.loading === true ? 
                            <LoadingPage colorTheme={this.props.colorTheme} mini={true}/>
                            :
                            <div className="tait-alert__signup-body">
                                <div>
                                    Thank you for starting your onboarding with talkAItive Emotional NPS.
                                </div>
                                <div>
                                    Please sign up and you will go through subscription opt-in and analysis setup. Happy learning!
                                </div>
                                <div className="tait-alert__signup-sections">
                                    <div className="tait-alert__signup-local">
                                        <Formik initialValues={this.state} validationSchema={validationSchema} onSubmit={this.onSubmit}>
                                        {({ errors, touched, isSubmitting }) => (
                                            <Form>
                                                <div className="form-group">
                                                    <Field placeholder="First Name" name="firstName" type="text" className={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid': '')} />
                                                    <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <Field placeholder="Last Name" name="lastName" type="text" className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid': '')} />
                                                    <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <Field placeholder="Email" name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <Field placeholder="Password" name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    {
                                                        this.state.status == 'Not verified' ?
                                                            <div className="tait-alert__signup-error">
                                                                <span style={{alignItems: 'center'}}>
                                                                    Email verification has been sent. Click <a style={{cursor: 'pointer'}} onClick={() => this.resendEmailVerification(this.props.email)}><b style={{textDecoration: 'underline'}}>here</b></a> to resend verification email.
                                                                </span>
                                                            </div> 
                                                            :
                                                            <div className="tait-alert__signup-error">
                                                                <span style={{alignItems: 'center'}}>
                                                                    {this.state.status}
                                                                </span>
                                                            </div>  
                                                    }
                                                </div>
                                                <div className="form-group tos-checkbox">
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input 
                                                                type="checkbox" 
                                                                name="tos-check" 
                                                                onChange={this.localTOSChange}
                                                            />{' '}
                                                            I agree to talkAItive's
                                                        </Label>
                                                        {' '}<a className="sign-up-modal__tos" style={{cursor: 'pointer'}} onClick={this.toggleTOSModal}>Terms Of Use</a>
                                                    </FormGroup>
                                                </div>
                                                <div className="form-group">
                                                    <Button type="submit" disabled={isSubmitting || !this.state.localTOS} className="tait-alert__signup-button">
                                                        Sign Up
                                                    </Button>
                                                </div>
                                            </Form>
                                            )}
                                        </Formik>
                                    </div>
                                    <div className='show-for-mobile'>
                                        <hr/>
                                    </div>
                                    <div className='show-for-tablet'>
                                        <hr/>
                                    </div>
                                    <div className='show-for-desktop'>
                                        <div className="tait-alert__signup-break"/>
                                    </div>
                                    <div className='show-for-desktop-wide'>
                                        <div className="tait-alert__signup-break"/>
                                    </div>
                                    <div className="tait-alert__signup-google">
                                        <div className="form-group tos-checkbox">
                                            <FormGroup check>
                                                <Label check>
                                                    <Input 
                                                        type="checkbox" 
                                                        name="tos-check" 
                                                        onChange={this.googleTOSChange}
                                                    />{' '}
                                                    To continue with Google signup, I agree to talkAItive's
                                                </Label>
                                                {' '}<a className="sign-up-modal__tos" style={{cursor: 'pointer'}} onClick={this.toggleTOSModal}>Terms Of Use</a>
                                            </FormGroup>
                                        </div>
                                        <div className="form-group">
                                            <GoogleLogin
                                                clientId={process.env.GOOGLE_CLIENT_ID}
                                                render={renderProps => (
                                                    <Button
                                                        className='dark-auth-button'
                                                        disabled={renderProps.disabled || !this.state.googleTOS}
                                                        onClick={renderProps.onClick}
                                                    >
                                                        <img
                                                            src='/images/signup/google-sign-up.png'
                                                        />
                                                    </Button>
                                                )}
                                                onSuccess={(e) => this.googleSuccess(e)}
                                                onFailure={(e) => this.googleFailed(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <span style={{textAlign: 'center'}}>
                                        Already have a talkAItive account? Log in <a href="/login">here</a>
                                    </span> 
                                </div>
                            </div>
                    }
                </div>
                <TermsOfServiceModal
                    open={this.state.tosModalOpen}
                    toggle={() => this.toggleTOSModal(null)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        colorTheme: state['admin']['colorTheme'],
        auth: state['auth'],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        startSignUp: (portal, id, email, accessToken, givenName, familyName, password) =>
            dispatch(startSignUp(portal, id, email, accessToken, givenName, familyName, password)),
        login: (portal, user, token, email, givenName, familyName, apiKey) => 
            dispatch(login(portal, user, token, email, givenName, familyName, apiKey)),
        startSetAvailableThemes: (portal, userId) => dispatch(startSetAvailableThemes(portal, userId)),
        sendVerificationEmail: (email) => dispatch(sendVerificationEmail(email))
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Signup);