import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Row,
    Col,
    Button
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from 'formik';

import Input from '../Input';
import Mapping from './Mapping';
import History from './History';
import LoadingPage from '../../Loading/LoadingPage';
import CardHeader from '../../Alerts/Widget/Header/CardHeader';
import { saveMailchimpIntegration, getMailchimpCampaigns, getMailchimpHistory } from '../../../actions/integrations';
import { startSetOnboarding } from '../../../actions/onboarding/onboarding';

const Mailchimp = ({ auth, mailchimp, saveMailchimpIntegration, getMailchimpCampaigns, startSetOnboarding, getMailchimpHistory }) => {
    const [loading, setLoading] = useState(true)
    const [successStatus, setSuccessStatus] = useState("")
    const [errorStatus, setErrorStatus] = useState("")
    const [mailchimpAuth, setMailchimpAuth] = useState(null)
    const [saveLoading, setSaveLoading] = useState(false)
    const [campaignHistory, setCampaignHistory] = useState([])
    const [campaigns, setCampaigns] = useState(null)
    const [alerts, setAlerts] = useState(null)

    const formikRef = React.createRef()

    useEffect(() => {
        if (mailchimp && mailchimp['apiKey'] && mailchimp['server']) {  
            setMailchimpAuth(mailchimp)
            getMailchimpCampaigns(mailchimp['apiKey'], mailchimp['server']).then(campaigns => {
                if (Array.isArray(campaigns)) {
                    startSetOnboarding(auth['id']).then(onboarding => {
                        getMailchimpHistory(auth['id']).then(mh => {
                            setAlerts(onboarding['config'])
                            setCampaigns(campaigns)
                            setCampaignHistory(mh)
                            setLoading(false)
                        })
                    })
                } else {
                    setLoading(false)
                }
            })
        } else {
            setLoading(false)
        }

        
    }, []);

    const onSave = (values) => {
        setSaveLoading(true)

        if (mailchimpAuth == null || (values['apiKey'] !== mailchimpAuth['apiKey'] || values['server'] !== mailchimpAuth['server'])) {
            saveMailchimpIntegration(auth.id, values).then(r => {
                if (r['status'] == 200) {
                    setMailchimpAuth({ apiKey: values['apiKey'], server: values['server'] })
                    getMailchimpCampaigns(values['apiKey'], values['server']).then(campaigns => {
                        startSetOnboarding(auth['id']).then(onboarding => {
                            setAlerts(onboarding['config'])
                            setCampaigns(campaigns)
                            setCampaignHistory([])
                            setLoading(false)
                        })
                    })

                    setSuccessStatus("Your credentials have been saved.")
                    setErrorStatus("")
                    setSaveLoading(false)
                } else if (r['status'] == 403) {
                    setSuccessStatus("")
                    setErrorStatus("Invalid credentials.")
                    setSaveLoading(false)
                } else {
                    setSuccessStatus("")
                    setErrorStatus("There was an error processing your request.")
                    setSaveLoading(false)
                }
            }) 
        } else {
            setSuccessStatus("Your credentials have been saved.")
            setErrorStatus("")
            setSaveLoading(false)
        }
    }

    const onCampaignTriggerSave = (values) => {
        setCampaignHistory(campaignHistory => [...campaignHistory, {
            'theme_name': values['alert']['theme'],
            'asset_name': values['alert']['asset'],
            'searchterm': values['alert']['searchTerm'],
            'campaign_id': values['campaign'],
            'status': values['status'],
            'triggers': values['triggers'],
            'match_any_word': values['matchAnyWord']
        }])
    }

    return (
        <Row>
            <Col xs={12}>
                <div className='tait-alert__card'>
                    <CardHeader
                        title='Mailchimp'
                        img='https://ucarecdn.com/4320698f-ff5b-4708-b326-60f974fde1bc/'
                    />
                    <div className='tait-alert__card-body'>
                        {
                            loading ?
                                <LoadingPage colorTheme="light" mini/> 
                                :
                                <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                                    {
                                        saveLoading ?
                                            <LoadingPage colorTheme="light" mini/> 
                                            :
                                            <Formik
                                                innerRef={formikRef}
                                                initialValues={
                                                    { 
                                                        apiKey: mailchimpAuth && 'apiKey' in mailchimpAuth ? mailchimpAuth['apiKey'] : "", 
                                                        server: mailchimpAuth && 'server' in mailchimpAuth ? mailchimpAuth['server'] : ""
                                                    }
                                                }
                                                validate={values => {
                                                    const errors = {};
                                                    if (!values.apiKey || values.apiKey == "") {
                                                        errors.apiKey = 'API key cannot be empty.';
                                                    } 
                                                    if (!values.server || values.server == "") {
                                                        errors.server = 'Server cannot be empty.';
                                                    } 
                                                    
                                                    return errors;
                                                }}
                                                onSubmit={onSave}
                                            >
                                                {({ isSubmitting }) => (
                                                    <Form className="mailchimp__form">
                                                        <Row className="mailchimp__row">
                                                            <Col xs={12} md={5} className="mailchimp__col">
                                                                <div className="mailchimp__label">
                                                                    <label htmlFor="apiKey" >API Key:</label>
                                                                </div>
                                                                <div className="mailchimp__input">
                                                                    <Input name="apiKey" />
                                                                    <ErrorMessage 
                                                                        name="apiKey" 
                                                                        component="div" 
                                                                        className="mailchimp__error-text"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={5} className="mailchimp__col">
                                                                <div className="mailchimp__label">
                                                                    <label htmlFor="server">Server:</label>
                                                                </div>
                                                                <div className="mailchimp__input">
                                                                    <Input name="server" />
                                                                    <ErrorMessage 
                                                                        name="server" 
                                                                        component="div" 
                                                                        className="mailchimp__error-text" 
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={2} className="mailchimp__col">
                                                                <Button 
                                                                    className="mailchimp__save-button"
                                                                    type="submit"
                                                                    onMouseDown={e => e.preventDefault()}
                                                                >
                                                                    Save
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <div>
                                                            {
                                                                successStatus && successStatus != "" && 
                                                                    <div className="mailchimp__success-status">
                                                                        <span>
                                                                            {successStatus}
                                                                        </span>
                                                                    </div>
                                                            }
                                                            {
                                                                errorStatus && errorStatus != "" && 
                                                                    <div className="mailchimp__error-status">
                                                                        <span>
                                                                            {errorStatus}
                                                                        </span>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                    }
                                    {
                                        mailchimpAuth && <Mapping 
                                            mailchimp={mailchimpAuth}
                                            campaigns={campaigns}
                                            alerts={alerts}
                                            campaignTriggerSave={onCampaignTriggerSave}
                                        />
                                    }
                                    <History 
                                        campaigns={campaigns}
                                        history={campaignHistory}
                                    />
                                </div>
                        }
                    </div>
                </div>
            </Col>
        </Row>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state['auth']
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        startSetOnboarding: (userId) => dispatch(startSetOnboarding(userId)),
        saveMailchimpIntegration: (userId, integration) => dispatch(saveMailchimpIntegration(userId, integration)),
        getMailchimpCampaigns: (apiKey, server) => dispatch(getMailchimpCampaigns(apiKey, server)),
        getMailchimpHistory: (userId) => dispatch(getMailchimpHistory(userId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mailchimp);