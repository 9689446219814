import React from "react";
import { connect } from 'react-redux';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "reactstrap";

import { GoogleLogin, GoogleLogout } from 'react-google-login';

import { logout } from '../../../actions/auth';

export class AccountSettings extends React.Component {
    constructor(props) {
        super(props);
    }
    logout = () => {
        localStorage.removeItem('user');
        this.props.logout();
    }
    render() {
        return (
            <div style={{float: 'right', paddingRight: '0px'}}>
                <Button className="header-logout__button" onClick={this.logout}>
                    Logout
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state['auth']
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);