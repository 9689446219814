import React from "react";
import { connect } from 'react-redux';

import AuthenticatedHeader from './AuthenticatedHeader';

export class Header extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.auth.isAuthenticated) {
      return (
        <AuthenticatedHeader 
          colorTheme={this.props.colorTheme}
          path={this.props.location.pathname}
          history={this.props.history}
        />
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    colorTheme: state['admin']['colorTheme'],
    auth: state['auth']
  }
};

export default connect(mapStateToProps)(Header);