import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';

import LoginView from "../../views/Login/Login";
var ps;

class Login extends React.Component {
    constructor(props) {
        super(props);
    }
    // componentDidMount() {
    //     console.log(navigator.platform.indexOf("Win"));
    //     if (navigator.platform.indexOf("Win") > -1) {
    //         ps = new PerfectScrollbar(this.refs.mainPanel);
    //         document.body.classList.toggle("perfect-scrollbar-on");
    //     }
    // }
    // componentWillUnmount() {
    //     if (navigator.platform.indexOf("Win") > -1) {
    //         ps.destroy();
    //         document.body.classList.toggle("perfect-scrollbar-on");
    //     }
    // }
    componentDidUpdate(e) {
        if (e.history.action === "PUSH") {
            this.refs.mainPanel.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        }
    }
    getRoutes() {
        const Login = (props) => {
            return (
                 <LoginView
                    {...props}
                />
            );
        }

        let routeList = [];
        routeList.push(
            <Route
                key='login'
                path={`/login`}
                component={Login}
            />
        );
        return routeList;
    };
    render() {
        return (
            <div className="wrapper">
                <div data-color={this.props.colorTheme}>
                    <Switch>{this.getRoutes()}</Switch>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        colorTheme: state['admin']['colorTheme']
    }
};

export default connect(mapStateToProps)(Login);