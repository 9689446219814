const dataPointSearchReducerDefaultState = {
    searchFilter: '',
    userFilter: {
        userName: null,
        source: null
    },
    sentimentFilter: null,
    sortFilter: 'newest',
    sourceFilter: null,
    dateFilter: null,
    dataPoints: []
};

export default (state = dataPointSearchReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_SEARCH_FILTER':
            return {
                ...state,
                searchFilter: action.searchFilter
            };
        case 'SET_USER_FILTER':
            return {
                ...state,
                userFilter: action.userFilter
            };
        case 'SET_SENTIMENT_FILTER': 
            return {
                ...state,
                sentimentFilter: action.sentimentFilter
            };
        case 'SET_SORT_FILTER':
            return {
                ...state,
                sortFilter: action.sortFilter
            };
        case 'SET_SOURCE_FILTER':
            return {
                ...state,
                sourceFilter: action.sourceFilter
            }
        case 'SET_DATE_FILTER':
            return {
                ...state,
                dateFilter: action.dateFilter
            };
        case 'RESET_FILTERS':
            return {
                ...state,
                searchFilter: '',
                sentimentFilter: null,
                sortFilter: 'newest',
                sourceFilter: null,
                dateFilter: null,
            };
        case 'SET_DATA_POINTS':
            return {
                ...state,
                dataPoints: action.dataPoints
            };
        default:
            return state;
    }
};
