import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from "react-select";

const AssetFilter = ({ history, assetFilter, alertsList, themeFilter}) => {
    const [dropdown, setDropdown] = useState([])
    const onSelect = (e) => {
        if (e.value != assetFilter) {
            const widget = alertsList[themeFilter][e.value][0]['shareCode'];

            history.push({
                pathname: '/alerts',
                search: `?shareCode=${widget}`
            })
        }
    }

    useEffect(() => {
        if (themeFilter) {
            setDropdown(Object.keys(alertsList[themeFilter]).sort().map(asset => ({
                value: asset,
                label: asset
            })))
        }
    }, [])

    return ( 
        <div style={{ display: 'flex', gap: '0.5rem' }}>
            <div className="vertical-center">
                <span style={{ fontWeight: 'bold' }}>
                    Asset:
                </span>
            </div>
            <Select
                className="tait-alert__select-full"
                classNamePrefix="select"
                placeholder="Select Asset"
                value={
                    dropdown.find(asset => 
                        asset['value'] == assetFilter
                    )
                }
                onChange={onSelect}
                options={dropdown}
            />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        alertsList: state['alerts']['alertsList'],
        themeFilter: state['alertsFilters']['theme'],
        assetFilter: state['alertsFilters']['asset'],
    };
};
  
export default connect(mapStateToProps)(AssetFilter);