import React from "react";
import { NavLink } from "react-router-dom";
import { 
  Nav,
  Collapse
} from "reactstrap";
import { connect } from 'react-redux';
import PerfectScrollbar from "perfect-scrollbar";

import ColorThemeSwitch from './ColorThemeSwitch';

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: false,
    }
  }
  setColorTheme = (colorTheme) => {
    localStorage.setItem('colorTheme', colorTheme);

    this.props.setColorTheme(colorTheme);
  }
  activeRoute(routeName) {
    return this.props.location.pathname.startsWith(`${routeName.split('/:id')[0].split(' ').join('_')}`) ? "active" : "";
  }
  handleResize = () => {
    if (window.innerWidth > 1400) {
      this.setState({
        mobile: false
      })
    } else if (window.innerWidth > 700){
      this.setState({
        mobile: false
      })
    } else {
      this.setState({
        mobile: true
      })
    }
  };
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener('resize', this.handleResize);
  }
  render() {
    return (
      <div
        className="sidebar"
        data-color={this.props.colorTheme}
      >
        <div className="logo">
          <a
            href="https://alerts.talkaitive.com/"
            className="simple-text logo-normal"
          >
            <div className="logo-img">
              <img src="/images/sidebar/talkaitive-logo.png" alt="react-logo" />
            </div>
          </a>
        </div>
        <div className="sidebar-user-nav">
          <div className="sidebar-user-nav__signed-in">
            <Nav>
              <li className={this.activeRoute("/integrations")}>
                <NavLink className='sidebar-nav-link' to="/integrations" activeClassName="">
                  <div className={`sidebar-nav-item ${this.activeRoute('/integrations')}`}>
                    <div className="vertical-center">
                      <img src="/images/sidebar/add.png" className="sidebar-nav-item__icon"/>
                    </div>
                    <p>Integrations</p>
                  </div>
                </NavLink>
              </li>
              <li className={this.activeRoute("/analysis-setup")}>
                <NavLink className='sidebar-nav-link' to="/analysis-setup" activeClassName="">
                  <div className={`sidebar-nav-item ${this.activeRoute('/analysis-setup')}`}>
                    <div className="vertical-center">
                      <img src="/images/sidebar/add.png" className="sidebar-nav-item__icon"/>
                    </div>
                    <p>Analysis Setup</p>
                  </div>
                </NavLink>
              </li>
            </Nav>
          </div>
        </div>
        <div className="sidebar-wrapper" ref="sidebar">
          <div className="sidebar-wrapper__theme-list">
            <Nav>
              <li className={this.activeRoute("/alerts")}>
                <NavLink className='sidebar-nav-link' to="/alerts" activeClassName="">
                  <div className={`sidebar-nav-item ${this.activeRoute('/alerts')}`}>
                    <div className="vertical-center">
                      <img src="/images/sidebar/dashboard.png" className="sidebar-nav-item__icon"/>
                    </div>
                    <p>My Alerts</p>
                  </div>
                </NavLink>
              </li>
            </Nav>
          </div>
        </div>
        {/*<ColorThemeSwitch />*/}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    colorTheme: state['admin']['colorTheme'],
    auth: state['auth'],
  }
};

export default connect(mapStateToProps)(Sidebar);