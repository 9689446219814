const alertsFiltersReducerDefaultState = {
    theme: null,
    asset: null,
    widget: null
};

export default (state = alertsFiltersReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_ALERTS_THEME_FILTER':
            return {
                ...state,
                theme: action.theme
            };
        case 'SET_ALERTS_ASSET_FILTER':
            return {
                ...state,
                asset: action.asset
            };
        case 'SET_ALERTS_WIDGET_FILTER':
            return {
                ...state,
                widget: action.widget
            };
        default:
            return state;
    }
};
