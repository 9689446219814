import React, { useState, useReducer, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Row,
    Col
} from 'reactstrap';

import DataPointSection from '../DataPointSearch/DataPointSection';
import LoadingPage from '../../../Loading/LoadingPage';
import Description from '../Description/Description';
import CardHeader from '../Header/CardHeader';
import Sentiment from '../Sentiment/Sentiment';
import Compare from '../Compare/Compare';
import SourceBreakdown from '../SourceBreakdown/SourceBreakdown';
import Volume from '../Volume/Volume';
import NEV from '../NEV/NEV';
import Hashtags from '../Hashtags/Hashtags';
import SentimentTimelineGraph from '../SentimentTimeline/SentimentTimelineGraph';
import SentimentTimelineContexts from '../SentimentTimeline/SentimentTimelineContexts/SentimentTimelineContexts';
import Entities from '../Entities/Entities';
import SocialEngagement from '../SocialEngagement/SocialEngagement';
import { setDateFilter, setSentimentFilter } from '../../../../actions/alerts/data-point-search';

const positiveThreshold = 0.30;
const negativeThreshold = -0.30;
const promotersThreshold = 0.66;
const detractorsThreshold = -0.66;

const extractHashtags = (text) => {
    var regexp = /\B\#\w\w+\b/g

    return text.match(regexp)
}

const parseDataPoints = (dataPoints, sourceFilter) => {
    let startDateEpoch = Number.MAX_VALUE;
    let endDateEpoch = 0;
    let totalEngagement = 0;
    let uniqueUserSet = new Set();
    let uniqueDataPointSet = new Set();
    let posCount = 0;
    let negCount = 0;
    let neutCount = 0;
    let sourceAnalysis = {}
    let volumeAnalysis = []
    let promotersCount = 0;
    let detractorsCount = 0;
    let passivesCount = 0;
    const numBuckets = 12;
    let minEpochTime = dataPoints.length > 0 ? dataPoints[0]['epoch_time'] : 0;
    let maxEpochTime = dataPoints.length > 0 ? dataPoints[dataPoints.length - 1]['epoch_time'] : 0;
    let epochDiff = Math.trunc((maxEpochTime - minEpochTime) / numBuckets) + 1
    let sentimentTimelineBuckets = []
    let currentEpochCounter = minEpochTime
    let hashtagsMapping = {};
    let userKeyMapping = {};
    let contributorsObj = {};
    let promotersObj = {};
    let detractorsObj = {};

    let contributorsMetadata = {
        totalFollowers: 0,
        accountAges: [],
        counts: [],
        avgCount: 0,
        avgAccountAge: null
    };

    let promotersMetadata = {
        totalFollowers: 0,
        accountAges: [],
        counts: [],
        avgCount: 0,
        avgAccountAge: null
    };

    let detractorsMetadata = {
        totalFollowers: 0,
        accountAges: [],
        counts: [],
        avgCount: 0,
        avgAccountAge: null
    };

    let tempSentTimelineObj = {
        count: {
            pos: 0,
            neg: 0,
            neut: 0
        },
        min: currentEpochCounter,
        max: currentEpochCounter + epochDiff
    }

    let entitiesObj = {}

    dataPoints.forEach(dp => {
        let userKey;
        let userName;

        if (dp['epoch_time'] < startDateEpoch) startDateEpoch = dp['epoch_time'];
        if (dp['epoch_time'] > endDateEpoch) endDateEpoch = dp['epoch_time'];

        if (!dp['source_uid'] && dp['engagement']) {
            Object.values(dp['engagement']).forEach(eng => {
                if (!Number.isNaN(eng)) {
                    totalEngagement += parseInt(eng);
                }
            })
        } else if (dp['engagement'] && dp['source_uid'] && !uniqueDataPointSet.has(dp['source_uid'])) {
            Object.values(dp['engagement']).forEach(eng => {
                if (!Number.isNaN(eng)) {
                    totalEngagement += parseInt(eng);
                }
            })

            uniqueDataPointSet.add(dp['source_uid'])
        }

        uniqueUserSet.add(`${dp['source']}_${dp['user_name']}`)

        if (dp['source'] == 'Reddit') {
            userName = dp['user_name'].split('_').slice(0, -1).join('_')
            userKey = `https://www.reddit.com/user/${userName}`
        } else if (dp['source'] == 'Twitter') {
            userName = dp['user_name']
            userKey = `https://twitter.com/${userName}`
        } else {
            userName = dp['user_name']
            userKey = `${dp['source']}/${userName}`
        }

        if (!(dp['source'] in sourceAnalysis)) {
            sourceAnalysis[dp['source']] = {
                count: 0,
                sentiment: 0,
                dataPoints: [],
                timeline: []
            }
        }
        sourceAnalysis[dp['source']]['count'] += 1
        sourceAnalysis[dp['source']]['sentiment'] += Math.abs(dp['sentiment'])
        sourceAnalysis[dp['source']]['dataPoints'].push({
            epochTime: dp['epoch_time'],
            sentiment: dp['sentiment']
        })

        if (!(dp['epoch_time'] >= tempSentTimelineObj['min'] && dp['epoch_time'] <= tempSentTimelineObj['max'])) {
            while (dp['epoch_time'] >= (currentEpochCounter + epochDiff) ) { 
                sentimentTimelineBuckets.push({
                    count: tempSentTimelineObj['count'],
                    date: tempSentTimelineObj['min']
                })
                currentEpochCounter += epochDiff

                tempSentTimelineObj = {
                    count: {
                        pos: 0,
                        neg: 0,
                        neut: 0
                    },
                    min: currentEpochCounter,
                    max: currentEpochCounter + epochDiff
                }
            }
        }

        if (dp['sentiment'] >= positiveThreshold) {
            posCount += 1
            tempSentTimelineObj['count']['pos'] += 1
        } else if (dp['sentiment'] <= negativeThreshold) {
            negCount += 1
            tempSentTimelineObj['count']['neg'] += 1
        } else {
            neutCount += 1
            tempSentTimelineObj['count']['neut'] += 1
        }

        if (dp['sentiment'] >= promotersThreshold) {
            promotersCount += 1;

            if (userKey in promotersObj) {
                promotersObj[userKey]['sentimentArr'].push(dp['sentiment'])
                promotersObj[userKey]['vws'] += Math.abs(dp['sentiment'])
                promotersObj[userKey]['dataPointIds'].push(dp['id'])
            } else {
                promotersObj[userKey] = {
                    sentimentArr: [dp['sentiment']],
                    vws: Math.abs(dp['sentiment']),
                    dataPointIds: [dp['id']]
                }
            }
        } else if (dp['sentiment'] <= detractorsThreshold) {
            detractorsCount += 1;

            if (userKey in detractorsObj) {
                detractorsObj[userKey]['sentimentArr'].push(dp['sentiment'])
                detractorsObj[userKey]['vws'] += Math.abs(dp['sentiment'])
                detractorsObj[userKey]['dataPointIds'].push(dp['id'])
            } else {
                detractorsObj[userKey] = {
                    sentimentArr: [dp['sentiment']],
                    vws: Math.abs(dp['sentiment']),
                    dataPointIds: [dp['id']]
                }
            }
        } else {
            passivesCount += 1; 
        }

        if (dp['entities']) {
            for (const [entityType, entities] of Object.entries(dp['entities'])) {
                entities.forEach(ent => {
                    let entityParsed = ent.toLowerCase()

                    if (entityParsed in entitiesObj) {
                        if (entityType in entitiesObj[entityParsed]) {
                            entitiesObj[entityParsed][entityType]['count'] += 1
                            entitiesObj[entityParsed][entityType]['sentiment'] += dp['sentiment']
                        } else {
                            entitiesObj[entityParsed][entityType] = {
                                count: 1,
                                sentiment: dp['sentiment']
                            }
                        }
                    } else {
                        entitiesObj[entityParsed] = {
                            [entityType]: {
                                count: 1,
                                sentiment: dp['sentiment']
                            }
                        }
                    }
                })
            }
        }
        

        let hashtags = extractHashtags(dp['text'])

        if (hashtags) {
            hashtags.forEach(ht => {
                if (ht.toLowerCase() in hashtagsMapping) {
                    hashtagsMapping[ht.toLowerCase()] += Math.abs(dp['sentiment'])
                } else {
                    hashtagsMapping[ht.toLowerCase()] = Math.abs(dp['sentiment'])
                }
            })
        }

        if (!(userKey in userKeyMapping)) {
            userKeyMapping[userKey] = {
                userName: userName,
                location: dp['user_location'],
                metadata: dp['metadata'],
                source: dp['source']
            }
        }

        if (userKey in contributorsObj) {
            contributorsObj[userKey]['sentimentArr'].push(dp['sentiment'])
            contributorsObj[userKey]['vws'] += Math.abs(dp['sentiment'])
            contributorsObj[userKey]['dataPointIds'].push(dp['id'])
        } else contributorsObj[userKey] = {
            sentimentArr: [dp['sentiment']],
            vws: Math.abs(dp['sentiment']),
            dataPointIds: [dp['id']]
        }
    })

    sentimentTimelineBuckets.push({
        count: tempSentTimelineObj['count'],
        date: tempSentTimelineObj['min'],
    })
    
    if (sourceFilter == null) {
        Object.keys(sourceAnalysis).forEach(source => {
            let sDataPoints = sourceAnalysis[source]['dataPoints']

            let sMinEpochTime = sDataPoints.length > 0 ? sDataPoints[0]['epochTime'] : 0;
            let sMaxEpochTime = sDataPoints.length > 0 ? sDataPoints[sDataPoints.length - 1]['epochTime'] : 0;
            let sEpochDiff = (sMaxEpochTime - sMinEpochTime) / numBuckets
            let sCurrentEpochCounter = sMinEpochTime
            let sSentimentTimelineBuckets = []

            let sTempSentTimelineObj = {
                count: 0,
                sentiment: 0,
                min: sCurrentEpochCounter,
                max: sCurrentEpochCounter + sEpochDiff
            }

            sDataPoints.forEach(sdp => {
                if (!(sdp['epochTime'] >= sTempSentTimelineObj['min'] && sdp['epochTime'] <= sTempSentTimelineObj['max'])) {
                    while (sdp['epochTime'] >= (sCurrentEpochCounter + sEpochDiff)) {
                        sSentimentTimelineBuckets.push({
                            count: sTempSentTimelineObj['count'],
                            sentiment: sTempSentTimelineObj['sentiment'],
                            date: sTempSentTimelineObj['min']
                        })
                        sCurrentEpochCounter += sEpochDiff

                        sTempSentTimelineObj = {
                            count: 0,
                            sentiment: 0,
                            min: sCurrentEpochCounter,
                            max: sCurrentEpochCounter + sEpochDiff
                        }
                    }
                }

                sTempSentTimelineObj['count'] += 1
                sTempSentTimelineObj['sentiment'] += Math.abs(sdp['sentiment'])
            })

            sSentimentTimelineBuckets.push({
                count: sTempSentTimelineObj['count'],
                sentiment: sTempSentTimelineObj['sentiment'],
                date: sTempSentTimelineObj['min']
            })

            sourceAnalysis[source]['timeline'] = sSentimentTimelineBuckets
            delete sourceAnalysis[source]['dataPoints']
        })
    }

    const entitiesArr = Object.keys(entitiesObj).map(ent => {
        let totalCount = 0
        let totalSentiment = 0
        let sentiment = null
        let entitiesTypeArr = []

        for (const [entityType, entData] of Object.entries(entitiesObj[ent])) {
            totalCount += entData['count']
            totalSentiment += entData['sentiment']
            entitiesTypeArr.push([entityType, entData['count']])
        }

        entitiesTypeArr.sort((a, b) => b[1] > a[1] ? 1 : ((a[1] > b[1]) ? -1 : 0))

        if (totalSentiment >= positiveThreshold) {
            sentiment = 'positive'
        } else if (totalSentiment <= negativeThreshold) {
            sentiment = 'negative'
        } else {
            sentiment = 'neutral'
        }

        return ({
            entity: ent,
            totalCount: totalCount,
            totalSentiment: totalSentiment,
            sentiment: sentiment,
            entityType: entitiesTypeArr[0][0],
        })
    });

    entitiesArr.sort((a, b) => b['totalCount'] > a['totalCount'] ? 1 : ((a['totalCount'] > b['totalCount']) ? -1 : 0))

    for (const [key, value] of Object.entries(sourceAnalysis)) {
        volumeAnalysis.push({
            source: key,
            countPerc: Math.round((value['count'] / dataPoints.length) * 100)
        })
    }

    let contributorsArr = Object.entries(contributorsObj).map(([key, value]) => {
        if (userKeyMapping[key]['metadata'] && 'user' in userKeyMapping[key]['metadata'] && userKeyMapping[key]['metadata']['user']) {
            contributorsMetadata['totalFollowers'] += 1 + userKeyMapping[key]['metadata']['user']['followers_count']

            if (userKeyMapping[key]['metadata']['user']['created_at']){
                contributorsMetadata['accountAges'].push(moment().diff(new Date(moment(userKeyMapping[key]['metadata']['user']['created_at'], "ddd MMM DD HH:mm:ss Z YYYY")), 'months', true))
            }
        }

        contributorsMetadata['counts'].push(value['dataPointIds'].length) 

        return ({
            userSource: key,
            userName: userKeyMapping[key]['userName'],
            location: userKeyMapping[key]['location'],
            metadata: userKeyMapping[key]['metadata'],
            source: userKeyMapping[key]['source'],
            dataPointIds: value['dataPointIds'],
            vws: value['vws'],
            averageSentiment: value['sentimentArr'].reduce((a, b) => a + b, 0) / value['sentimentArr'].length
        })
    })

    let promotersArr = Object.entries(promotersObj).map(([key, value]) => {
        if (userKeyMapping[key]['metadata'] && 'user' in userKeyMapping[key]['metadata'] && userKeyMapping[key]['metadata']['user']) {
            promotersMetadata['totalFollowers'] += 1 + userKeyMapping[key]['metadata']['user']['followers_count']
            
            if (userKeyMapping[key]['metadata']['user']['created_at']) {
                promotersMetadata['accountAges'].push(moment().diff(new Date(moment(userKeyMapping[key]['metadata']['user']['created_at'], "ddd MMM DD HH:mm:ss Z YYYY")), 'months', true))
            }
        }

        promotersMetadata['counts'].push(value['dataPointIds'].length) 

        return ({
            userSource: key,
            userName: userKeyMapping[key]['userName'],
            location: userKeyMapping[key]['user_location'],
            metadata: userKeyMapping[key]['metadata'],
            source: userKeyMapping[key]['source'],
            dataPointIds: value['dataPointIds'],
            vws: value['vws'],
            averageSentiment: value['sentimentArr'].reduce((a, b) => a + b, 0) / value['sentimentArr'].length
        })
    })

    let detractorsArr = Object.entries(detractorsObj).map(([key, value]) => {
        if (userKeyMapping[key]['metadata'] && 'user' in userKeyMapping[key]['metadata'] && userKeyMapping[key]['metadata']['user']) {
            detractorsMetadata['totalFollowers'] += 1 + userKeyMapping[key]['metadata']['user']['followers_count']
                
            if (userKeyMapping[key]['metadata']['user']['created_at']) {
                detractorsMetadata['accountAges'].push(moment().diff(new Date(moment(userKeyMapping[key]['metadata']['user']['created_at'], "ddd MMM DD HH:mm:ss Z YYYY")), 'months', true))
            }
        }

        detractorsMetadata['counts'].push(value['dataPointIds'].length) 

        return ({
            userSource: key,
            userName: userKeyMapping[key]['userName'],
            location: userKeyMapping[key]['user_location'],
            metadata: userKeyMapping[key]['metadata'],
            source: userKeyMapping[key]['source'],
            dataPointIds: value['dataPointIds'],
            vws: value['vws'],
            averageSentiment: value['sentimentArr'].reduce((a, b) => a + b, 0) / value['sentimentArr'].length
        })
    })

    if (contributorsMetadata['counts'].length > 0) {
        contributorsMetadata['avgCount'] = contributorsMetadata['counts'].reduce((a, b) => a + b, 0) / contributorsMetadata['counts'].length
    } else {
        contributorsMetadata['avgCount'] = 0
    }
    if (contributorsMetadata['accountAges'].length > 0) {
        contributorsMetadata['avgAccountAge'] = contributorsMetadata['accountAges'].reduce((a, b) => a + b, 0) / contributorsMetadata['accountAges'].length
    } else {
        contributorsMetadata['avgAccountAge'] = 0
    }
    delete contributorsMetadata['accountAges']
    delete contributorsMetadata['counts']

    if (promotersMetadata['counts'].length > 0) {
        promotersMetadata['avgCount'] = promotersMetadata['counts'].reduce((a, b) => a + b, 0) / promotersMetadata['counts'].length
    } else {
        promotersMetadata['avgCount'] = 0
    }
    if (promotersMetadata['accountAges'].length > 0) {
        promotersMetadata['avgAccountAge'] = promotersMetadata['accountAges'].reduce((a, b) => a + b, 0) / promotersMetadata['accountAges'].length
    } else {
        promotersMetadata['avgAccountAge'] = 0
    }
    delete promotersMetadata['accountAges']
    delete promotersMetadata['counts']

    if (detractorsMetadata['counts'].length > 0) {
        detractorsMetadata['avgCount'] = detractorsMetadata['counts'].reduce((a, b) => a + b, 0) / detractorsMetadata['counts'].length
    } else {
        detractorsMetadata['avgCount'] = 0
    }
    if (detractorsMetadata['accountAges'].length > 0) {
        detractorsMetadata['avgAccountAge'] = detractorsMetadata['accountAges'].reduce((a, b) => a + b, 0) / detractorsMetadata['accountAges'].length
    } else {
        detractorsMetadata['avgAccountAge'] = 0
    }
    delete detractorsMetadata['accountAges']
    delete detractorsMetadata['counts']

    contributorsArr.sort((a,b) => (a['vws'] < b['vws']) ? 1 : ((b['vws'] < a['vws']) ? -1 : 0))
    promotersArr.sort((a,b) => (a['vws'] < b['vws']) ? 1 : ((b['vws'] < a['vws']) ? -1 : 0))
    detractorsArr.sort((a,b) => (a['vws'] < b['vws']) ? 1 : ((b['vws'] < a['vws']) ? -1 : 0))

    let hashtagsAnalysis = Object.entries(hashtagsMapping).map(ht => (
        {
            hashtag: ht[0],
            score: ht[1]
        }
    ))

    hashtagsAnalysis.sort((a, b) => b['score'] - a['score'])
    hashtagsAnalysis = hashtagsAnalysis.slice(0, 50)

    return {
        startDateEpoch: startDateEpoch,
        endDateEpoch: endDateEpoch,
        uniqueUserSet: uniqueUserSet,
        totalEngagement: totalEngagement,
        sentimentAnalysis: {
            POS: dataPoints.length > 0 ? posCount / dataPoints.length : 0,
            NEG: dataPoints.length > 0 ? negCount / dataPoints.length : 0,
            NEUT: dataPoints.length > 0 ? neutCount / dataPoints.length : 0
        },
        volumeAnalysis: volumeAnalysis,
        socialNPS: {
            promoters: promotersCount,
            detractors: detractorsCount, 
            passives: passivesCount
        },
        sentimentTimeline: sentimentTimelineBuckets,
        sourceAnalysis: sourceAnalysis,
        entitiesAnalysis: entitiesArr,
        socialEngagement: {
            contributors: {
                metadata: contributorsMetadata,
                userList: contributorsArr
            },
            promoters: {
                metadata: promotersMetadata,
                userList: promotersArr
            },
            detractors: {
                metadata: detractorsMetadata,
                userList: detractorsArr
            }
        },
        hashtagsAnalysis: hashtagsAnalysis
    }
}

const Analysis = ({ dataPoints, setDateFilter, setSentimentFilter, colorTheme, timeline, sentimentFilter, sourceFilter }) => {
    const [loading, setLoading] = useState(true);
    const [sourceLoading, setSourceLoading] = useState(false);
    const [state, setState] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            count: 0,
            startDate: null,
            endDate: null,
            totalUsers: 0,
            totalEngagement: 0,
            sentimentAnalysis: {POS: 0, NEG: 0, NEUT: 0},
            volumeAnalysis: [],
            socialNPS: {promoters: 0, detractors: 0, passives: 0},
            sentimentTimeline: [],
            sourceAnalysis: {},
            entitiesAnalysis: [],
            socialEngagement: {
                contributors: {
                    metadata: {},
                    userList: []
                },
                promoters: {
                    metadata: {},
                    userList: []
                },
                detractors: {
                    metadata: {},
                    userList: []
                }
            },
            hashtagsAnalysis: [],
            contexts: []
        }
    )
    const [additionalContexts, setAdditionalContexts] = useState([])
    const [sourceAnalysis, setSourceAnalysis] = useState({})


    useEffect(() => {
        if (sourceLoading) {
            let dataPointsCopy = JSON.parse(JSON.stringify(dataPoints));

            if (sourceFilter) {
                dataPointsCopy = dataPointsCopy.filter(dp => dp['source'] == sourceFilter)
            }

            dataPointsCopy.sort((a, b) => a['epoch_time'] - b['epoch_time'])
            
            let analysis = parseDataPoints(dataPointsCopy)

            setState({
                count: dataPointsCopy.length,
                startDate: moment.unix(analysis['startDateEpoch']).format("ddd MMM DD, YYYY hh:mm:ss a"),
                endDate: moment.unix(analysis['endDateEpoch']).format("ddd MMM DD, YYYY hh:mm:ss a"),
                totalUsers: analysis['uniqueUserSet'].size,
                totalEngagement: analysis['totalEngagement'],
                sentimentAnalysis: analysis['sentimentAnalysis'],
                volumeAnalysis: analysis['volumeAnalysis'],
                socialNPS: analysis['socialNPS'],
                sentimentTimeline: analysis['sentimentTimeline'],
                sourceAnalysis: analysis['sourceAnalysis'],
                entitiesAnalysis: analysis['entitiesAnalysis'],
                socialEngagement: analysis['socialEngagement'],
                hashtagsAnalysis: analysis['hashtagsAnalysis']
            })

            if (sourceFilter == null) {
                setSourceAnalysis(analysis['sourceAnalysis'])
            }

            setLoading(false)
            setSourceLoading(false)
        }
    }, [sourceLoading])

    useEffect(() => {
        setSourceLoading(true)
    }, [dataPoints, sourceFilter])

    const sentimentTimelineNodeClick = (sentiment, startDate, endDate, idx) => {
        setDateFilter(startDate, endDate)
        setSentimentFilter(sentiment)

        if (idx !== null) {
            timeline.forEach(tl => {
                if (tl['source'] == 'Overall') {
                    const tempAdditionalContexts = []

                    tl['data'].forEach(tld => {
                        if (tld['date'] >= startDate && tld['date'] <= endDate) {
                            tempAdditionalContexts.push(...tld['keywords'][sentiment])
                        }
                    })

                    tempAdditionalContexts.sort((a, b) => b[1] - a[1])

                    setAdditionalContexts(tempAdditionalContexts.slice(0, 20))
                }
            })
        } else {
            setAdditionalContexts([])
        }
    }

    if (loading) {
        return (
            <Row>
                <Col xs={12}>
                    <div className='content-row'>
                        <LoadingPage colorTheme={colorTheme} mini={true}/>
                    </div>
                </Col>
            </Row>
        )
    }

    if (dataPoints.length > 0) {
        return (
            <Fragment>
                <Row>
                    <Col xs={12}>
                        <Compare 
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SourceBreakdown 
                            analysis={sourceAnalysis}
                        />
                    </Col>
                </Row>
                {
                    sourceLoading ?
                        <Row>
                            <Col xs={12}>
                                <div className='content-row'>
                                    <LoadingPage colorTheme={colorTheme} mini={true}/>
                                </div>
                            </Col>
                        </Row>
                        :
                        <Fragment>
                            <Row>
                                <Col xs={12}>
                                    <Description 
                                        count={state['count']}
                                        startDate={state['startDate']}
                                        endDate={state['endDate']}
                                        totalUsers={state['totalUsers']}
                                        totalEngagement={state['totalEngagement']}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={6} style={{ gap: '1rem' }}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Volume
                                                analysis={state['volumeAnalysis']}
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Sentiment 
                                                analysis={state['sentimentAnalysis']}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} md={12} lg={6} style={{ gap: '1rem' }}>
                                    <NEV
                                        socialNPS={state['socialNPS']}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={6} style={{ gap: '1rem' }}>
                                    <Row>
                                        <Col xs={12}>
                                            <div className={'tait-alert__card'}>
                                                <CardHeader
                                                    title='Sentiment Timeline'
                                                    tooltipId='tait-alert__st-tooltip'
                                                    tooltipText='Sentiment Timeline helps identify dramatic shifts across your audience sentiment health. A spike in negative posts about your brand signals an emerging crisis. Click on a node to highlight related emotional contexts for that time period.'
                                                />
                                                <div className={'tait-alert__card-body'}>
                                                    <div>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <SentimentTimelineGraph
                                                                    timeline={state['sentimentTimeline']}
                                                                    nodeClick={sentimentTimelineNodeClick}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <SentimentTimelineContexts
                                                                    contexts={additionalContexts}
                                                                    sentiment={sentimentFilter}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <Entities
                                                entities={state['entitiesAnalysis']}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <Hashtags
                                                hashtags={state['hashtagsAnalysis']}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <DataPointSection
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <SocialEngagement
                                        analysis={state['socialEngagement']}
                                    />
                                </Col>
                            </Row>
                        </Fragment>
                }
            </Fragment>
        )
    } else {
        return (
            <Row>
                <Col xs={12}>
                    <Description />
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        colorTheme: state['admin']['colorTheme'],
        dataPoints: state['dataPointSearch']['dataPoints'],
        timeline: state['alerts']['widgetAnalysis']['timeline'],
        sentimentFilter: state['dataPointSearch']['sentimentFilter'],
        sourceFilter: state['dataPointSearch']['sourceFilter'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setDateFilter: (startDate, endDate) => dispatch(setDateFilter(startDate, endDate)),
        setSentimentFilter: (sentimentFilter) => dispatch(setSentimentFilter(sentimentFilter))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analysis);
