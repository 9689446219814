import React from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import {
    Row,
    Col,
    Button
} from 'reactstrap';

import LoadingPage from '../../components/Loading/LoadingPage';
import Header from '../../components/Integrations/Header';
import Twitter from '../../components/Integrations/Twitter';
import MailChimp from '../../components/Integrations/Mailchimp/Mailchimp';
import { startSetIntegrations } from '../../actions/integrations';

export class Integrations extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            mailchimp: {},
            twitter: {}
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);

        this.props.startSetIntegrations(this.props.auth.id).then((integrations) => {
            this.setState({
                mailchimp: integrations['mailchimp_integration'],
                twitter: integrations['twitter_integration'],
                loading: false
            })
        });
    }
    toggleLoading = (status) => {
        this.setState({
            loading: status
        })
    }
    render() {
        if (this.state.loading === true) {
            return <LoadingPage colorTheme='light'/>;
        }

        return (
            <div className="content">
                <div className="content-row">
                    <Header />
                </div>
                <div className="content-row">
                    <MailChimp 
                        mailchimp={this.state.mailchimp}
                    />
                    {/*<Twitter 
                        twitter={this.state.twitter}
                    />*/}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state['auth']
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        startSetIntegrations: (userId) => dispatch(startSetIntegrations(userId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);