import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import {
    Row,
    Col
} from 'reactstrap';

import { 
    startSetWidgetAnalysis,
} from '../../../actions/alerts/alerts';

import { getDataPointsBySearch } from '../../../actions/alerts/data-point-search';

import LoadingPage from '../../../components/Loading/LoadingPage';
import SearchSentiment from './SearchSentiment/SearchSentiment';
import Analysis from './Analysis/Analysis';

const Widget = ({ 
    userId,
    colorTheme,
    widget,
    widgetFilter,
    startSetWidgetAnalysis,
    getDataPointsBySearch,
    searchFilter
}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [analysisLoading, setAnalysisLoading] = useState(false);
    const [widgetAnalysis, setWidgetAnalysis] = useState(widget);

    useEffect(() => {
        setLoading(true);

        startSetWidgetAnalysis(userId, widgetFilter).then(widgetAnalysis => {
            setLoading(false)
            setWidgetAnalysis(widgetAnalysis)
        }).catch((error) => {
        });
    }, [widgetFilter])

    useEffect(() => {
        setAnalysisLoading(true);

        getDataPointsBySearch(widget['shareCode'], searchFilter).then(() => {
            setAnalysisLoading(false);
        }).catch((error) => {
        });
    }, [searchFilter])

    const toggleLoading = (status) => {
        setLoading(status)
    }

    if (loading) {
        return (
            <div className="content-row">
                <Row>
                    <Col xs={12}>
                        <LoadingPage colorTheme={colorTheme} mini={true}/>
                    </Col>
                </Row>
            </div>
        )
    } else if (widgetAnalysis) {
        return (
            <div className="content-row">
                <Row>
                    <Col xs={12}>
                        <div className='tait-alert'>
                            <div className='tait-alert__body'>
                                <SearchSentiment 
                                    contexts={widgetAnalysis.keywords['Overall']}
                                />
                                {
                                    analysisLoading ?
                                        <Row>
                                            <Col xs={12}>
                                                <div className='content-row'>
                                                    <LoadingPage colorTheme={colorTheme} mini={true}/>
                                                </div>
                                            </Col>
                                        </Row>
                                        :
                                        <Analysis />
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    } else {
        return (
            <div></div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userId: state['auth']['id'],
        colorTheme: state['admin']['colorTheme'],
        widget: state['alerts']['widgetAnalysis'],
        widgetFilter: state['alertsFilters']['widget'],
        searchFilter: state['dataPointSearch']['searchFilter']
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        startSetWidgetAnalysis: (userId, shareCode) => dispatch(startSetWidgetAnalysis(userId, shareCode)),
        getDataPointsBySearch: (shareCode, searchFilter) => dispatch(getDataPointsBySearch(shareCode, searchFilter)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Widget);