import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCircleChevronLeft,
	faCircleChevronRight,
	faCircleXmark
} from '@fortawesome/free-solid-svg-icons';
import MediaDataPoint from './MediaDataPoint';


const MediaGallery = ({dataPoints}) => {
	const [slideNum, setSlideNum] = useState(0)
	const [openModal, setOpenModal] = useState(false)

	const handleOpenModal = (index) => {
		setSlideNum(index)
		setOpenModal(true)
	}

	const handleCloseModal = () => {
		setOpenModal(false)
	}

	const prevSlide = () => {
		slideNum === 0 ? setSlideNum(dataPoints.length - 1) : setSlideNum(slideNum - 1) 
	}

	const nextSlide = () => {
		slideNum === dataPoints.length - 1 ? setSlideNum(0) : setSlideNum(slideNum + 1)
	}

	return (
		<div>
			{
				openModal &&
					<div className="media-gallery__slider">
						<FontAwesomeIcon icon={faCircleXmark} className="media-gallery__btn-close" onClick={handleCloseModal} />
						<FontAwesomeIcon icon={faCircleChevronLeft} className="media-gallery__btn-prev" onClick={prevSlide} />
						<FontAwesomeIcon icon={faCircleChevronRight} className="media-gallery__btn-next" onClick={nextSlide} />
						<div className="media-gallery__full-screen-img">
							<img src={dataPoints[slideNum]['metadata']['media']['url']} />
						</div>
					</div>
			}
			<div className="media-gallery">
				{
					dataPoints && dataPoints.map((slide, index) => (
						<MediaDataPoint 
							key={index}
							onClick={() => handleOpenModal(index)}
							data={slide}
						/>
					))
				}
			</div>
		</div>
	)
}


export default MediaGallery