import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
	Button
} from 'reactstrap';

import { setSearchFilter, setDateFilter, setSentimentFilter, setSourceFilter } from '../../../../../actions/alerts/data-point-search';

const ContextFilter = ({ contexts, searchTerm, setSearchFilter, searchFilter, setSentimentFilter, setDateFilter, setSourceFilter }) => {
	const onClick = (context) => {
		if (context[0] == searchTerm) {
			setDateFilter(null)
			setSentimentFilter(null)
			setSourceFilter(null)
			setSearchFilter('')
		} else {
			setDateFilter(null)
			setSentimentFilter(null)
			setSourceFilter(null)
			setSearchFilter(context[0])
		}
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
			<div>
				<span>
					Top Identified Contexts
				</span>
			</div>
			<div className="dps-context-filter">
				{
					contexts.map(c => (
						<Button
							className={`tait-alert__st-context tait-alert__st-context-${c[3]} ${searchTerm == c[0] && 'selected'}`}
	                        key={c[0]}
	                        onClick={() => onClick(c)}
	                        onMouseDown={e => e.preventDefault()}
	                    >
	                        {c[0]}
	                    </Button>
					))
				}
			</div>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => {
    return {
        searchTerm: state['dataPointSearch']['searchFilter']
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSearchFilter: (searchFilter) => dispatch(setSearchFilter(searchFilter)),
        setDateFilter: (startDate, endDate) => dispatch(setDateFilter(startDate, endDate)),
        setSentimentFilter: (sentimentFilter) => dispatch(setSentimentFilter(sentimentFilter)),
        setSourceFilter: (sourceFilter) => dispatch(setSourceFilter(sourceFilter))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContextFilter);