import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Row,
    Col,
    Image
} from 'reactstrap';

import CardHeader from '../Header/CardHeader';
import EmptyCompareCard from './EmptyCompareCard';
import CompareCard from './CompareCard';
import AlertPickerModal from './AlertPickerModal';

import { setCompareList } from '../../../../actions/alerts/compare';

import { 
    getWidgetAnalysis,
} from '../../../../actions/alerts/alerts';

const Compare = ({ userId, getWidgetAnalysis, compareList, setCompareList }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
    }, []);

    const toggleModalOpen = () => {
        setModalIsOpen(current => !current)
    }

    const onAlertSelect = (theme, asset, alert) => {
        setLoading(true)

        getWidgetAnalysis(userId, alert).then(analysis => {
            let vws = 0
            analysis['timeline'][0]['data'].forEach(a => {
                vws += a['vws']
            })

            setCompareList([
                ...compareList, 
                {
                    theme: theme,
                    asset: asset,
                    shareCode: alert,
                    nev: analysis['socialNPS'],
                    vws: vws,
                    dataPointCount: analysis['dataPointCount'],
                    sentiment: analysis['sentimentAnalysis'],
                    engagement: analysis['engagementAnalysis'],
                    sentimentTimeline: analysis['timeline'][0],
                    startDate: moment.unix(analysis['startDate']).format("MMM DD, YYYY"),
                    endDate: moment.unix(analysis['endDate']).format("MMM DD, YYYY"),
                }
            ])

            setLoading(false)
        }).catch((error) => {
        });
    }

    const onAlertRemove = (shareCode) => {
        setCompareList(compareList.filter(c => {
            return c['shareCode'] !== shareCode
        }))
    }

    return (
        <Fragment>
            <div className={'tait-alert__card'}>
                <CardHeader
                    title='Compare'
                    tooltipId='tait-alert__cb-tooltip'
                    tooltipText='Compare'
                />
                <div className={'tait-alert__card-body'}>
                    <div>
                        <Row>
                            <Col xs={12}>
                                <div className="compare__div">
                                    {
                                        compareList.sort((a, b) => b['vws'] - a['vws']).map((c, index) => (
                                            <CompareCard 
                                                key={c['shareCode']}
                                                onAlertRemove={onAlertRemove}
                                                { ...c }
                                            />
                                        ))
                                    }
                                    <EmptyCompareCard
                                        onClick={toggleModalOpen}
                                        loading={loading}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <AlertPickerModal 
                isOpen={modalIsOpen}
                toggle={toggleModalOpen}
                onSelect={onAlertSelect}
                compareList={compareList}
            />
        </Fragment>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        userId: state['auth']['id'],
        compareList: state['compare']['compareList']
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getWidgetAnalysis: (userId, shareCode) => dispatch(getWidgetAnalysis(userId, shareCode)),
        setCompareList: (compareList) => dispatch(setCompareList(compareList))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Compare);