import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Numeral from 'numeral';
import moment from 'moment';
import {
    Row,
    Col,
    Image
} from 'reactstrap';

import CardHeader from '../Header/CardHeader';
import SourceCard from './SourceCard';
import { setSourceFilter } from '../../../../actions/alerts/data-point-search';

const SourceBreakdown = ({ analysis, sourceFilter, setSourceFilter }) => {
    const [sources, setSources] = useState([]);

    useEffect(() => {
        let tempSources = []

        Object.entries(analysis).forEach(([source, metadata]) => {
            tempSources.push({
                name: source,
                count: metadata['count'],
                sentiment: metadata['sentiment'],
                timeline: metadata['timeline']
            })
        })

        tempSources.sort((a, b) => b['count'] - a['count'])

        setSources(tempSources)
    }, [analysis]);

    const onSourceCardClick = (sourceName) => {
        if (sourceFilter === sourceName) {
            setSourceFilter(null)
        } else {
            setSourceFilter(sourceName)
        }   
    }

    return (
        <div className={'tait-alert__card'}>
            <CardHeader
                title='Channel Breakdown'
                tooltipId='tait-alert__cb-tooltip'
                tooltipText='Aggregates and evaluates volume and sentiment trends for a given channel. Both lines should be going up for a healthy trend which communicates engagement and positivity.'
            />
            <div className={'tait-alert__card-body'}>
                <div>
                    <Row>
                        <Col xs={12}>
                            <div className="source-breakdown">
                                {
                                    sources.map(s => (
                                        <SourceCard 
                                            key={s['name']}
                                            { ...s }
                                            onClick={onSourceCardClick}
                                        />
                                    ))
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        sourceFilter: state['dataPointSearch']['sourceFilter']
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSourceFilter: (sourceFilter) => dispatch(setSourceFilter(sourceFilter)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceBreakdown);