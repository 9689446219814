import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import adminReducer from '../reducers/admin';
import authReducer from '../reducers/auth';
import alertsReducer from '../reducers/alerts/alerts';
import alertsFiltersReducer from '../reducers/alerts/alerts-filters';
import onboardingReducer from '../reducers/onboarding/onboarding';
import dataPointSearchReducer from '../reducers/alerts/data-point-search';
import compareReducer from '../reducers/alerts/compare';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
    const store = createStore(
        combineReducers({
            admin: adminReducer,
            auth: authReducer,
            alerts: alertsReducer,
            alertsFilters: alertsFiltersReducer,
            onboarding: onboardingReducer,
            dataPointSearch: dataPointSearchReducer,
            compare: compareReducer
        }),
        composeEnhancers(applyMiddleware(thunk))
    );

    return store;
};

