import React, { Component } from 'react'
import Chart from "chart.js";

Chart.defaults.customHorizontalBar = Chart.defaults.horizontalBar;
Chart.defaults.customHorizontalBar.defaultFontFamily = "'ProximaNovaSoftW03-Regular', 'Montserrat', 'Helvetica', Arial, sans-serif";

export default class HorizontalBarChart extends Component {
    chartRef = React.createRef();
    
    componentDidMount() {
        const myChartRef = this.chartRef.current.getContext("2d");

        const { datasets, labels, options, mobile } = this.props;

        var customHorizontalBar = Chart.controllers.horizontalBar.extend({
            draw: function(ease) {
                Chart.controllers.horizontalBar.prototype.draw.call(this, ease);
            }
        });

        const afterDraw = function(chartInstance) {
            const ctx = chartInstance.chart.ctx;
            ctx.font = Chart.helpers.fontString(14, 'bold', Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
    
            chartInstance.data.datasets.forEach((dataset, idx) => {
                const entityType = chartInstance.config.data.datasets[idx]['entityType'];

                if (entityType !== undefined) {
                    for (var i = 0; i < dataset.data.length; i++) {
                        if (dataset.data[i] !== 0) {
                            const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;

                            let entType_x = model.x;

                            if (dataset.data[i] < 0) {
                                entType_x = entType_x - 5 - (model.height * 3)
                            } else {
                                entType_x += 5
                            }

                            if (entityType[i]) {
                                const entType = new Image;
                                entType.onload = function() {
                                    if (mobile) {
                                        ctx.drawImage(entType, entType_x, model.y - model.height, model.height, model.height);
                                    } else {
                                        ctx.drawImage(entType, entType_x, model.y - (model.height / 2), model.height, model.height);
                                    }
                                }
                                entType.src = `/images/entities/${entityType[i]}.webp`;
                            }
                        }
                    }
                }
            });
        }
        
        Chart.controllers.customHorizontalBar = customHorizontalBar;
        
        new Chart(myChartRef, {
            plugins: [{
                afterDraw: afterDraw
            }],
            type: 'customHorizontalBar',
            data: {
                labels: labels,
                datasets: datasets
            },
            options: options
        });
    }
    render() {
        return (
            <canvas
                id="customHorizontalBar"
                ref={this.chartRef}
                height={400}
            />
        )
    }
}
