import React, { useState } from 'react';
import {
	Row,
	Col,
	Button
} from 'reactstrap'

import MappingRow from './MappingRow'

const Mapping = ({ mailchimp, campaigns, alerts, campaignTriggerSave }) => {
	const [mappings, setMappings] = useState([])

	const addMapping = () => {
		setMappings(prevMapping => [...prevMapping, 'test'])
	}

	const onCampaignTriggerSave = (values) => {
		campaignTriggerSave(values)
	}

	return (
		<Row>
			<Col xs={12}>
				<div>
					{
						mappings.map((m, idx) => (
							<MappingRow 
								key={idx}
								id={idx}
								mailchimp={mailchimp}
								campaigns={campaigns}
								campaignTriggerSave={onCampaignTriggerSave}
								alerts={alerts}
							/>
						))
					}
					<div style={{display: 'flex', justifyContent: 'center'}}>
						<Button 
							className="mailchimp__add-campaign-button"
							onClick={addMapping}
	                        onMouseDown={e => e.preventDefault()}
	                    >
	                        Add Campaign
                    	</Button>
                    </div>
				</div>
			</Col>
		</Row>
	)
}


export default Mapping