import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import ReactWordcloud from 'react-wordcloud';
import {
    Row,
    Col,
    Button
} from 'reactstrap'

import { setSearchFilter, setDateFilter, setSentimentFilter, setSourceFilter } from '../../../../actions/alerts/data-point-search';

import CardHeader from '../Header/CardHeader';

const Hashtags = ({ hashtags, setSearchFilter, setDateFilter, setSentimentFilter, setSourceFilter }) => {
    let [words, setWords] = useState([])

    useEffect(() => {
        if (hashtags) {
            setWords(hashtags.map(ht => (
                {
                    text: ht['hashtag'],
                    value: ht['score'].toFixed(2)
                }
            )))
        }
    }, [hashtags])

    const onWordClick = (word) => {
        setDateFilter(null)
        setSentimentFilter(null)
        setSourceFilter(null)
        setSearchFilter(word.text)
        window.scrollTo(0, 0);
    }

    const callbacks = {
        onWordClick: onWordClick
    }

    const options = {
        rotations: 0,
        fontSizes: [20, 70],
        enableTooltip: false
    };

    if (words.length > 0) {
        return (
            <div className={'tait-alert__card'} style={{flex: 1}}>
                <CardHeader
                    title='Top Hashtags'
                    tooltipId={`tait-alert__nev-tooltip`}
                    tooltipText='Top relevant hashtags'
                />
                <div className={'tait-alert__card-body'}>
                    <Row>
                        <Col xs={12}>
                            <ReactWordcloud 
                                callbacks={callbacks}
                                words={words}
                                options={options}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSearchFilter: (searchFilter) => dispatch(setSearchFilter(searchFilter)),
        setDateFilter: (startDate, endDate) => dispatch(setDateFilter(startDate, endDate)),
        setSentimentFilter: (sentimentFilter) => dispatch(setSentimentFilter(sentimentFilter)),
        setSourceFilter: (sourceFilter) => dispatch(setSourceFilter(sourceFilter))
    };
};

export default connect(null, mapDispatchToProps)(Hashtags);