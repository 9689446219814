const authReducerDefaultState = {
    isAuthenticated: false,
    portal: null,
    id: null,
    token: null,
    email: null,
    givenName: null,
    familyName: null,
    apiKey: null
};

export default (state = authReducerDefaultState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                isAuthenticated: true,
                portal: action.portal,
                id: action.id,
                token: action.token,
                email: action.email,
                givenName: action.givenName,
                familyName: action.familyName,
                apiKey: action.apiKey
            };
        case 'LOGOUT':
            return {
                isAuthenticated: false,
                portal: null,
                id: null,
                token: null,
                email: null,
                givenName: null,
                familyName: null,
                apiKey: null
            };
        default:
            return state;
    }
};
