import React, { useEffect, useState, Fragment } from 'react';
import StackedHorizontalBarChart from '../Graphs/StackedHorizontalBarChart';
import { connect } from 'react-redux';
import Numeral from 'numeral';
import {
    Row,
    Col,
    Button
} from 'reactstrap';
import { Sparklines, SparklinesLine } from 'react-sparklines';

const CompareCard = ({ theme, asset, shareCode, nev, dataPointCount, engagement, sentiment, sentimentTimeline, startDate, endDate, onAlertRemove }) => {
    const [nss, setNSS] = useState(null)
    const [sent, setSent] = useState(null)
    const [countTimeline, setCountTimeline] = useState(null)
    const [vwsTimeline, setVWSTimeline] = useState(null)
    const [socialEngagement, setSocialEngagement] = useState(engagement)

    useEffect(() => {
        if (nev) {
            const nevTotal = nev['promoters'] + nev['detractors'] + nev['passives'];
            const promotersPerc = Math.round((nev['promoters'] / nevTotal) * 100);
            const detractorsPerc = Math.round((nev['detractors'] / nevTotal) * 100);

            setNSS({
                promotersPerc: promotersPerc,
                detractorsPerc: detractorsPerc,
                nss: promotersPerc - detractorsPerc
            })
        }

        if (sentiment) {
            let horizontalBarChart = {
                datasets: [{
                    data: [Math.round(sentiment['NEG'] * 100)],
                    backgroundColor: "#E94F76",
                    // hoverBackgroundColor: "#E94F76"
                },{
                    data: [Math.round(sentiment['NEUT'] * 100)],
                    backgroundColor: "#7C65D5",
                    // hoverBackgroundColor: "#7C65D5"
                },{
                    data: [Math.round(sentiment['POS'] * 100)],
                    backgroundColor: "#3ABAE2",
                    // hoverBackgroundColor: "#3ABAE2"
                }], 
                labels: [''],
                options: {
                    events: [],
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: false,
                    legend: {
                        display: false
                    },
                    title: {
                        display: false,
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                display: false
                            },
                            scaleLabel:{
                                display:false
                            },
                            gridLines: {
                                display: false
                            }, 
                            stacked: true
                        }],
                        yAxes: [{
                            gridLines: {
                                display:false,
                                color: "#fff",
                                zeroLineColor: "#fff",
                                zeroLineWidth: 0
                            },
                            ticks: {
                                display: false
                            },
                            scaleLabel:{
                                display:false
                            },
                            stacked: true
                        }]
                    }
                }
            };
            setSent(horizontalBarChart)
        }

        if (sentimentTimeline && sentimentTimeline['data']) {
            let tempCountTimeline = []
            let tempVWSTimeline = []

            sentimentTimeline['data'].forEach(tl => {
                if (tl['vws']) {
                    tempVWSTimeline.push(tl['vws'])
                }
                tempCountTimeline.push(Object.values(tl['count']).reduce((count, a) => count + a, 0))
            })

            setVWSTimeline(tempVWSTimeline)
            setCountTimeline(tempCountTimeline)
        }
        
    }, [])

    const onRemove = () => {
        onAlertRemove(shareCode)
    }

    return (
        <div className='compare__card'>
            <div className="compare-card__header">
                <div className="compare-card__title">
                    <span>
                        <b>{theme}</b>
                    </span>
                    <span>
                        <b>{asset}</b>
                    </span>
                    <span style={{ fontSize: '1rem'}}>
                        {startDate} - {endDate}
                    </span>
                </div>
                <div>
                    <img className="close" src="/images/compare/close.webp" onClick={() => onRemove()}/>
                </div>
            </div>
            <div className='compare-card__info'>
                <div>
                    <div className="description">
                        <div className="description-group">
                            <div className="vertical-center">
                                <img
                                    src='/images/description/volume.webp'
                                />
                            </div>
                            <div className="description-subgroup data-points">
                                <span className="description-subgroup__count">
                                    { Numeral(dataPointCount).format("0,0") }
                                </span>
                                <span className="description-subgroup__desc">
                                    Data Points
                                </span>
                            </div> 
                        </div>
                        {
                            engagement &&
                                <Fragment>
                                    <div className="description-group">
                                        <div className="vertical-center">
                                            <img
                                                src='/images/description/people.webp'
                                            />
                                        </div>
                                        <div className="description-subgroup community">
                                            <span className="description-subgroup__count">
                                                { Numeral(engagement['total_unique_users']).format("0,0") }
                                            </span>
                                            <span className="description-subgroup__desc">
                                                Community Size
                                            </span>
                                        </div> 
                                    </div>
                                    <div className="description-group">
                                        <div className="vertical-center">
                                            <img
                                                src='/images/description/engagement.webp'
                                            />
                                        </div>
                                        <div className="description-subgroup engagement">
                                            <span className="description-subgroup__count">
                                                { Numeral(engagement['total_engagement']).format("0,0") }
                                            </span>
                                            <span className="description-subgroup__desc">
                                                Total Engagement
                                            </span>
                                        </div> 
                                    </div>
                                </Fragment>
                        }
                    </div>
                </div>
                {
                    nss &&
                        <div className='nss'>
                            <div className='nss-group'>
                                <div className='nss-group__div'>
                                    <div>
                                        <img 
                                            src="https://ucarecdn.com/46923d09-e9a9-47f1-bca1-08a89f3d6d86/happiness.png"
                                        />
                                    </div>
                                    <span>Promoters</span>
                                </div>
                                <span className='nss-group__score'>
                                    {nss['promotersPerc']}%
                                </span>
                            </div>
                            <div className='tait-alert__nev-score'>
                                <span>-</span>
                            </div>
                            <div className='nss-group'>
                                <div className='nss-group__div'>
                                    <div>
                                        <img
                                            src="https://ucarecdn.com/ddd1a94a-a2f3-4a54-b7ae-7f24f1398cf0/sad.png"
                                        />
                                    </div>
                                    <span>Detractors</span>
                                </div>
                                <span className='nss-group__score'>
                                    {nss['detractorsPerc']}%
                                </span>
                            </div>
                            <div className='tait-alert__nev-score'>
                                <span>=</span>
                            </div>
                            <div className='nss-group'>
                                {
                                    nss['nss'] > 0 ? 
                                        <div className='nss-group__div'>
                                            <div>
                                                <img
                                                    src="https://ucarecdn.com/46923d09-e9a9-47f1-bca1-08a89f3d6d86/happiness.png"
                                                />
                                            </div>
                                            <span>NSS</span>
                                        </div>
                                        :
                                        <div className='nss-group__div'>
                                            <div>
                                                <img 
                                                    src="https://ucarecdn.com/ddd1a94a-a2f3-4a54-b7ae-7f24f1398cf0/sad.png"
                                                />
                                            </div>
                                            <span>NSS</span>
                                        </div>
                                }
                                <span className='nss-group__score'>
                                    {nss['nss']}%
                                </span>
                            </div>
                        </div>
                }
                {
                    sent &&
                        <div>
                            <StackedHorizontalBarChart 
                                key={Math.random()}
                                datasets={sent['datasets']}
                                labels={sent['labels']}
                                options={sent['options']}
                            />
                        </div>
                }
                <Row>
                    {
                        countTimeline && countTimeline.length > 0 &&
                            <Col xs={6}>
                                <div className="sparklines">
                                    <span>Volume:</span>
                                    <Sparklines data={countTimeline}>
                                        <SparklinesLine color="blue" />
                                    </Sparklines>
                                </div>
                            </Col>
                    }
                    {
                        vwsTimeline && vwsTimeline.length > 0 &&
                            <Col xs={6}>
                                <div className="sparklines">
                                    <span>Senti Trend:</span>
                                    <Sparklines data={vwsTimeline}>
                                        <SparklinesLine color="blue" />
                                    </Sparklines>
                                </div>
                            </Col>
                    }
                </Row>
            </div>

            {/*

            <div className="source-card__info-section">
                <div className="vertical-center">
                    <span><b>Volume:</b> {Numeral(count).format("0,0")}</span>
                </div>
                <div className="sparklines">
                    <Sparklines data={countTimeline}>
                        <SparklinesLine color="blue" />
                    </Sparklines>
                </div>
            </div>
            <div className="source-card__info-section">
                <div className="vertical-center">
                    <span><b>Senti Trend:</b></span>
                </div>
                <div className="sparklines">
                    <Sparklines data={sentimentTimeline}>
                        <SparklinesLine color="blue" />
                    </Sparklines>
                </div>
            </div>*/}
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
};

export default connect(mapStateToProps)(CompareCard);