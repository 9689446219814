import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import {
    Row,
    Col
} from 'reactstrap';

import SearchFilter from './Filters/SearchFilter';
import ContextFilter from './Filters/ContextFilter';

const Filters = ({ searchFilter, contexts }) => {
    return (
        <Fragment>
            <Row>
                <Col xs={12}>
                    <div className="dps-filters">
                        <SearchFilter
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <ContextFilter
                        contexts={contexts}
                    />
                </Col>
            </Row>
        </Fragment>
    )
}


const mapStateToProps = (state, ownProps) => {
    return {
        searchFilter: state['dataPointSearch']['searchFilter']
    };
};

export default connect(mapStateToProps)(Filters);
