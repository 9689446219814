import React from 'react';

import { connect } from 'react-redux';

export class LoadingPage extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={`loader ${this.props.mini && 'mini'} ${this.props.full && 'full'}`}>
                <img 
                    className="loader__image" 
                    src={this.props.colorTheme == 'light' ? "/images/loader-light.gif" : "/images/loader-dark.gif"} 
                />
            </div>
        )
    }
};

// const mapStateToProps = (state, ownProps) => {
//     console.log(state);
//     return {
//         colorTheme: state['admin']['colorTheme']
//     }
// }

// export default connect(mapStateToProps)(LoadingPage);

export default LoadingPage;