import React from 'react';
import {
    Row,
    Col
} from "reactstrap";

export class SubscriptionCard extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={this.props.selected ? 'subscription__card selected' : 'subscription__card'} onClick={() => this.props.onClick(this.props.planPaypalId)}>
                <div>
                    <div className={'subscription__card-header'}>
                        <div className={'subscription__card-header-title'}>
                            <span>{this.props.name}</span>
                        </div>
                    </div>
                    <div className={'subscription__card-body'}>
                        {
                            this.props.description1 &&
                                <Row className='subscription__card-body-section'>
                                    <Col xs={12}>
                                        <span>
                                            {this.props.description1}
                                        </span>
                                    </Col>
                                </Row>
                        }
                        {
                            this.props.description2 &&
                                <Row className='subscription__card-body-section'>
                                    <Col xs={12}>
                                        <span>
                                            {this.props.description2}
                                        </span>
                                    </Col>
                                </Row>
                        }
                        {
                            this.props.price &&
                                <Row className='subscription__card-body-section price'>
                                    <Col xs={12}>
                                        <div>
                                            <span className='subscription__card-body-price'>
                                                ${this.props.price}
                                            </span>
                                            /month
                                        </div>
                                    </Col>
                                </Row>
                        }
                        {
                            this.props.features &&
                                this.props.features.map((feature) => (
                                    <Row key={feature} className='subscription__card-body-section'>
                                        <Col xs={12}>
                                            <span>
                                                {feature}
                                            </span>
                                        </Col>
                                    </Row>
                                ))
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default SubscriptionCard;