import React, {useEffect, useState} from 'react';
import HorizontalBarChart from '../Graphs/HorizontalBarChart';

import {
    Row,
    Col
} from 'reactstrap'

import CardHeader from '../Header/CardHeader';

const Entities = ({ entities }) => {
    let [chartOptions, setChartOptions] = useState({
        // events: ['click'],
        events: [],
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        legend: {
            display: false
        },
        title: {
            display: false,
        },
        scales: {
            yAxes: [{
                ticks: {
                    fontSize: 15,
                    fontColor: '#150637'
                }
            }],
            xAxes: [{
                gridLines: {
                    display: false
                },
                ticks: {
                    beginAtZero: true,
                    fontSize: 15,
                    fontColor: '#150637'
                }
            }]
        },
        plugins: {
            legend: {
                onClick: onClickHandler
            }
        }
    })
    let [chartLabels, setChartLabels] = useState([])
    let [chartData, setChartData] = useState([])
    let [chartEntityTypes, setChartEntityTypes] = useState([])
    let [chartBackgroundColors, setChartBackgroundColors] = useState([])
    let [chartBorderColors, setChartBorderColors] = useState([])

    const onClickHandler = () => {
        console.log('')
    }

    useEffect(() => {
        const tempEntities = entities.slice(0, 10);
        let labels = []
        let data = []
        let entityTypes = []
        let backgroundColors = []
        let borderColors = []

        tempEntities.forEach(ent => {
            labels.push(ent['entity'])
            data.push(ent['totalCount'])
            entityTypes.push(ent['entityType'])

            if (ent['sentiment'] == 'positive') {
                backgroundColors.push('#DFF7FF')
                borderColors.push('#3ABAE2')
            } else if (ent['sentiment'] == 'negative') {
                backgroundColors.push('#FFE3EB')
                borderColors.push('#E94F76')
            } else if (ent['sentiment'] == 'neutral') {
                backgroundColors.push('#EAE4FE')
                borderColors.push('#7C65D5')
            }
        })

        setChartLabels(labels)
        setChartData(data)
        setChartEntityTypes(entityTypes)
        setChartBackgroundColors(backgroundColors)
        setChartBorderColors(borderColors)
    }, [entities])

    return (
        <div className={'tait-alert__card'}>
            <CardHeader
                title='Top Entities'
                tooltipId={`tait-alert__entities-tooltip`}
                tooltipText='Top Entities'
            />
            <div className={'tait-alert__card-body'}>
                <Row>
                    <Col xs={12}>
                        <HorizontalBarChart 
                            key={Math.random()}
                            datasets={[{
                                data: chartData,
                                backgroundColor: chartBackgroundColors,
                                borderColor: chartBorderColors,
                                entityType: chartEntityTypes,
                                barThickness: 30,
                                borderWidth: 2,
                            }]}
                            labels={chartLabels}
                            options={chartOptions}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Entities;