import React from 'react';
import {
    Row,
    Col
} from "reactstrap";

export class Header extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Row>
                <Col xs={12}>
                    <span className='h1'>
                        Analysis Setup
                    </span>
                </Col>
            </Row>
        )
    }
}
  
export default Header;