import React from "react";

import { NavLink } from "react-router-dom";

import {
  Navbar,
  Nav,
  Container
} from "reactstrap";

import AccountSettings from './AccountSettings/AccountSettings';

export class AuthenticatedHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountSettings: {
        collapseOpen: false,
        dropdownOpen: false
      }
    };
  }
  navbarToggle = () => {
    const accountSettings = {...this.state.accountSettings};
    accountSettings.collapseOpen = !accountSettings.collapseOpen;

    this.setState(() => ({accountSettings}));
  }
  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
    this.refs.sidebarToggle.classList.toggle("toggled");
  }
  componentDidMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
      this.refs.sidebarToggle.classList.remove("toggled");
    }
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.path &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.refs.sidebarToggle.classList.toggle("toggled");
    }
  }
  accountSettingsDropdownToggle = (e) => {
    const accountSettings = {...this.state.accountSettings};
    accountSettings.dropdownOpen = !accountSettings.dropdownOpen;

    this.setState(() => ({accountSettings}));
  }
  activeRoute(route) {
    return this.props.path.split('/')[2] == route ? "active" : "";
  }
  changeDashboardRoute(route) {
    const pathSplit = this.props.path.split('/');
    return `/${pathSplit[1]}/${route}/${pathSplit[3]}`;
  }
  changeSocialMediaRoute(route) {
    const pathSplit = this.props.path.split('/');
    return `/${pathSplit[1]}/${route}`;
  }
  render() {
    let viewType = 'dashboard';

    return (
      <Navbar
        data-color={this.props.colorTheme}
        expand="lg"
        className="navbar-absolute fixed-top"
      >
        <Container fluid>
          <div className="header-nav__mobile">
            <div className="navbar-wrapper">
              <div className="navbar-toggle">
                <button
                  type="button"
                  ref="sidebarToggle"
                  className="navbar-toggler"
                  onClick={() => this.openSidebar()}
                  onMouseDown={e => e.preventDefault()}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
            </div>
            <AccountSettings 
              history={this.props.history}
              isOpen={this.state.accountSettings.dropdownOpen}
              toggle={this.accountSettingsDropdownToggle}
            />
          </div>
          <div className="header-nav__desktop">
            <AccountSettings 
              history={this.props.history}
              isOpen={this.state.accountSettings.dropdownOpen}
              toggle={this.accountSettingsDropdownToggle}
            />
          </div>
        </Container>
      </Navbar>
    );
  }
}

export default AuthenticatedHeader;