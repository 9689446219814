import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from "react-select";

const ThemeFilter = ({ alertsList, currentFilter, selected, onSelect }) => {
    const [dropdown, setDropdown] = useState([]);

    useEffect(() => {
        setDropdown(Object.keys(alertsList).sort().map(theme => ({
            value: theme,
            label: theme,
        })))
    }, [])

    return (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
            <div className="vertical-center">
                <span style={{ fontWeight: 'bold' }}>
                    Theme:
                </span>
            </div>
            <Select
                className="tait-alert__select-full"
                classNamePrefix="select"
                placeholder="Select Theme"
                value={ 
                    dropdown.find(d => 
                        d['value'] == selected)
                }
                onChange={onSelect}
                options={dropdown}
            />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        alertsList: state['alerts']['alertsList']
    };
};

export default connect(mapStateToProps)(ThemeFilter);