import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";

import Select from "react-select";

import { setSortFilter } from '../../../../../actions/alerts/data-point-search';


const SortFilter = ({ sortFilter, setSortFilter }) => {
	const dropdown = [
        {
            value: 'oldest',
            label: `Date (Oldest)`
        },
        {
            value: 'newest',
            label: `Date (Newest)`
        },
        {
            value: 'highestIntensity',
            label: 'Intensity (Highest)'
        },
        {
            value: 'lowestIntensity',
            label: 'Intensity (Lowest)'
        },
        {
            value: 'highestEngagement',
            label: 'Engagement (Highest)'
        },
        {
            value: 'lowestEngagement',
            label: 'Engagement (Lowest)'
        }
    ]

	const onChange = (e) => {
        setSortFilter(e.value)
	}

	return ( 
        <Select
            className="tait-alert__select-full"
            classNamePrefix="select"
            value={
                dropdown.find(e => 
                    e['value'] == sortFilter
                )
            }
            placeholder="Sort By"
            onChange={onChange}
            options={dropdown}
        />
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        sortFilter: state['dataPointSearch']['sortFilter']
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSortFilter: (sortFilter) => dispatch(setSortFilter(sortFilter)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SortFilter);