import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// import 'bootstrap';
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux'; 
import axios from 'axios';
import AppRouter, { history } from './routers/AppRouter';
import configureStore from './store/configureStore';
import { login, logout } from './actions/auth';
import { setColorTheme } from './actions/admin';
import LoadingPage from './components/Loading/LoadingPage';


import './styles/styles.scss';


const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  response => response,
  error => {
    const {status} = error.response;
    if (status === UNAUTHORIZED) {
      localStorage.removeItem('user');
      store.dispatch(logout());
      history.push('/');
      renderApp();
    }
    return Promise.reject(error.response);
 }
);

const store = configureStore();



// const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
// console.log(isMobile);

const jsx = (
  <Provider store={store}>
      <AppRouter />
  </Provider>
);

let hasRendered = false;
const renderApp = () => {
  if (!hasRendered) {
      ReactDOM.render(jsx, document.getElementById('root'));
      hasRendered = true;
  }
};

ReactDOM.render(<LoadingPage colorTheme='light'/>, document.getElementById('root'));

const currentUser = JSON.parse(localStorage.getItem('user'));
const colorTheme = localStorage.getItem('colorTheme');

if (colorTheme) {
  store.dispatch(setColorTheme(colorTheme));
} else {
  localStorage.setItem('colorTheme', 'light');
}

// renderApp();

if (currentUser) {
  store.dispatch(login(currentUser['portal'], currentUser['id'], currentUser['token'], 
    currentUser['email'], currentUser['givenName'], currentUser['familyName'], currentUser['apiKey']
  ))
  renderApp();
} else {
  renderApp();
};