import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import PrivateRoute from './PrivateRoute';

import Alerts from "../layouts/Alerts/Alerts";
import Login from "../layouts/Login/Login";
import Signup from "../layouts/Signup/Signup";
import Onboarding from "../layouts/Onboarding/Onboarding";
import Integrations from "../layouts/Integrations/Integrations";

export const history = createHistory();
import { connect } from 'react-redux';

class AppRouter extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Router history={history}>
                <Switch>
                    {
                        this.props.auth.isAuthenticated && 
                            <PrivateRoute path={'/alerts'} component={Alerts} exact/>
                    }
                    {
                        this.props.auth.isAuthenticated && 
                            <PrivateRoute path={'/analysis-setup'} component={Onboarding} exact/>
                    }
                    {
                        this.props.auth.isAuthenticated && 
                            <PrivateRoute path={'/integrations'} component={Integrations} exact/>
                    }
                    {   
                        this.props.auth.isAuthenticated ?
                            this.props.auth.planId ?
                                <Route path="/" exact component={(props) => (
                                    <Redirect to={{
                                        pathname: "/alerts"
                                    }} />
                                )}/> 
                                :
                                <Route path="/" exact component={(props) => (
                                    <Redirect to={{
                                        pathname: "/analysis-setup"
                                    }} />
                                )}/> 
                            :
                            <Route path="/" exact component={(props) => (
                                <Redirect to={{
                                    pathname: "/login"
                                }} />
                            )}/> 
                    }
                    {   
                        !this.props.auth.isAuthenticated &&
                            <Route path="/login" exact component={Login}/> 
                    }
                    {
                        !this.props.auth.isAuthenticated &&
                            <Route path="/signup" exact component={Signup}/>
                    }
                    <Route path="/verification/:code" exact component={(props) => (
                        <Redirect to={{
                            pathname: "/login",
                            state: { verification: true, code: props['match']['params']['code'] }
                        }} />
                    )}/>
                    <Route render={() => <Redirect to={"/"} />} />
                </Switch>
            </Router>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state['auth'],
    }
};

export default connect(mapStateToProps)(AppRouter);