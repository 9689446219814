import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { useField } from "formik";

import { ActionMeta, OnChangeValue } from 'react-select';

const AlertSelect = ({ name, options, type }) => {
    const [field, meta, { setValue }] = useField({ name });

    const handleChange = (
        newValue: OnChangeValue<Option, false>,
        actionMeta: ActionMeta<Option>
    ) => {
        if (newValue) setValue(newValue['value']);
        else setValue("");
    };

    return (
        <Select
            name={name}
            className="onboarding__dropdown"
            placeholder="Select Alert"
            onChange={handleChange}
            value={
                options.find(c => 
                    c['value'] == field['value'])}
            options={options}
        />
    )
}



export default AlertSelect