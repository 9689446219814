import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from "react-infinite-scroll-component";
import Numeral from 'numeral';
import {
    Row,
    Col
} from 'reactstrap';

import CardHeader from '../Header/CardHeader';
import SentimentFilter from './Filters/SentimentFilter';
import SortFilter from './Filters/SortFilter';
import DateFilter from './Filters/DateFilter';
import DataPoint from '../DataPoint/DataPoint';

const positiveThreshold = 0.30;
const negativeThreshold = -0.30;
const showCount = 50;

const DataPointSection = ({ dataPoints, sentimentFilter, sortFilter, dateFilter, sourceFilter }) => {
    const [filteredDataPoints, setFilteredDataPoints] = useState(dataPoints)
    const [currentLength, setCurrentLength] = useState(dataPoints.length > showCount ? showCount : dataPoints.length)
    const [hasMore, setHasMore] = useState(dataPoints.length > 0 ? true : false)
    const [counts, setCounts] = useState({ pos: 0, neg: 0, neut: 0, all: 0 })

    const fetchMoreData = () => {
        if (currentLength >= filteredDataPoints.length) {
            setHasMore(false)
        } else {
            setCurrentLength(currentLength + showCount > filteredDataPoints.length ? filteredDataPoints.length : currentLength + showCount)
        }
    }

    useEffect(() => {
        if (dataPoints) {
            let posCount = 0;
            let negCount = 0;
            let neutCount = 0;

            if (sentimentFilter) {
                if (sentimentFilter == 'pos') {
                    dataPoints = dataPoints.filter(dp => dp['sentiment'] >= positiveThreshold);
                } else if (sentimentFilter == 'neg') {
                    dataPoints = dataPoints.filter(dp => dp['sentiment'] <= negativeThreshold);
                } else if (sentimentFilter == 'neut') {
                    dataPoints = dataPoints.filter(dp => dp['sentiment'] < positiveThreshold && dp['sentiment'] > negativeThreshold);
                }
            }

            if (sourceFilter) {
                dataPoints = dataPoints.filter(dp => dp['source'] == sourceFilter)
            }

            if (dateFilter && dateFilter['startDate'] && dateFilter['endDate']) {
                dataPoints = dataPoints.filter(dp => dp['epoch_time'] >= dateFilter['startDate'] && dp['epoch_time'] <= dateFilter['endDate'])
            }

            if (sortFilter) {
                if (sortFilter == 'newest') {
                    dataPoints.sort((a, b) => b['epoch_time'] - a['epoch_time'])
                } else if (sortFilter == 'oldest') {
                    dataPoints.sort((a, b) => a['epoch_time'] - b['epoch_time'])
                } else  if (sortFilter == 'highestIntensity') {
                    dataPoints.sort((a, b) => Math.abs(b['sentiment']) - Math.abs(a['sentiment']))
                } else if (sortFilter == 'lowestIntensity') {
                    dataPoints.sort((a, b) => Math.abs(a['sentiment']) - Math.abs(b['sentiment']))
                } else if (sortFilter == 'highestEngagement') {
                    dataPoints.sort((a, b) => {
                        if (a['engagement'] && b['engagement']) {
                            const aEngagement = Object.values(a['engagement']).reduce((accumulator, val) => {
                                return accumulator + val
                            })
                            const bEngagement = Object.values(b['engagement']).reduce((accumulator, val) => {
                                return accumulator + val
                            })
                            return bEngagement - aEngagement
                        } else {
                            return false
                        }
                    })
                } else if (sortFilter == 'lowestEngagement') {
                    dataPoints.sort((a, b) => {
                        if (a['engagement'] && b['engagement']) {
                            const aEngagement = Object.values(a['engagement']).reduce((accumulator, val) => {
                                return accumulator + val
                            })
                            const bEngagement = Object.values(b['engagement']).reduce((accumulator, val) => {
                                return accumulator + val
                            })
                            return aEngagement - bEngagement
                        } else {
                            return false
                        }
                    })
                }
            }

            dataPoints.forEach(dp => {
                if (dp['sentiment'] >= positiveThreshold) posCount += 1;
                else if (dp['sentiment'] <= negativeThreshold) negCount += 1;
                else neutCount += 1;
            })

            setFilteredDataPoints([...dataPoints])
            setCurrentLength(dataPoints.length > showCount ? showCount : dataPoints.length)
            setCounts({
                pos: posCount, neg: negCount, neut: neutCount, all: dataPoints.length
            })
            setHasMore(dataPoints.length > 0 ? true : false)
        }
    }, [dataPoints, sortFilter, sentimentFilter, dateFilter, sourceFilter])

    return (
        <div className='tait-alert__card'>
            <CardHeader
                title={`Data Points (${Numeral(currentLength).format("0,0")} / ${Numeral(filteredDataPoints.length).format("0,0")})`}
                tooltipId={null}
                tooltipText='You can search for your own terms, hashtags, products or any other context. talkAItive shows you the reach, sentiment & emotional profile along with actual data points related to your search term. This allows you to discover contexts which are important and relevant to your public perception or strategy.'
            />
            <div className='tait-alert__card-body' style={{ flexGrow: 1 }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    <div className='data-point-section__filters'>
                        <SentimentFilter 
                            counts={counts}
                        />
                        <SortFilter />
                    </div>
                    {/*<DateFilter />*/}
                </div>
                <div id="data-point-section" className="data-point-section">
                    <InfiniteScroll
                        className="dps-infinite-scroll"
                        dataLength={currentLength}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        scrollableTarget="data-point-section"
                        loader={<h4>Loading...</h4>}
                    >
                        {
                            filteredDataPoints.slice(0, currentLength).map(dp => (
                                <DataPoint 
                                    key={dp['id']}
                                    id={dp['id']}
                                    engagement={dp['engagement']}
                                    epochTime={dp['epoch_time']}
                                    metadata={dp['metadata']}
                                    source={dp['source']}
                                    sentiment={dp['sentiment']}
                                    text={dp['text']}
                                    userLocation={dp['user_location']}
                                    userName={dp['user_name']}
                                />
                            ))
                        }
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        dataPoints: state['dataPointSearch']['dataPoints'],
        sortFilter: state['dataPointSearch']['sortFilter'],
        sentimentFilter: state['dataPointSearch']['sentimentFilter'],
        dateFilter: state['dataPointSearch']['dateFilter'],
        sourceFilter: state['dataPointSearch']['sourceFilter'],
    };
};

export default connect(mapStateToProps)(DataPointSection);
