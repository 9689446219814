const onboardingReducerDefaultState = {
    config: [],
    themeAssets: {},
    runtimes: [],
    planName: null,
    planNumSearchterms: 0,
    upeId: null,
    lastPaymentTime: null,
    nextPaymentTime: null
};

export default (state = onboardingReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_ONBOARDING':
            return {
                ...state,
                config: action.config,
                themeAssets: action.themeAssets,
                runtimes: action.runtimes,
                planName: action.planName,
                planNumSearchterms: action.planNumSearchterms,
                upeId: action.upeId,
                lastPaymentTime: action.lastPaymentTime,
                nextPaymentTime: action.nextPaymentTime
            };
        default:
            return state;
    }
};
