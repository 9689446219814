import React from 'react';
import {
    Button
} from 'reactstrap'

export class UserCard extends React.Component {
    constructor(props) {
        super(props);
    }
    openUserLink = (userLink) => {
        window.open(userLink);
    }
    render() {
        let sourceUrl;
        let userLink;
        let profileImageUrl;
        let name;
        let screenName;

        if (this.props['metadata']) {
            if ('profile_image_url' in this.props['metadata']) {
                profileImageUrl = this.props['metadata']['profile_image_url']
            } else {
                profileImageUrl = 'https://ucarecdn.com/cea023f8-a13d-446f-9c4c-fc68a379372f/'
            }

            if (this.props.source == 'Twitter') {
                sourceUrl = 'https://ucarecdn.com/31d199e4-f723-4188-9a40-71bbaa405c6f/';
                userLink = this.props.userLink
                name = this.props['metadata']['name']
                screenName = '@' + this.props['metadata']['screen_name']
            } else if (this.props.source == 'Reddit') {
                sourceUrl = 'https://ucarecdn.com/cf528e25-1598-409a-b5af-fd6706099076/';
                userLink = this.props.userLink
                // screenName = this.props['metadata']['screen_name'].split('_').slice(0, -1).join('_')
                screenName = this.props['metadata']['name']
            } else if (this.props.source == 'Facebook') {
                sourceUrl = 'https://ucarecdn.com/6d44a863-0e9f-4349-88aa-91d149d5bc4d/';
                userLink = null;
                screenName = this.props['metadata']['name']
            } else if (this.props.source == 'Instagram') {
                sourceUrl = 'https://ucarecdn.com/f67a7d0c-21db-4faa-9ef1-86e4e0f111ee/';
                userLink = null;
                screenName = this.props['metadata']['name']
            } else if (this.props.source == 'Truth Social') {
                sourceUrl = 'https://ucarecdn.com/29610c89-c385-456c-9e84-522d69fcdc5b/';
                userLink = null;
                screenName = this.props['metadata']['name']
            } else {
                sourceUrl = 'https://ucarecdn.com/1b7a2fed-0ed2-439e-bc56-c704dfb1034a/';
                userLink = null;
                name = this.props['metadata']['name']
                screenName = this.props['metadata']['screen_name']
            }
        } else {
            profileImageUrl = 'https://ucarecdn.com/cea023f8-a13d-446f-9c4c-fc68a379372f/'

            if (this.props['userLink'].includes("reddit.com")) {
                sourceUrl = 'https://ucarecdn.com/cf528e25-1598-409a-b5af-fd6706099076/';
                userLink = this.props.userLink
                name = this.props['userName']
            } else if (this.props['userLink'].includes("twitter.com")) {
                sourceUrl = 'https://ucarecdn.com/31d199e4-f723-4188-9a40-71bbaa405c6f/';
                userLink = this.props.userLink
                name = this.props['userName']
            } else {
                sourceUrl = 'https://ucarecdn.com/1b7a2fed-0ed2-439e-bc56-c704dfb1034a/';
                userLink = null
                name = this.props['userName']
            }
            
            screenName = null
        }

        let sentiment;

        if (this.props['averageSentiment'] >= 0.3) sentiment = 'positive'
        else if (this.props['averageSentiment'] <= -0.3) sentiment = 'negative'
        else sentiment = 'neutral'

        return (

            <div 
                className={`user-card ${sentiment}`}
                onClick={this.props.modalToggle}
            >
                <div className="user-card__user">
                    <div className="user-card__user-profile-image-div">
                        <img
                            className="user-card__user-profile-image"
                            src={profileImageUrl}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="https://ucarecdn.com/cea023f8-a13d-446f-9c4c-fc68a379372f/";
                            }}
                        />
                    </div>
                    <div className="user-card__user-info">
                        <span className="user-card__user-info-name">
                            {
                                name ?
                                    name
                                    :
                                    screenName
                            }
                        </span>
                        {
                            name && 
                                <span className="user-card__user-info-screen-name">
                                    {screenName}
                                </span>
                        }
                    </div>
                </div>
                <div className='show-for-mobile'>
                    <hr/>
                </div>
                <div className='show-for-mobile user-card__mobile'>
                    <div>
                        <div className="user-card__score">
                            <span className="user-card__score-title">Score:</span>
                            <span className="user-card__score-number">{this.props['vws'].toFixed(2)}</span>
                        </div>
                        <div className="user-card__count">
                            <span className="user-card__count-title">Count:</span>
                            <span className="user-card__count-number">{this.props['count']}</span>
                        </div>
                    </div>
                    <div className="user-card__actions">
                        {
                            <div className="user-card__actions-button-div">
                                <Button
                                    id={`${this.props.dataKey}-source`}
                                    className="user-card__actions-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (userLink) this.openUserLink(userLink)}
                                    }
                                    onMouseDown={e => e.preventDefault()}
                                >
                                    <img src={sourceUrl}/>
                                </Button>
                            </div>
                            
                        }
                    </div>
                </div>
                <div className="user-card__data">
                    <div>
                        <div className="user-card__score">
                            <span className="user-card__score-title">Score:</span>
                            <span className="user-card__score-number">{this.props['vws'].toFixed(2)}</span>
                        </div>
                        <div className="user-card__count">
                            <span className="user-card__count-title">Count:</span>
                            <span className="user-card__count-number">{this.props['count']}</span>
                        </div>
                    </div>
                    <div className="user-card__actions">
                        {
                            <div className="user-card__actions-button-div">
                                <Button
                                    className="user-card__actions-button"
                                    title="Click to follow user"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (userLink) this.openUserLink(userLink)}
                                    }
                                    onMouseDown={e => e.preventDefault()}
                                >
                                    <img src={sourceUrl}/>
                                </Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };
}

export default UserCard;