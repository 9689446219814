import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
    Row,
    Col,
    Button
} from "reactstrap";

const History = ({ campaigns, history }) => {
	const [campaignIdMapping, setCampaignIdMapping] = useState({})

	useEffect(() => {
        const mapping = {}

        if (campaigns) {
        	campaigns.forEach(c => {
        		mapping[c['id']] = c['title']
        	})
        }

        setCampaignIdMapping(mapping)
    }, []);

	return (
		<div className="content-row mailchimp__history">
			{
				history && history.map(h => (
					<Row key={h['id']} className="mailchimp__history-row">
						<Col xs={12} md={3} className="mailchimp__history-cell">
							<span>
								<b>Campaign:</b> {campaignIdMapping[h['campaign_id']]}
							</span>
							<span>
								<b>Theme:</b> {h['theme_name']}
							</span>
							<span>
								<b>Asset:</b> {h['asset_name']}
							</span>
							<span>
								<b>Search Term:</b> {h['searchterm']}
							</span>
						</Col>
						<Col xs={12} md={3} className="mailchimp__history-cell">
							<span>
								<b>Created At:</b> {moment(h['created_at']).format("dddd, MMMM Do YYYY, h:mm:ss a")}
							</span>
						</Col>
						<Col xs={12} md={3} className="mailchimp__history-cell">
							<span>
								<b>Triggers:</b> 
							</span>
							{
								h['triggers'].map(t => (
									<span key={t}>
										{t}
									</span>
								))
							}
							<span>
								<b>Match Any Word:</b> {h['match_any_word'] ? 'True' : 'False'}
							</span>
						</Col>
						<Col xs={12} md={3} className="mailchimp__history-cell">
							<span>
								<b>Status:</b> {h['status']}
							</span>
							{
								h['status'] == 'Triggered' &&
									<div style={{display: 'flex', flexDirection: 'column'}}>
										<span>
											<b>Triggered At:</b> {moment(h['triggered_at']).local().format("dddd, MMMM Do YYYY, h:mm:ss a")}
										</span>
										<span>
											<b>Triggered On:</b> {h['triggered_on']}
										</span>
									</div>
							}
						</Col>
					</Row>
				))
			}
		</div>
	)
}

export default History;