import React from "react";
import { useField } from "formik";

function Input({ name }) {
  	const [field] = useField({ name, type: "text" });
  	
  	return (
  		<input 
  			id={name} 
  			className='onboarding__text-input'
  			name={name} 
  			type="text" 
  			{...field} 
  		/>
  	);
}

export default React.memo(Input);
