import axios from 'axios';

export const startSignUp = (portal, id, email, accessToken, givenName, familyName, password) => {
    return (dispatch) => {
        return axios.post(`${process.env.SERVER_URL}/signup`, {
            portal: portal,
            id: id, 
            email: email, 
            accessToken: accessToken, 
            givenName: givenName, 
            familyName: familyName,
            password: password
        }).then((response) =>  {
            return response['status'];
        }).catch((error) => {
            console.log(error);
        });
    };
};

export const login = (
    portal, id, token, email, givenName, familyName, apiKey, integrations
) => ({
    type: 'LOGIN',
    portal, 
    id,
    token,
    email,
    givenName,
    familyName,
    apiKey,
    integrations
});

export const logout = () => ({
    type: 'LOGOUT'
});

export const sendVerificationEmail = (email) => {
    return (dispatch) => {
        return axios.post(`${process.env.SERVER_URL}/auth/sendVerificationEmail`,
            {
                email: email
            }
        ).then((response) => {
        }).catch((error) => {
        });
    }
}

export const validateCode = (code) => {
    return (dispatch) => {
        return axios.post(`${process.env.SERVER_URL}/auth/validateCode`,
            {
                code: code
            }
        ).then((response) => {
            return response['status'];
        }).catch((error) => {
            return error['status'];
        });
    } 
}