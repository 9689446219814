import React from 'react';
import PieChart from '../Graphs/PieChart';
import {
    Row,
    Col
} from 'reactstrap'

import CardHeader from '../Header/CardHeader';

const sourceColorMapping = {
    'Twitter': '#1DA1F2', //carolina blue
    'Reddit': '#FF5700', //orange pantone
    'Chewy': '#033F63', //indigo
    'Influenster': '#C5A48A', //tumbleweed
    'Google Reviews': '#8F3985', // plum
    'Facebook': '#4267B2',
    'Instagram': '#FCAF45',
    'YouTube': '#DF0000',
    'TikTok': '#00DB95',
    'Truth Social': '#5447ef'
}

export class Volume extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false
        };
    }
    toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }
    render() {
        const sourceLabels = [];
        const sourceColors = [];
        const sourceData = [];
        let maxSourceValue = 0;
        let maxSourceLabel = null;
        let maxSourceColor = null;

        let sourceList = this.props.analysis.sort((a, b) => b['countPerc'] - a['countPerc']);

        let countPercTotal = 0;

        sourceList.forEach(source => {
            if (source['countPerc'] >= 1) {
                sourceLabels.push(source['source']);
                sourceColors.push(sourceColorMapping[source['source']]);
                sourceData.push(source['countPerc'])

                countPercTotal += source['countPerc']

                if (source['countPerc'] > maxSourceValue) {
                    maxSourceValue = source['countPerc'];
                    maxSourceLabel = source['source'];
                    maxSourceColor = sourceColorMapping[source['source']];
                }
            }
        })

        if (countPercTotal !== 100) {
            maxSourceValue += (100 - countPercTotal)
        }

        let sourceChart = {
            data: {
                labels: sourceLabels,
                datasets: [{
                    data: sourceData,
                    backgroundColor: sourceColors,
                    hoverBackgroundColor: sourceColors,
                    borderColor: 'white',
                    borderWidth: 2
                }],
                text1: `${maxSourceValue}%`,
                text1Color: maxSourceColor,
                text2: maxSourceLabel 
            },
            options: {
                events: [],
                animation: {
                    duration: 0
                },
                responsive: true,
                cutoutPercentage: 90,
                legend: {
                    display: false
                }
            }
        };

        return (
            <div className={'tait-alert__card'}>
                <CardHeader
                    title='Volume'
                    tooltipId={`tait-alert__volume-tooltip-${this.props.device}`}
                    tooltipText='Volume Overview'
                />
                <div className={'tait-alert__card-body'}>
                    <Row>
                        <Col xs={12}>
                            <Row style={{justifyContent: 'center'}}>
                                <div className={'tait-alert__volume-div'}>
                                    <PieChart
                                        key={Math.random()}
                                        data={sourceChart.data}
                                        options={sourceChart.options}
                                    />
                                    <div className={'tait-alert__volume-legend'}>
                                        {
                                            sourceList.map((source, index) => {
                                                if (index != 0 && source['countPerc'] >= 1) {
                                                    return (
                                                        <div key={source['source']}>
                                                            <span className='perc' style={{color: sourceColors[index]}}>
                                                                {source['countPerc']}%
                                                            </span>
                                                            <span className='description'>
                                                                {source['source']}
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    };
}

export default Volume;