import React from "react";
import { connect } from 'react-redux';
import uuid from 'react-native-uuid';
import axios from 'axios';

import {
    Row,
    Col,
    Button
} from 'reactstrap'

import LoadingPage from '../../components/Loading/LoadingPage';
import Header from '../../components/Onboarding/Header/Header';
import Description from '../../components/Onboarding/Description/Description';
import SearchtermSetup from '../../components/Onboarding/SearchtermSetup';
import DeleteConfirmation from '../../components/Onboarding/DeleteConfirmation';

import { startSetOnboarding, startSetSaveOnboarding } from '../../actions/onboarding/onboarding';

export class OnboardingView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            submitLoading: false,
            errorStatus: null,
            successStatus: null,
            deleteConfirmationModal: {
                open: false,
                activeConfig: null
            },
            config: [],
            savedConfig: []
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);

        this.props.startSetOnboarding(this.props.auth.id).then(onboarding => {
            const filteredOnboarding = onboarding['config'].filter((c) => {
                if (c['theme'] != '' && c['asset'] != '' && c['searchTerm'] != ''){
                    return true
                }
            })

            filteredOnboarding.forEach((on, idx) => {
                filteredOnboarding[idx]['uuid'] = uuid.v4()
                filteredOnboarding[idx]['saved'] = true
            })

            this.setState({
                config: [...filteredOnboarding],
                savedConfig: [...filteredOnboarding]
            })
            
            this.toggleLoading(false);
        })
    }
    toggleLoading = (status) => {
        this.setState({
            loading: status
        })
    }
    toggleSubmitLoading = (status) => {
        this.setState({
            submitLoading: status
        })
    }
    modalToggle = () => {
        this.setState(prevState => ({
            deleteConfirmationModal: {
                ...prevState.deleteConfirmationModal,
                open: !prevState.deleteConfirmationModal
            }
        }))
    }
    onAddAlert = () => {
        if (this.state.config.length >= this.props.onboarding['planNumSearchterms']) {
            this.setState({
                errorStatus: "Maximum number of alerts exceeded."
            })
        } else {
            this.setState(prevState => ({
                config: [...prevState.config, {
                    theme: '',
                    asset: '',
                    searchTerm: '',
                    sources: [],
                    uuid: uuid.v4(),
                    saved: false,
                    nevThreshold: 10,
                    posThreshold: 10,
                    negThreshold: 10,
                    overallThreshold: 10,
                    emailNotifications: 'all'
                }]
            }))
        }
    }
    saveConfig = async (config) => {
        let isNewAlert = true
        const tempConfig = this.state.config.map(tc => {
            if (tc['uuid'] == config['uuid']) { //edit config
                return config
            } else {
                return tc
            }
        });

        const tempSavedConfig = this.state.savedConfig.map(tc => {
            if (tc['uuid'] == config['uuid']) { //edit config
                isNewAlert = false
                return config
            } else {
                return tc
            }
        });

        if (isNewAlert) { //new alert
            if (this.state.config.length >= this.props.onboarding['planNumSearchterms']) {
                this.setState({
                    errorStatus: "Maximum number of alerts exceeded."
                })
            } else {
                tempSavedConfig.push(config)
            }
        }

        const onboarding = {
            config: tempSavedConfig.map(({uuid, ...c}) => c),
            upeId: this.props.onboarding['upeId']
        }

        const res = await this.props.startSetSaveOnboarding(onboarding)

        if (res['status'] == 200) {
            this.setState({
                config: tempConfig,
                savedConfig: tempSavedConfig
            })
        }

        return res
    }
    confirmDelete = async () => {
        const filteredConfig = this.state.savedConfig.filter(tc => tc['uuid'] != this.state.deleteConfirmationModal['activeConfig']['uuid'])
        
        const onboarding = {
            config: filteredConfig,
            upeId: this.props.onboarding['upeId']
        }

        const res = await this.props.startSetSaveOnboarding(onboarding)

        if (res['status'] == 200) {
            this.setState(prevState => ({
                config: prevState.config.filter(tc => tc['uuid'] != prevState.deleteConfirmationModal['activeConfig']['uuid']),
                savedConfig: filteredConfig,
                deleteConfirmationModal: {...prevState.deleteConfirmationModal, open: false},
                successStatus: "Your alert has been successfully deleted."
            }))
        } else {
            this.setState({
                deleteConfirmationModal: {...prevState.deleteConfirmationModal, open: false},
                successStatus: "An error has occured with your request."
            })
        }

        return res
    }
    deleteConfig = (config) => {
        this.setState(prevState => ({
            deleteConfirmationModal: {
                ...prevState.deleteConfirmationModal,
                open: true,
                activeConfig: config
            }
        }))
    }
    render() {
        if (this.state.loading === true) {
            return <LoadingPage colorTheme={this.props.colorTheme}/>;
        }

        return (
            <div className="content">
                <div className="content-row">
                    <Header />
                </div>
                <div className="content-row">
                    <Description 
                        planName={this.props.onboarding['planName']}
                        numSearchterms={this.state.config.length}
                        planNumSearchterms={this.props.onboarding['planNumSearchterms']}
                        runtimes={this.props.onboarding['runtimes']}
                        lastPaymentTime={this.props.onboarding['lastPaymentTime']}
                        nextPaymentTime={this.props.onboarding['nextPaymentTime']}
                    />
                </div>
                <div className="content-row" style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {
                        this.state.config.map((st, idx) => {
                            return (
                                <SearchtermSetup 
                                    id={st['uuid']}
                                    key={st['uuid']} 
                                    config={st}
                                    themeAssets={this.props.onboarding.themeAssets}
                                    num={idx}
                                    saveConfig={this.saveConfig}
                                    deleteConfig={this.deleteConfig}
                                />
                            )
                        })
                    }
                </div>
                <div className="content-row">
                    <Row>
                        <Col xs={12}>
                            <div className="onboarding__footer">
                                {
                                    this.state.errorStatus &&
                                        <div className="onboarding__error-status">
                                            <span>
                                                {this.state.errorStatus}
                                            </span>
                                        </div>
                                }
                                {
                                    this.state.successStatus &&
                                        <div className="onboarding__success-status">
                                            <span>
                                                {this.state.successStatus}
                                            </span>
                                        </div>
                                }
                                {
                                    this.state.submitLoading ?
                                        <LoadingPage colorTheme={this.props.colorTheme} mini/>
                                        :
                                        <div className="onboarding__submit">
                                            <Button 
                                                className="onboarding__add-button"
                                                onClick={this.onAddAlert}
                                                onMouseDown={e => e.preventDefault()}
                                            >
                                                Add Alert
                                            </Button>
                                        </div>
                                }
                            </div>
                        </Col>
                    </Row>
                    <DeleteConfirmation
                        isOpen={this.state.deleteConfirmationModal['open']}
                        toggle={this.modalToggle}
                        confirmDelete={this.confirmDelete}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        colorTheme: state['admin']['colorTheme'],
        auth: state['auth'],
        onboarding: state['onboarding']
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        startSetOnboarding: (userId) => dispatch(startSetOnboarding(userId)),
        startSetSaveOnboarding: (onboarding) => dispatch(startSetSaveOnboarding(onboarding))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingView);