import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import Numeral from 'numeral';
import Select from "react-select";

import { setSentimentFilter } from '../../../../../actions/alerts/data-point-search';

const SentimentFilter = ({ counts, sentimentFilter, setSentimentFilter }) => {
	const dropdown = [
        {
            value: null,
            label: `All (${Numeral(counts['all']).format("0,0")})`
        },
        {
            value: 'pos',
            label: `Positive (${Numeral(counts['pos']).format("0,0")})`
        },
        {
            value: 'neut',
            label: `Neutral (${Numeral(counts['neut']).format("0,0")})`
        },
        {
            value: 'neg',
            label: `Negative (${Numeral(counts['neg']).format("0,0")})`
        }
    ]

	const onChange = (e) => {
        setSentimentFilter(e.value)
	}

	return ( 
        <Select
            className="tait-alert__select-full"
            classNamePrefix="select"
            value={
                dropdown.find(e => 
                    e['value'] == sentimentFilter
                )
            }
            placeholder="Filter By Sentiment"
            onChange={onChange}
            options={dropdown}
        />
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        sentimentFilter: state['dataPointSearch']['sentimentFilter']
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSentimentFilter: (sentimentFilter) => dispatch(setSentimentFilter(sentimentFilter)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SentimentFilter);