import React from "react";
import { connect } from 'react-redux';

import {
    Row,
    Col
} from 'reactstrap'

import LoadingPage from '../../components/Loading/LoadingPage';
import SignupComponent from '../../components/Signup/Signup';

export class Signup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            loading: false
        })
    }
    render() {
        if (this.state.loading === true) {
            return <LoadingPage colorTheme={this.props.colorTheme} full/>;
        }

        return (
            <SignupComponent />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        colorTheme: state['admin']['colorTheme'],
        auth: state['auth'],
    }
}
  
export default connect(mapStateToProps)(Signup);