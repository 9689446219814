import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Numeral from 'numeral';
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

import ThemeFilter from './ThemeFilter';
import AssetFilter from './AssetFilter';
import AlertFilter from './AlertFilter';


const AlertPickerModal = ({ isOpen, toggle, onSelect, themeFilter, assetFilter, widgetFilter, compareList }) => {
    const [theme, setTheme] = useState(themeFilter);
    const [asset, setAsset] = useState(assetFilter);
    const [alert, setAlert] = useState(null);

    // useEffect(() => {
    //     console.log('modal opening')
    // }, [])

    const onThemeSelect = (t) => {
        if (t.value != theme) {
            setTheme(t.value)
            setAsset(null)
            setAlert(null)
        }
    }

    const onAssetSelect = (a) => {
        if (a.value != asset) {
            setAsset(a.value)
            setAlert(null)
        }
    }

    const onAlertSelect = (a) => {
        if (a.value != asset) {
            setAlert(a.value)
        }
    }

    const onSelectButtonClick = () => {
        onSelect(theme, asset, alert)
        setTheme(themeFilter)
        setAsset(assetFilter)
        setAlert(null)
        toggle()
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            className="compare__alert-picker-modal"
        >
            <ModalHeader toggle={toggle}>
                Select Alert
            </ModalHeader>
            <ModalBody>
                <ThemeFilter 
                    selected={theme}
                    onSelect={onThemeSelect}
                />
                <AssetFilter 
                    selected={asset}
                    theme={theme}
                    onSelect={onAssetSelect}
                />
                <AlertFilter 
                    selected={alert}
                    currentAlert={widgetFilter}
                    compareList={compareList}
                    theme={theme}
                    asset={asset}
                    onSelect={onAlertSelect}
                />
            </ModalBody>
            <ModalFooter>
                <Button 
                    disabled={alert ? false : true}
                    onClick={onSelectButtonClick}
                >
                    Select
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        themeFilter: state['alertsFilters']['theme'],
        assetFilter: state['alertsFilters']['asset'],
        widgetFilter: state['alertsFilters']['widget'],
    };
};

export default connect(mapStateToProps)(AlertPickerModal);