import React, { Component } from 'react'
import Chart from "chart.js";

Chart.defaults.customStackedHorizontalBar = Chart.defaults.horizontalBar;
Chart.defaults.customStackedHorizontalBar.defaultFontFamily = "'ProximaNovaSoftW03-Regular', 'Montserrat', 'Helvetica', Arial, sans-serif";

export default class StackedHorizontalBarChart extends Component {
    chartRef = React.createRef();
    
    componentDidMount() {
        const myChartRef = this.chartRef.current.getContext("2d");

        const { datasets, labels, options, mobile } = this.props;

        var customStackedHorizontalBar = Chart.controllers.horizontalBar.extend({
            draw: function(ease) {
                Chart.controllers.horizontalBar.prototype.draw.call(this, ease);
            }
        });
        
        Chart.controllers.customStackedHorizontalBar = customStackedHorizontalBar;
        
        new Chart(myChartRef, {
            type: 'horizontalBar',
            data: {
                labels: labels,
                datasets: datasets
            },
            options: options
        });
    }
    render() {
        return (
            <canvas
                id="customStackedHorizontalBar"
                ref={this.chartRef}
                height={30}
            />
        )
    }
}
