import axios from 'axios';

export const setOnboarding = (config, themeAssets, runtimes, planName, planNumSearchterms, upeId, lastPaymentTime, nextPaymentTime) => ({
    type: 'SET_ONBOARDING',
    config,
    themeAssets,
    runtimes,
    planName,
    planNumSearchterms,
    upeId,
    lastPaymentTime,
    nextPaymentTime
});

export const startSetOnboarding = (userId) => {
    return (dispatch) => {
        return axios.get(`${process.env.SERVER_URL}/onboarding/getOnboarding`, 
            {
                params: {
                    userId
                },
                headers: {
                    'Authorization': "Bearer " + JSON.parse(localStorage.getItem('user'))['token']
                }
            },
        ).then((response) =>  {
            dispatch(setOnboarding(
                response['data']['config'], 
                response['data']['themeAssets'],
                response['data']['runtimes'],
                response['data']['planName'],
                response['data']['planNumSearchterms'],
                response['data']['upeId'],
                response['data']['lastPaymentTime'],
                response['data']['nextPaymentTime']
            ));
            return response['data'];
        }).catch((error) => {
            console.log(error);
        });
    };
};

export const startSetSaveOnboarding = (onboarding) => {
    return (dispatch) => {
        return axios.post(`${process.env.SERVER_URL}/onboarding/saveOnboarding`, 
            {
                onboarding: onboarding
            },
            {
                headers: {
                    'Authorization': "Bearer " + JSON.parse(localStorage.getItem('user'))['token']
                }
            }
        ).then((response) =>  {
            return response;
        }).catch((error) => {
            console.log(error);
        });
    };
}


