import React from 'react';
import {
    Row,
    Col
} from "reactstrap";
import Countdown from 'react-countdown';
import moment from 'moment-timezone';

export class Header extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const currentTimeEST = moment().tz('America/New_York');

        let nextRefresh = null;

        this.props.runtimes.every((rt) => {
            var runtime = moment(currentTimeEST);
            runtime.set({
                hour: rt.slice(0, 2),
                minute: rt.slice(2, 4),
                second: 0
            });
            
            if (currentTimeEST < runtime) {
                nextRefresh = runtime.unix();
                return false;
            }

            return true;
        })

        if (!nextRefresh) {
            var runtime = moment(currentTimeEST);
            runtime.set({
                hour: this.props.runtimes[0].slice(0, 2),
                minute: this.props.runtimes[0].slice(2, 4),
                second: 0
            });

            runtime.add(1, 'days');

            nextRefresh = runtime.unix();
        }

        return (
            <Row>
                <Col xs={12}>
                    <div className={'tait-alert__card'}>
                        <div className={'tait-alert__card-body'}>
                            <Row>
                                <Col sm={12} md={12} lg={12}>
                                    <div className='onboarding__description-div'>
                                        <span>
                                            <b>Current Plan:</b> {this.props.planName}
                                        </span>
                                        <span>
                                            <b>Last Payment Date:</b> {moment.unix(this.props.lastPaymentTime).format("MMM DD YYYY")}
                                        </span>
                                        <span>
                                            <b>Next Renewal Date:</b> {moment.unix(this.props.nextPaymentTime).format("MMM DD YYYY")}
                                        </span>
                                        <span>
                                            <b>Number of Search Terms:</b> {this.props.numSearchterms}/{this.props.planNumSearchterms}
                                        </span>
                                        <span>
                                            <b>Time Until Next Alert:</b> <Countdown date={nextRefresh * 1000} />
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
}
  
export default Header;