import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Row,
    Col
} from 'reactstrap';

import CardHeader from '../Header/CardHeader';
import FiltersList from './FiltersList';
import Filters from './Filters';

const SearchSentiment = ({ contexts }) => {
    return (
        <Row>
            <Col xs={12}>
                <div className='tait-alert__card'>
                    <CardHeader
                        title='Search Sentiment'
                        tooltipId={`tait-alert__dps-tooltip`}
                        tooltipText="It's possible to conduct a search by using your own words, hashtags, products/brands, and people’s names. talkAItive refreshes total volume, sentiment, engagement, unique users engaging, and reach breakdown for a certain term."
                    />
                    <div className={'tait-alert__card-body'}>
                        {/*<FiltersList />*/}
                        <Filters 
                            contexts={contexts}
                        />
                    </div>
                </div>
            </Col>
        </Row>
    )
}


export default SearchSentiment;