import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import {
    Button
} from 'reactstrap';

import { setSearchFilter, setDateFilter, setSentimentFilter, setSourceFilter } from '../../../../../actions/alerts/data-point-search';

const SentimentTimelineContexts = ({ contexts, sentiment, setSearchFilter, setDateFilter, setSentimentFilter, setSourceFilter }) => {
    const onClick = (context) => {
        setDateFilter(null)
        setSentimentFilter(null)
        setSourceFilter(null)
        setSearchFilter(context)
        window.scrollTo(0, 0);
    }

    if (contexts && contexts.length > 0) {
        return (
            <div className='tait-alert__st-contexts-div'>
                <hr />
                <div>
                    <span>Community is also talking about:</span>
                </div>
                <div className={'tait-alert__st-contexts'}>
                    {
                        contexts.map(cntx => {
                            let className = `tait-alert__st-context tait-alert__st-context-${sentiment}`;

                            return (
                                <Button 
                                    className={className} 
                                    key={`sentiment-timeline-contexts__${cntx[0]}`}
                                    onClick={() => onClick(cntx[0])}
                                    onMouseDown={e => e.preventDefault()}
                                >
                                    {cntx[0]}
                                </Button>
                            )
                        })
                    }
                </div>
            </div>
        );
    } else {
        return null;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSearchFilter: (searchFilter) => dispatch(setSearchFilter(searchFilter)),
        setDateFilter: (startDate, endDate) => dispatch(setDateFilter(startDate, endDate)),
        setSentimentFilter: (sentimentFilter) => dispatch(setSentimentFilter(sentimentFilter)),
        setSourceFilter: (sourceFilter) => dispatch(setSourceFilter(sourceFilter))
    };
};

export default connect(null, mapDispatchToProps)(SentimentTimelineContexts);