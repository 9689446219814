import React from "react";
import axios from "axios";
import { connect } from 'react-redux';

import LoadingPage from '../../components/Loading/LoadingPage';
import OnboardingView from './OnboardingView';
import SubscriptionView from './SubscriptionView';

export class Onboarding extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            validSubscription: false
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);

        axios.get(`${process.env.SERVER_URL}/auth/getSubscription`, 
            {
                params: {
                    userId: this.props.auth.id,
                },
                headers: {
                    'Authorization': "Bearer " + JSON.parse(localStorage.getItem('user'))['token']
                }
            }
        ).then((response) =>  {
            this.setState({
                loading: false,
                validSubscription: response['data']['data']
            })
        }).catch((error) => {
            console.log(error);
        });

        // this.toggleLoading(false);
    }
    toggleLoading = (status) => {
        this.setState({
            loading: status
        })
    }
    render() {
        if (this.state.loading === true) {
            return <LoadingPage colorTheme={this.props.colorTheme}/>;
        }

        return (this.state.validSubscription ? <OnboardingView /> : <SubscriptionView />)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        colorTheme: state['admin']['colorTheme'],
        auth: state['auth']
    }
}

export default connect(mapStateToProps)(Onboarding);