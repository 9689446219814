import React from "react";
import { connect } from 'react-redux';
import queryString from 'query-string';
import {
    Row,
    Col
} from 'reactstrap'

import LoadingPage from '../../components/Loading/LoadingPage';
import ThemeFilter from '../../components/Alerts/Filters/ThemeFilter';
import AssetFilter from '../../components/Alerts/Filters/AssetFilter';
import WidgetFilter from '../../components/Alerts/Filters/WidgetFilter';
import Widget from '../../components/Alerts/Widget/Widget';

import { startSetAlertsList, startSetWidgetAnalysis } from '../../actions/alerts/alerts';
import {
    setAlertsWidgetFilter,
    setAlertsAssetFilter,
    setAlertsThemeFilter
} from '../../actions/alerts/alerts-filters';
import { resetFilters } from '../../actions/alerts/data-point-search';

export class Alerts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            themeAnalysis: {}
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);

        const qs = queryString.parse(this.props.location.search);
        const shareCode = qs.shareCode;

        this.props.startSetAlertsList(this.props.auth['id']).then((alerts) => {
            if (Object.keys(alerts).length > 0) {
                let foundWidget = false;

                if (shareCode) {
                    for (let [theme, assets] of Object.entries(alerts)) {
                        for (let [asset, widgets] of Object.entries(assets)) {
                            for (let widget of widgets) {
                                if (widget.shareCode == shareCode) {
                                    foundWidget = true;
                                    
                                    this.props.setAlertsThemeFilter(theme);
                                    this.props.setAlertsAssetFilter(asset);
                                    this.props.setAlertsWidgetFilter(shareCode);
                                    this.props.resetFilters();

                                    this.props.startSetWidgetAnalysis(this.props.userId, shareCode).then((widgetAnalysis) => {
                                        this.toggleLoading(false)
                                    }).catch((error) => {
                                    });

                                    break;
                                }
                            }
                            if (foundWidget) break;
                        }
                        if (foundWidget) break;
                    }
                } 

                if (!shareCode || !foundWidget) {
                    const defaultTheme = Object.keys(alerts).sort()[0];
                    const defaultAsset = Object.keys(alerts[defaultTheme]).sort()[0];
                    const defaultWidget = alerts[defaultTheme][defaultAsset][0]['shareCode'];

                    this.props.history.push({
                        pathname: '/alerts',
                        search: `?shareCode=${defaultWidget}`
                    });
                }
            } else {
                this.toggleLoading(false)
            }
        }).catch((error) => {
        });
    }
    toggleLoading = (status) => {
        this.setState({
            loading: status
        })
    }
    render() {
        if (this.state.loading === true) {
            return <LoadingPage colorTheme={this.props.colorTheme}/>;
        }

        return (
            <div className="content">
                <div className="content-row">
                    <Row>
                        <Col xs={12} md={4} lg={4} xl={4}>
                            <ThemeFilter 
                                history={this.props.history}
                            />
                        </Col>
                        <Col xs={12} md={4} lg={4} xl={4}>
                            <AssetFilter 
                                history={this.props.history}
                            />
                        </Col>
                        <Col xs={12} md={4} lg={4} xl={4}>
                            <WidgetFilter 
                                history={this.props.history}
                            />
                        </Col>
                    </Row>
                </div>
                <Widget/>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userId: state['auth']['id'],
        colorTheme: state['admin']['colorTheme'],
        auth: state['auth']
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        startSetAlertsList: (userId) => dispatch(startSetAlertsList(userId)),
        setAlertsThemeFilter: (theme) => dispatch(setAlertsThemeFilter(theme)),
        setAlertsAssetFilter: (asset) => dispatch(setAlertsAssetFilter(asset)),
        setAlertsWidgetFilter: (widget) => dispatch(setAlertsWidgetFilter(widget)),
        startSetWidgetAnalysis: (userId, shareCode) => dispatch(startSetWidgetAnalysis(userId, shareCode)),
        resetFilters: () => dispatch(resetFilters())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Alerts);