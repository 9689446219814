import React from 'react';
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Tooltip
} from 'reactstrap'
// import LoadingPage from '../../../Loading/LoadingPage';

export class DeleteConfirmation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }
    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={() => this.props.toggle(null)}
                className="dark-modal"
            >
                <ModalHeader toggle={() => this.props.toggle(null)}>
                    Do you wish to delete this alert?
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={12}>
                            <div style={{display: 'flex', gap: '2rem', justifyContent: 'center'}}>   
                                <Button 
                                    className="onboarding__delete-button"
                                    onClick={this.props.confirmDelete}
                                    onMouseDown={e => e.preventDefault()}
                                >
                                    Delete
                                </Button>
                                <Button 
                                    className="onboarding__cancel-button"
                                    onClick={this.props.toggle}
                                    onMouseDown={e => e.preventDefault()}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter />
            </Modal>
        );
    };
}

export default DeleteConfirmation;