import React, { Component } from 'react'
import Chart from "chart.js";

Chart.defaults.customPie = Chart.defaults.doughnut;
Chart.defaults.customPie.defaultFontFamily = "'ProximaNovaSoftW03-Regular', 'Montserrat', 'Helvetica', Arial, sans-serif";
Chart.defaults.customPie.legend.display = false;

export default class PieChart extends Component {
    chartRef = React.createRef();
    
    componentDidMount() {
        const myChartRef = this.chartRef.current.getContext("2d");

        const { data, options } = this.props;

        var customPie = Chart.controllers.doughnut.extend({
            draw: function(ease) {
                Chart.controllers.doughnut.prototype.draw.call(this, ease);
        
                var chart = this.chart.chart;
                var ctx = chart.ctx;
                var width = chart.width;
                var height = chart.height;

                let percFontSize = (height / 100).toFixed(2);
                ctx.font = percFontSize + "em 'ProximaNovaSoftW03-Regular', 'Montserrat', 'Helvetica', Arial, sans-serif";
                ctx.textBaseline = "middle";
                ctx.fillStyle = chart.config.data.text1Color;

                var text1 = chart.config.data.text1,
                    textX1 = Math.round((width - ctx.measureText(text1).width) / 2),
                    textY1 = (height / 2) - ((percFontSize * 15)/ 2);

                ctx.fillText(text1, textX1, textY1);

                let labelFontSize = (height / 190).toFixed(2);
                ctx.font = labelFontSize + "em 'ProximaNovaSoftW03-Regular', 'Montserrat', 'Helvetica', Arial, sans-serif";
                
                ctx.fillStyle = '#150637';

                var textSplit = "";
                if (chart.config.data.text2 != null) {
                    textSplit = chart.config.data.text2.split(" ");
                }
                

                for (var i=0;i<textSplit.length;i++) {
                    var text = textSplit[i],
                        textX2 = Math.round((width - ctx.measureText(textSplit[i]).width) / 2),
                        textY2 = (height / 2) + ((percFontSize * 20) / 2) + (i*(labelFontSize * 20))

                    ctx.fillText(text, textX2, textY2);
                }
            }
        });
        
        Chart.controllers.customPie = customPie;
        
        new Chart(myChartRef, {
            type: 'customPie',
            data: data,
            options: options
        });
    }
    render() {
        return (
            <canvas
                id="customPie"
                ref={this.chartRef}
                width={200}
                height={200}
            />
        )
    }
}
