const alertsReducerDefaultState = {
    alertsList: null,
    widgetAnalysis: null
};

export default (state = alertsReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_ALERTS_LIST':
            return {
                ...state,
                alertsList: action.alertsList
            };
        case 'SET_WIDGET_ANALYSIS':
            return {
                ...state,
                widgetAnalysis: action.widgetAnalysis
            };
        default:
            return state;
    }
};
