const compareReducerDefaultState = {
    compareList: []
};

export default (state = compareReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_COMPARE_LIST':
            console.log(action.compareList)
            return {
                ...state,
                compareList: action.compareList
            };
        default:
            return state;
    }
};
